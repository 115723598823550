/** @format */

import { Text, View } from "react-native"

import { useGameStore } from "../../../context/GameManager/GameManager"
import { styles } from "../../../styles"

const HeaderScore = () => {
	const score = useGameStore(
		({ gameState }) => gameState[gameState.gameMode]?.score,
	)

	return (
		<View
			style={{
				...styles.headerView,
				alignItems: "center",
				justifyContent: "center",
				backgroundColor: "transparent",
				height: 60,
			}}
			pointerEvents="none"
		>
			<Text
				allowFontScaling={false}
				style={{
					...styles.mediumText,
					...styles.boldText,
					textAlignVertical: "center",
					textAlign: "center",
					display: "flex",
					alignContent: "center",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				{score || 0} POINTS
			</Text>
		</View>
	)
}

export default HeaderScore
