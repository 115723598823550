/** @format */

import { Letter } from "../../utils/wordManager"

export const JS_VERSION = "1.0.18"
export const __TUTORIAL_SEED__ = "__TUTORIAL_SEED__"
export const __INVALID_GUESS__ = '__INVALID__GUESS__"'

export enum GameMode {
	CHALLENGE,
	CASUAL,
	ENDLESS,
	TUTORIAL,
	STUMP,
}

type GameOverCondition = "ON_ATTEMPTS" | "ON_NO_MOVES" | "ON_USER"
type ScoreMethod = "POINTS" | "ATTEMPTS"
interface GameModeConfigType {
	isDaily: boolean
	hasSeed: boolean
	gameOverCondition: GameOverCondition
	hasHoldButton: boolean
	shouldCountScoreOnLoss: boolean
	shouldClearOnComplete: boolean
	attemptsAllowed?: number
	seedSuffix?: string
	initialGameStateOverride?: Partial<GameModeState>
	allowReset: boolean
	allowNewGame: boolean
	allowShowSolution: boolean
	challengeLabel?: string
	scoreMethod: ScoreMethod
	customResetLabel?: string
	disableStats?: { [key in keyof Partial<Stat>]: boolean } & {
		winRate?: boolean
	}
	getScoreMessage: (
		score: number,
		attempts: number,
		attemptsWord: string,
		seed?: string,
	) => string
}

const DEFAULT_CONFIG = {
	isDaily: false,
	scoreMethod: "POINTS",
	attemptsAllowed: 3,
	hasSeed: false,
	gameOverCondition: "ON_USER",
	shouldClearOnComplete: false,
	hasHoldButton: true,
	shouldCountScoreOnLoss: false,
	allowNewGame: true,
	allowShowSolution: true,
	allowReset: true,

	getScoreMessage: (score, attempts, attemptsWord) =>
		`WordTree\nChallenge\n${attempts} ${attemptsWord}\n${score} Points`,
}

export const GAME_MODE_CONFIG = {
	[GameMode.CASUAL]: {
		...DEFAULT_CONFIG,
		getScoreMessage: (score, attempts, attemptsWord) =>
			`WordTree\n🆓Free Challenge\n${attempts} ${attemptsWord}\n${score} Points`,
	},
	[GameMode.CHALLENGE]: {
		...DEFAULT_CONFIG,
		isDaily: true,
		hasSeed: true,
		allowNewGame: false,
		gameOverCondition: "ON_ATTEMPTS",
		getScoreMessage: (score, attempts, attemptsWord, seed) =>
			seed
				? `WordTree ${seed}\n🗓️ Daily Challenge\n${attempts} ${attemptsWord}\n${score} Points`
				: `WordTree\nChallenge\n${attempts} ${attemptsWord}\n${score} Points`,
	},
	[GameMode.ENDLESS]: {
		...DEFAULT_CONFIG,
		disableReset: true,
		attemptsAllowed: 0,
		gameOverCondition: "ON_NO_MOVES",
		shouldClearOnComplete: true,
		shouldCountScoreOnLoss: true,
		allowNewGame: true,
		allowReset: false,
		customResetLabel: "ENDLESS",
		allowShowSolution: false,
		challengeLabel: "endless challenges",
		disableStats: {
			currentStreak: true,
			maxStreak: true,
			beatUs: true,
			won: true,
			winRate: true,
		},
		getScoreMessage: score => `WordTree\n♾️ Endless Challenge\n${score} Points`,
	},
	[GameMode.TUTORIAL]: {
		...DEFAULT_CONFIG,
	},
	[GameMode.STUMP]: {
		...DEFAULT_CONFIG,
		isDaily: true,
		hasSeed: true,
		attemptsAllowed: 0,
		hasHoldButton: false,
		seedSuffix: "|STUMP",
		scoreMethod: "ATTEMPTS",
		allowNewGame: false,
		disableStats: {
			beatUs: true,
			average: true,
			topScore: true,
			lastTenMatches: true,
		},
		getScoreMessage: (score, attempts, attemptsWord, seed) =>
			`WordTree ${seed}\n🤔 Stump Challenge\n${attempts} ${attemptsWord}`,
		initialGameStateOverride: {
			skipsLeft: 0,
		},
	},
} as { [key in GameMode]: GameModeConfigType }

export const GAME_MODES = Object.keys(GAME_MODE_CONFIG).map(i =>
	parseFloat(i),
) as unknown as GameMode[]

export interface GameState {
	VERSION_NUMBER: number
	isMuted: boolean
	currentChallengeSeed?: string
	[GameMode.CASUAL]: GameModeState
	[GameMode.CHALLENGE]: GameModeState
	[GameMode.ENDLESS]: GameModeState
	[GameMode.STUMP]: GameModeState
	[GameMode.TUTORIAL]: GameModeState
	gameMode: GameMode
	SERVER_VERSION_NUMBER?: string
	isCustomSeed: boolean
}
export interface GameModeState {
	currLetter?: Letter
	holdLetter?: Letter
	stats: Stat
	guesses: Guess[][]
	numGuesses: number
	skipsLeft: number
	attempts: number
	gameplayState: GameplayState
	letters: Letter[]
	initialLetters: Letter[]
	wordsEncoded?: string
	score: number
}

export interface Stat {
	won: number
	total: number
	currentStreak: number
	maxStreak: number
	experience: number
	lastTenMatches: number[]
	topScore: number
	average: [number, number]
	beatUs: number
	totalScore: number
	totalWords: number
}

export type Guess = {
	complete: boolean
	letter: Letter
}

export enum GameplayState {
	PLAYING,
	LOST,
	NO_MOVES,
	WON,
}

export const INITIAL_STATS: Stat = {
	won: 0,
	total: 0,
	currentStreak: 0,
	maxStreak: 0,
	experience: 0,
	topScore: 0,
	average: [0, 0],
	beatUs: 0,
	lastTenMatches: [],
	totalScore: 0,
	totalWords: 0,
}

export interface GameManagerState {
	nextLetter: () => void
	skip: () => void
	reset: () => void
	clearRow: () => void
	checkGuess: (wordIndex: number, letterIndex: number) => boolean | string
	makeGuess: (wordIndex: number, letterIndex: number) => void
	undo: (wordIndex: number, letterIndex: number) => void
	newGame: () => void
	setIsMuted: (b: boolean) => void
	gameState: GameState
	showSetTutorial: boolean
	setShowSetTutorial: (b: boolean) => void
	showStats: boolean
	setShowStats: (b: boolean) => void
	showSettings: boolean
	setShowSettings: (b: boolean) => void
	setGameMode: (s: GameMode) => void
	shouldSetGameMode: boolean
	setShouldSetGameMode: (b: boolean) => void
	gameOver: () => void
	toggleSolution: (s: boolean) => void
	showSolution: boolean
	newVersionAvailable: boolean
	newChallenge: () => void
	challengeSeed: string
	setChallengeSeed: (b: string) => void
	isCustomSeed: boolean
	doHoldLetter: () => void
	loadedSeed: string
	setLoadedSeed: (s: string) => void
	lastCheckTime: number
	setLastCheckTime: (lastCheckTime: number) => void
	currentVersion: string
	setCurrentVersion: (currentVersion: string) => void
	loadNewSeed: (seed: string) => void
	soundManager: SoundContextInterface
	setSoundManager: (soundManager: SoundContextInterface) => void
	loadFromOldState: (s: GameManagerState & { VERSION_NUMBER: number }) => void
}

export interface SoundContextInterface {
	muted: boolean
	setMuted: (b: boolean) => void
	playInvalid: () => Promise<void> | void
	playSuccess: () => Promise<void> | void
	playFirework: () => Promise<void> | void
	playGameOver: () => Promise<void> | void
	playPush: () => Promise<void> | void
	playVictory: () => Promise<void> | void
}
