/** @format */

import { Image } from "react-native"

export const GooglePlayButton = () => (
	<Image
		source={require("../../../../assets/google-play-badge.png")}
		style={{
			width: 170,
			height: 50,
		}}
	/>
)

export default GooglePlayButton
