/** @format */
import { AnimatePresence, MotiText, MotiView } from "moti"
import { MotiPressable } from "moti/interactions"
import React, { useMemo, useContext, useState } from "react"
import { View, Text, Platform, Share } from "react-native"

import CurrentLetter from "./CurrentLetter"
import SoundContext from "../../../../utils/SoundContext"
import { ShareIcon, SkipIcon } from "../../../components/Icon"
import { ShakeView } from "../../../components/ShakeView"
import { useGameStore } from "../../../context/GameManager/GameManager"
import {
	GAME_MODE_CONFIG,
	GameMode,
	GameplayState,
} from "../../../context/types"
import { styles } from "../../../styles"
import { colors } from "../../../styles/styles"
import { getSeedString } from "../../../utils/utils"

export const ActionRow: React.FC = () => {
	const gameMode = useGameStore(state => state.gameState.gameMode)
	const gameplayState = useGameStore(
		state => state.gameState[state.gameState.gameMode].gameplayState,
	)

	const showScore =
		gameplayState === GameplayState.WON ||
		(gameplayState === GameplayState.LOST &&
			GAME_MODE_CONFIG[gameMode].shouldCountScoreOnLoss)
	return (
		<View
			style={{
				...styles.box,
				marginBottom: 15,
				alignItems: "center",
				height: 60,
			}}
		>
			<ResetButton gameMode={gameMode} gameplayState={gameplayState} />
			<LetterAndScore
				gameMode={gameMode}
				gameplayState={gameplayState}
				showScore={showScore}
			/>
			<SkipButton gameplayState={gameplayState} />
		</View>
	)
}

export default ActionRow

const LetterAndScore: React.FC<{
	gameplayState: GameplayState
	gameMode: GameMode
	showScore: boolean
}> = ({ showScore, gameMode, gameplayState }) => {
	return (
		<AnimatePresence exitBeforeEnter>
			{!showScore && (
				<MotiView
					key={1}
					from={{ opacity: 0, translateY: 0 }}
					animate={{ opacity: 1, translateY: 0 }}
					exit={{ opacity: 0, translateY: -40 }}
					exitTransition={{ delay: 1500, type: "timing", duration: 250 }}
				>
					<CurrentLetter
						gameMode={gameMode}
						gameOver={
							gameplayState === GameplayState.LOST ||
							gameplayState === GameplayState.NO_MOVES
						}
					/>
				</MotiView>
			)}
			{showScore && (
				<MotiView
					key={2}
					from={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ delay: 0 }}
				>
					<ScoreShowcase gameMode={gameMode} />
				</MotiView>
			)}
		</AnimatePresence>
	)
}

export const SkipButton: React.FC<{
	gameplayState: GameplayState
	canSkip?: boolean
	onSkip?: () => void
}> = ({ gameplayState, canSkip = true, onSkip }) => {
	const skip = useGameStore(state => state.skip)

	const skipsLeft = useGameStore(
		state => state.gameState[state.gameState.gameMode].skipsLeft,
	)
	const SoundManager = useContext(SoundContext)
	const borderColor =
		skipsLeft === 0 ||
		gameplayState === GameplayState.WON ||
		gameplayState === GameplayState.LOST
			? colors["grey-600"]
			: colors["cyan-800"]

	return (
		<View>
			<MotiPressable
				disabled={
					!canSkip ||
					skipsLeft === 0 ||
					gameplayState === GameplayState.WON ||
					gameplayState === GameplayState.LOST
				}
				onPressIn={() => SoundManager.playPush()}
				onPress={() => {
					skip()
					onSkip && onSkip()
				}}
				animate={useMemo(
					() =>
						({ hovered, pressed }) => {
							"worklet"

							return {
								translateY: pressed ? 4 : hovered ? -2 : 0,
								backgroundColor:
									skipsLeft === 0 ||
									gameplayState === GameplayState.WON ||
									gameplayState === GameplayState.LOST
										? colors["grey-400"]
										: colors["cyan-600"],
								borderColor,
							}
						},
					[skipsLeft, gameplayState, borderColor],
				)}
				style={styles.footerButton}
				containerStyle={{ zIndex: 1 }}
			>
				<SkipIcon />
			</MotiPressable>
			<View
				style={{
					...styles.footerButton,
					position: "absolute",
					bottom: -5,
					zIndex: 0,
					backgroundColor: borderColor,
					borderColor: "transparent",
				}}
			/>
			<AnimatePresence>
				{skipsLeft > 6 && (
					<MotiView
						key="high"
						style={{
							...styles.buttonBadge,
							backgroundColor: colors["cyan-500"],
							zIndex: 3,
						}}
						from={{ opacity: 1 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{ opacity: { duration: 250, type: "timing" } }}
					>
						<Text allowFontScaling={false} style={styles.buttonBadgeText}>
							{skipsLeft}
						</Text>
					</MotiView>
				)}
				{skipsLeft > 3 && skipsLeft <= 6 && (
					<MotiView
						key="medium"
						style={{
							...styles.buttonBadge,
							backgroundColor: colors["yellow-500"],
							zIndex: 2,
						}}
						from={{ opacity: 1 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{ opacity: { duration: 250, type: "timing" } }}
					>
						<Text allowFontScaling={false} style={styles.buttonBadgeText}>
							{skipsLeft}
						</Text>
					</MotiView>
				)}
				{skipsLeft <= 3 && (
					<MotiView
						key="low"
						style={{
							...styles.buttonBadge,
							backgroundColor: colors["red-600"],
							zIndex: 1,
						}}
						from={{ opacity: 1 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{ opacity: { duration: 250, type: "timing" } }}
					>
						<Text allowFontScaling={false} style={styles.buttonBadgeText}>
							{skipsLeft}
						</Text>
					</MotiView>
				)}
			</AnimatePresence>
		</View>
	)
}

export const ResetButton: React.FC<{
	disabled?: boolean
	gameMode: GameMode
	gameplayState: GameplayState
}> = ({ disabled: _disabled, gameMode, gameplayState }) => {
	const attempts = useGameStore(
		state => state.gameState[state.gameState.gameMode].attempts,
	)
	const { attemptsAllowed, gameOverCondition, allowReset, customResetLabel } =
		GAME_MODE_CONFIG[gameMode] || {}
	const reset = useGameStore(state => state.reset)
	const gameOver = useGameStore(state => state.gameOver)
	const isLastAttempt =
		attemptsAllowed &&
		attempts >= attemptsAllowed &&
		gameOverCondition === "ON_ATTEMPTS"
	const disabled =
		_disabled ||
		isLastAttempt ||
		gameplayState === GameplayState.WON ||
		gameplayState === GameplayState.LOST ||
		!allowReset
	const SoundManager = useContext(SoundContext)
	const borderColor =
		isLastAttempt ||
		!allowReset ||
		gameplayState === GameplayState.WON ||
		gameplayState === GameplayState.LOST ||
		disabled
			? colors["grey-600"]
			: colors["cyan-800"]
	const ParentView =
		gameplayState === GameplayState.NO_MOVES && !disabled ? ShakeView : View
	return (
		<ParentView>
			<>
				<MotiPressable
					onPressIn={() => SoundManager.playPush()}
					onPress={() => {
						const fn = isLastAttempt ? gameOver : reset
						fn()
					}}
					disabled={disabled}
					animate={useMemo(
						() =>
							({ hovered, pressed }) => {
								"worklet"

								return {
									translateY: pressed ? 4 : hovered ? -2 : 0,
									borderColor,
									backgroundColor:
										isLastAttempt ||
										!allowReset ||
										gameplayState === GameplayState.WON ||
										gameplayState === GameplayState.LOST ||
										disabled
											? colors["grey-400"]
											: colors["cyan-600"],
								}
							},
						[borderColor, allowReset, isLastAttempt, gameplayState, disabled],
					)}
					style={styles.footerButton}
					containerStyle={{ zIndex: 1 }}
				>
					<MotiText
						allowFontScaling={false}
						style={{
							...styles.normalText,
							...styles.buttonLabel,
							...styles.boldText,
						}}
					>
						{customResetLabel
							? customResetLabel
							: isLastAttempt
							? "LAST TRY"
							: "RESET"}
					</MotiText>
					{!customResetLabel && (
						<MotiText
							allowFontScaling={false}
							style={{
								...styles.buttonLabel,
								...styles.smallText,
								fontSize: 10,
								fontWeight: "normal",
							}}
						>
							Attempt: {attempts}
						</MotiText>
					)}
				</MotiPressable>
				<View
					style={{
						...styles.footerButton,
						position: "absolute",
						bottom: -5,
						zIndex: 0,
						backgroundColor: borderColor,
						borderColor: "transparent",
					}}
				/>
			</>
		</ParentView>
	)
}

const ScoreShowcase = ({ gameMode }: { gameMode: GameMode }) => {
	const seed = useGameStore(state => {
		const seedSuffix = GAME_MODE_CONFIG[state.gameState.gameMode].seedSuffix
		const cleanSeed = seedSuffix
			? state.gameState.currentChallengeSeed.replace(seedSuffix, "")
			: state.gameState.currentChallengeSeed
		return getSeedString(cleanSeed)
	})
	const score = useGameStore(
		state => state.gameState[state.gameState.gameMode].score,
	)
	const attempts = useGameStore(
		state => state.gameState[state.gameState.gameMode].attempts,
	)
	const SoundManager = useContext(SoundContext)
	const attemptsWord = `${attempts > 1 ? "Attempts" : "Attempt"}`
	const { getScoreMessage, scoreMethod } = GAME_MODE_CONFIG[gameMode] || {}
	const text = getScoreMessage(score, attempts, attemptsWord, seed)

	const [copied, setCopied] = useState(false)

	const doShare = async () => {
		if (Platform.OS === "web") {
			try {
				if (navigator.canShare({ text, url: window.location.origin })) {
					await navigator.share({ text, url: window.location.origin })
				} else {
					setCopied(true)
					navigator.clipboard.writeText(`${window.location.origin}\n${text}`)
				}
			} catch (e: unknown) {
				if (!e.toString().includes("AbortError")) {
					setCopied(true)
					navigator.clipboard.writeText(`${window.location.origin}\n${text}`)
				}
			}
		} else {
			try {
				Share.share({
					message: text,
					url: "https://www.wordtree.app",
				})
			} catch (e: unknown) {
				if (!e.toString().includes("AbortError")) {
					setCopied(true)
					navigator.clipboard.writeText(`${window.location.origin}\n${text}`)
				}
			}
		}
	}

	return (
		<ShakeView>
			<>
				<MotiPressable
					onPressIn={() => SoundManager.playPush()}
					onPress={doShare}
					animate={useMemo(
						() =>
							({ hovered, pressed }) => {
								"worklet"

								return {
									translateY: pressed ? 4 : hovered ? -2 : 0,
									borderColor: colors["indigo-800"],
									backgroundColor: colors["indigo-600"],
								}
							},
						[],
					)}
					style={{
						...styles.footerButton,
						paddingHorizontal: 5,
						maxWidth: 100,
						minWidth: 100,
					}}
					containerStyle={{ zIndex: 1 }}
				>
					<View
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "space-between",
						}}
					>
						<View
							style={{
								width: 24,
								height: 24,
							}}
						>
							<ShareIcon color={colors["grey-050"]} />
						</View>
						<View style={{ flexGrow: 1 }}>
							<Text
								allowFontScaling={false}
								style={{
									...styles.normalText,
									...styles.boldText,
									textAlign: "center",
									color: colors["grey-050"],
									fontSize: copied ? 14 : 18,
								}}
							>
								{copied
									? "Copied!"
									: scoreMethod === "ATTEMPTS"
									? attempts
									: score}
							</Text>
							{!copied && (
								<Text
									allowFontScaling={false}
									style={[
										styles.smallText,
										{
											textAlign: "center",
											fontSize: 10,
											fontWeight: "normal",
										},
									]}
								>
									{scoreMethod === "ATTEMPTS" ? attemptsWord : "Points"}
								</Text>
							)}
						</View>
					</View>
				</MotiPressable>
				<View
					style={{
						...styles.footerButton,
						width: "100%",
						maxWidth: 100,
						minWidth: 100,
						position: "absolute",
						bottom: -5,
						zIndex: 0,
						backgroundColor: colors["indigo-800"],
						borderColor: "transparent",
					}}
				/>
			</>
		</ShakeView>
	)
}
