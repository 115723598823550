/** @format */

import { MotiView, MotiText } from "moti"
import React, { useCallback, useContext, useEffect } from "react"
import { View, Text } from "react-native"

import Fireworks from "./Fireworks"
import SoundContext from "../../../../utils/SoundContext"
import { useGameStore } from "../../../context/GameManager/GameManager"
import { GameMode, GameplayState } from "../../../context/types"
import { styles } from "../../../styles"
import { colors } from "../../../styles/styles"

export const GameOverModal = () => {
	const SoundManager = useContext(SoundContext)
	const gameplayState = useGameStore(
		({ gameState }) => gameState[gameState.gameMode].gameplayState,
	)
	const gameMode = useGameStore(({ gameState }) => gameState.gameMode)

	const playFireworks = useCallback(
		() => SoundManager.playFirework(),
		[SoundManager],
	)
	const score = useGameStore(
		({ gameState }) => gameState[gameState.gameMode].score,
	)

	useEffect(() => {
		if (
			gameplayState === GameplayState.LOST ||
			gameplayState === GameplayState.NO_MOVES
		) {
			SoundManager.playGameOver()
		} else if (gameplayState === GameplayState.WON) {
			SoundManager.playVictory()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [gameplayState])

	return (
		<>
			{gameplayState === GameplayState.WON && (
				<View
					pointerEvents="none"
					style={{
						position: "absolute",
						top: 0,
						left: 0,
						overflow: "hidden",
						width: "100%",
						height: "100%",
					}}
				>
					<Fireworks
						key={gameplayState}
						playFireworks={playFireworks}
						iterations={3}
						colors={[
							colors["cyan-050"],
							colors["cyan-100"],
							colors["cyan-200"],
							colors["cyan-300"],
							colors["cyan-400"],
							colors["cyan-500"],
							colors["cyan-600"],
							colors["cyan-700"],
							colors["cyan-800"],
							colors["cyan-900"],
						]}
					/>
				</View>
			)}
			{gameplayState !== GameplayState.PLAYING && (
				<View style={styles.fullCenteredView}>
					<MotiView
						key={gameMode}
						from={{ opacity: 1, translateY: 0 }}
						animate={{ opacity: 0, translateY: -100 }}
						transition={{ delay: 3000 }}
					>
						<View style={{ ...styles.overlay, justifyContent: "center" }}>
							<MotiText
								allowFontScaling={false}
								style={{
									...styles.headerText,
									justifyContent: "center",
									textAlign: "center",
								}}
								adjustsFontSizeToFit={true}
								numberOfLines={1}
							>
								{gameplayState !== GameplayState.WON
									? gameplayState === GameplayState.LOST
										? "GAME OVER"
										: "NO MOVES"
									: "YOU WON!"}
							</MotiText>

							{gameplayState === GameplayState.WON && (
								<>
									<View style={{ ...styles.box, justifyContent: "center" }}>
										<Text
											allowFontScaling={false}
											style={{
												...styles.centerBox,
												...styles.smallText,
												...styles.boldText,
											}}
										>
											Congrats! You scored {score} points!
										</Text>
									</View>
								</>
							)}

							{gameplayState === GameplayState.WON &&
								gameMode === GameMode.CHALLENGE && (
									<Text
										allowFontScaling={false}
										style={{
											...styles.centerBox,
											...styles.smallText,
											...styles.boldText,
										}}
									>
										Check back tomorrow for a fresh challenge.
									</Text>
								)}
						</View>
					</MotiView>
				</View>
			)}
		</>
	)
}
