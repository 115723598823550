/** @format */
import "setimmediate"

import "react-native-reanimated"
import "react-native-gesture-handler"

import {
	Inter_400Regular,
	Inter_300Light,
	Inter_600SemiBold,
	Inter_700Bold,
} from "@expo-google-fonts/inter"
import { useAssets } from "expo-asset"
import { useFonts } from "expo-font"
import * as SplashScreen from "expo-splash-screen"
import { useCallback } from "react"
import { Platform, View } from "react-native"
import * as Sentry from "sentry-expo"

import GameManager, { useGameStore } from "./context/GameManager/GameManager"
import { TimeProvider } from "./context/TimeContext/TimeContext"
import { ChallengeModal } from "./features/challengeModal"
import { Experience } from "./features/experience"
import { GameModeModal } from "./features/gameModeModal"
import { Header } from "./features/header"
import BannerAd from "./features/header/components/BannerAd"
// import NotificationHandler from "./features/notifications/Notifications"
import GeneralStatusBarColor from "./features/header/components/StatusBar"
import { StatModal } from "./features/stats"
import TutorialModal from "./features/tutorial/components/TutorialModal"
import Home from "./pages/Home"
import { styles } from "./styles"
import { colors } from "./styles/styles"
import { SoundProvider } from "../utils/SoundContext"

Sentry.init({
	dsn: "https://48dc47cfe49b4dc6b1f587d04cc582f4@o4504788560052224.ingest.sentry.io/4504788575911936",
	enableInExpoDevelopment: false,
})

SplashScreen.preventAutoHideAsync()

export default function App() {
	const [assets] = useAssets(
		Platform.OS === "web"
			? [
					require("../assets/invalid.mp3"),
					require("../assets/success.mp3"),
					require("../assets/firework.mp3"),
					require("../assets/push.mp3"),
					require("../assets/gameover.mp3"),
					require("../assets/victory.mp3"),
			  ]
			: [],
	)

	const [fontsLoaded] = useFonts({
		Inter_600SemiBold,
		Inter: Inter_400Regular,
		Inter_300Light,
		Inter_700Bold,
	})

	const onLayoutRootView = useCallback(async () => {
		if (fontsLoaded) {
			try {
				await SplashScreen.hideAsync()
			} catch (e) {
				console.log(e)
			}
		}
	}, [fontsLoaded])

	if (!fontsLoaded) {
		return null
	}
	return (
		<>
			{/* <NotificationHandler /> */}
			<SoundProvider assets={Platform.OS === "web" ? assets || [] : []}>
				<TimeProvider>
					<GameManager>
						<BackgroundColorWrapper onLayoutRootView={onLayoutRootView} />
					</GameManager>
				</TimeProvider>
			</SoundProvider>
		</>
	)
}

const BackgroundColorWrapper = ({ onLayoutRootView }) => {
	const showSolution = useGameStore(state => state.showSolution)
	const backgroundColor = showSolution ? colors["red-050"] : colors["grey-100"]
	return (
		<View
			style={{
				width: "100%",
				height: "100%",
				backgroundColor,
			}}
		>
			<View style={styles.headerContainer}>
				<GeneralStatusBarColor />
				<Header />
			</View>
			{Platform.OS !== "web" && <BannerAd />}
			<GameModeModal />
			<ChallengeModal />
			<StatModal />
			<TutorialModal />
			<Home
				onLayoutRootView={onLayoutRootView}
				backgroundColor={backgroundColor}
			/>
			<Experience />
		</View>
	)
}
