/** @format */
import { MotiPressable } from "moti/interactions"
import { useContext, useMemo } from "react"
import { View, Text, StyleProp, ViewStyle } from "react-native"

import SoundContext from "../../../../utils/SoundContext"
import {
	ClockIcon,
	CalendarIcon,
	SparkleIcon,
	BookIcon,
	PuzzleIcon,
} from "../../../components/Icon"
import { GameMode } from "../../../context/types"
import { colors, styles } from "../../../styles/styles"

const MODES = {
	[GameMode.CASUAL]: {
		icon: <SparkleIcon />,
		label: "FREE",
	},
	[GameMode.ENDLESS]: {
		icon: <ClockIcon />,
		label: "ENDLESS",
	},
	[GameMode.CHALLENGE]: {
		icon: <CalendarIcon />,
		label: "DAILY",
	},
	[GameMode.TUTORIAL]: {
		icon: <BookIcon />,
		label: "MODE",
	},
	[GameMode.STUMP]: {
		icon: <PuzzleIcon />,
		label: "STUMP",
	},
}

export const GameModeButton = ({
	gameMode,
	setShouldSetGameMode,
	style,
	badge,
	label,
}: {
	gameMode: GameMode
	setShouldSetGameMode: (b: boolean) => void
	style?: StyleProp<ViewStyle>
	badge?: React.ReactNode
	label?: string
}) => {
	const SoundManager = useContext(SoundContext)
	return (
		<View
			style={
				style
					? style
					: {
							zIndex: 3,
							position: "absolute",
							left: 0,
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
					  }
			}
		>
			<MotiPressable
				animate={useMemo(
					() =>
						({ hovered, pressed }) => {
							"worklet"

							return {
								translateY: pressed ? 4 : hovered ? -2 : 0,
							}
						},
					[],
				)}
				onPressIn={() => SoundManager.playPush()}
				onPress={() => {
					setShouldSetGameMode(true)
				}}
				containerStyle={{ zIndex: 1 }}
				style={{
					height: 60,
					width: 60,
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					borderColor: colors["cyan-800"],
					borderWidth: 2,
					borderRadius: 5,
					margin: 5,
					backgroundColor: colors["cyan-600"],
					zIndex: 1,
				}}
			>
				{badge}
				<View
					style={{
						width: 26,
						height: 26,
					}}
				>
					{MODES[gameMode]?.icon}
				</View>
			</MotiPressable>
			<View
				style={{
					height: 60,
					width: 60,
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					borderColor: "transparent",
					borderWidth: 2,
					borderRadius: 5,
					margin: 5,
					backgroundColor: colors["cyan-800"],
					position: "absolute",
					top: 5,
					zIndex: 0,
				}}
			/>

			<Text
				maxFontSizeMultiplier={1.25}
				style={{
					...styles.smallText,
					color: colors["cyan-800"],
					...styles.boldText,
					textAlign: "center",
				}}
			>
				{label?.toUpperCase() || MODES[gameMode]?.label}
			</Text>
		</View>
	)
}

export default GameModeButton
