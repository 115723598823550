/** @format */

import AsyncStorage from "@react-native-async-storage/async-storage"
import * as StoreReview from "expo-store-review"
import React, { useContext, useEffect } from "react"
import { Platform } from "react-native"
import { create } from "zustand"
import { persist, createJSONStorage } from "zustand/middleware"

import {
	SKIPS_ALLOWED,
	generateEmptyGuessArray,
	generateNewGameState,
	handleMigrations,
	loadFromOldState,
	wordManager,
} from "./zustMigrations"
import SoundContext from "../../../utils/SoundContext"
import { SCORES } from "../../../utils/wordManager"
import { getBaseUrl } from "../../utils/utils"
import { NO_SEED, TimeContext } from "../TimeContext/TimeContext"
import {
	__TUTORIAL_SEED__,
	GameMode,
	GameplayState,
	GameModeState,
	GameState,
	Guess,
	__INVALID_GUESS__,
	GAME_MODE_CONFIG,
	GAME_MODES,
	GameManagerState,
} from "../types"

if (Platform.OS === "web") {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	window._frameTimestamp = null
} else {
	window.btoa = str => str
	window.atob = str => str
}

const ASK_FOR_REVIEW = "__ASK_FOR_REVIEW__"
const askForReview = async () => {
	if (Platform.OS === "web") {
		return
	}
	try {
		if (await StoreReview.isAvailableAsync()) {
			if (await StoreReview.hasAction()) {
				const askForReviewStored = await AsyncStorage.getItem(ASK_FOR_REVIEW)
				const canAskForReview =
					!askForReviewStored || Date.now() > parseFloat(askForReviewStored)

				if (canAskForReview) {
					await AsyncStorage.setItem(
						ASK_FOR_REVIEW,
						(Date.now() + 1000 * 60 * 60 * 24 * 2).toString(),
					)
					StoreReview.requestReview()
				}
			}
		}
	} catch (e) {
		console.log(e)
		try {
			await AsyncStorage.removeItem(ASK_FOR_REVIEW)
		} catch (eTwo) {
			console.log(eTwo)
		}
	}
}

const VERSION_NUMBER = 8

const PERSISTED_KEY = "__WORDTREE__"

export const GET_INITIAL_GAME_STATE = (
	seed: string,
	isCustomSeed: boolean,
	mode?: GameMode,
	currState?: GameState,
	resetStats = false,
): GameState => {
	const gameStats = currState?.[mode]?.stats

	if (resetStats && gameStats) {
		const { shouldCountScoreOnLoss } = GAME_MODE_CONFIG[mode] || {}
		gameStats.currentStreak = 0
		const finalScore = shouldCountScoreOnLoss ? currState[mode].score || 0 : 0
		gameStats.lastTenMatches = [
			...(gameStats.lastTenMatches || []).reduce(
				(acc, c, idx) =>
					idx === 0 && (gameStats.lastTenMatches || []).length === 10
						? acc
						: [...acc, c],
				[] as number[],
			),
			finalScore,
		]

		if (shouldCountScoreOnLoss)
			gameStats.average = getNewAverage(gameStats, finalScore)
		if ((gameStats.topScore || 0) < finalScore) {
			gameStats.topScore = finalScore
		}
	}
	const shouldReset = !mode || !currState
	const shouldResetCasual = mode === GameMode.CASUAL || shouldReset
	const shouldResetChallenge = mode === GameMode.CHALLENGE || shouldReset
	const shouldResetEndless = mode === GameMode.ENDLESS || shouldReset
	const shouldResetTutorial = mode === GameMode.TUTORIAL || shouldReset
	const shouldResetStump = mode === GameMode.STUMP || shouldReset

	return {
		VERSION_NUMBER,
		isCustomSeed,
		isMuted: currState?.isMuted ? currState?.isMuted : false,
		currentChallengeSeed: seed || currState?.currentChallengeSeed,
		[GameMode.CASUAL]: shouldResetCasual
			? generateNewGameState(GameMode.CASUAL, gameStats)
			: currState[GameMode.CASUAL],
		[GameMode.CHALLENGE]: shouldResetChallenge
			? generateNewGameState(
					GameMode.CHALLENGE,
					currState?.[GameMode.CHALLENGE]?.stats,
					seed,
			  )
			: currState[GameMode.CHALLENGE],
		[GameMode.ENDLESS]: shouldResetEndless
			? generateNewGameState(GameMode.ENDLESS, gameStats)
			: currState[GameMode.ENDLESS],
		[GameMode.TUTORIAL]: shouldResetTutorial
			? generateNewGameState(GameMode.TUTORIAL, gameStats, __TUTORIAL_SEED__)
			: currState[GameMode.TUTORIAL],
		[GameMode.STUMP]: shouldResetStump
			? generateNewGameState(GameMode.STUMP, gameStats, seed)
			: currState[GameMode.STUMP],
		gameMode: mode || GameMode.TUTORIAL,
	}
}

const checkGuess = (state: GameManagerState, wordIndex, letterIndex) => {
	const challengeKey = state.gameState.gameMode
	const {
		guesses,
		gameplayState,
		currLetter: ltr,
	} = state.gameState[challengeKey]

	if (gameplayState !== GameplayState.PLAYING) {
		return false
	}
	if (
		guesses?.[wordIndex]?.[letterIndex]?.letter?.letter &&
		guesses?.[wordIndex]?.[letterIndex]?.letter?.letter !== ""
	) {
		return false
	}

	const newWord = [
		...(guesses[wordIndex] || []).filter((v, idx) => idx < letterIndex),
		{
			...guesses[wordIndex][letterIndex],
			letter: ltr,
			complete: false,
		},
		...(guesses[wordIndex] || []).filter((v, idx) => idx > letterIndex),
	]
	const word = newWord.map(guess => guess.letter.letter?.toLowerCase()).join("")
	const completeWord = word.length === newWord.length
	const invalidGuess = completeWord
		? !wordManager.search(word)
		: !wordManager.searchAll(newWord?.map(g => g.letter?.letter?.toLowerCase()))

	if (invalidGuess) {
		state.soundManager?.playInvalid()
		return __INVALID_GUESS__
	}

	if (completeWord && !invalidGuess) {
		state.soundManager?.playSuccess()
	} else {
		state.soundManager?.playPush()
	}
	return true
}

const loadNewSeed = (
	state: GameManagerState,
	seed: string,
): GameManagerState => {
	let newState = { ...state }

	const dailyModes = GAME_MODES.filter(key => GAME_MODE_CONFIG[key].isDaily)
	dailyModes.forEach(gameMode => {
		const _newChallengeState = {
			[gameMode]: GET_INITIAL_GAME_STATE(
				seed,
				false,
				gameMode,
				state.gameState,
			)[gameMode],
		}

		const stats = { ..._newChallengeState[gameMode].stats }

		if (
			state.gameState.currentChallengeSeed &&
			state.gameState.currentChallengeSeed !== NO_SEED &&
			state.gameState[gameMode].gameplayState !== GameplayState.WON
		) {
			stats.currentStreak = 0
			stats.lastTenMatches = [
				...(_newChallengeState[gameMode].stats.lastTenMatches || []).reduce(
					(acc, c, idx) =>
						idx === 0 &&
						(_newChallengeState[gameMode].stats.lastTenMatches || []).length ===
							10
							? acc
							: [...acc, c],
					[] as number[],
				),
				0,
			]
		}
		_newChallengeState[gameMode].stats = stats

		newState = {
			...newState,
			gameState: {
				...newState.gameState,
				..._newChallengeState,
				currentChallengeSeed: seed,
			},
			showSolution: false,
		}
	})
	return newState
}

export const useGameStore = create(
	persist<GameManagerState>(
		(set, get) => ({
			// State
			gameState: GET_INITIAL_GAME_STATE(NO_SEED, false),
			showSolution: false,
			showSettings: false,
			challengeSeed: null,
			showStats: false,
			shouldSetGameMode: false,
			lastCheckTime: Date.now(),
			currentVersion: null,
			loadedSeed: NO_SEED,
			isCustomSeed: false,
			newVersionAvailable: false,
			showSetTutorial: false,

			// State Actions
			setShowSetTutorial: (showSetTutorial: boolean) =>
				set(state => ({ ...state, showSetTutorial })),
			setCurrentVersion: (currentVersion: string) =>
				set(state => ({ ...state, currentVersion })),
			setLastCheckTime: (lastCheckTime: number) =>
				set(state => ({ ...state, lastCheckTime })),
			setShowSolution: (showSolution: boolean) =>
				set(state => ({ ...state, showSolution })),
			setChallengeSeed: (challengeSeed: string) =>
				set(state => ({ ...state, challengeSeed })),
			setShowStats: (showStats: boolean) =>
				set(state => ({ ...state, showStats })),
			setShowSettings: (showSettings: boolean) =>
				set(state => ({ ...state, showSettings })),
			setCurrentChallengeSeed: (currentChallengeSeed: string) =>
				set(state => ({
					...state,
					gameState: { ...state.gameState, currentChallengeSeed },
				})),
			setLoadedSeed: (loadedSeed: string) =>
				set(state => ({ ...state, loadedSeed })),
			setShouldSetGameMode: (shouldSetGameMode: boolean) =>
				set(state => ({ ...state, shouldSetGameMode })),
			setGameMode: (gameMode: GameMode) =>
				set(state => ({
					...state,
					showSolution: false,
					shouldSetGameMode: false,
					gameState: {
						...state.gameState,
						...(gameMode === GameMode.TUTORIAL
							? fullReset(state, gameMode)
							: {}),
						gameMode,
					},
				})),
			toggleSolution: (shouldResetState: boolean) =>
				set(state => ({
					...state,
					showSolution: !state.showSolution,
					...(shouldResetState ? resetStreak(state) : {}),
				})),
			setIsMuted: (isMuted: boolean) =>
				set(state => ({
					...state,
					gameState: { ...state.gameState, isMuted },
				})),
			newChallenge: () => null,

			// Game Actions
			checkGuess: (wordIndex: number, letterIndex: number) =>
				checkGuess(get(), wordIndex, letterIndex),
			nextLetter: () => set(state => nextLetter(state)),
			holdLetter: () => set(state => holdLetter(state)),
			skip: () => set(state => skip(state)),
			resetStreak: () => set(state => resetStreak(state)),
			reset: () => set(state => reset(state)),
			fullReset: (mode: GameMode, resetStats: boolean) =>
				set(state => ({
					...state,
					gameState: {
						...state.gameState,
						...fullReset(state, mode, resetStats),
					},
				})),
			clearRow: () => set(state => clearRow(state)),
			undo: (wordIndex: number, letterIndex: number) =>
				set(state => undo(state, wordIndex, letterIndex)),
			doHoldLetter: () => set(state => holdLetter(state)),
			makeGuess: async (wordIndex: number, letterIndex: number) => {
				let { newVersionAvailable, currentVersion, lastCheckTime } = get()
				if (Date.now() - lastCheckTime > 1000 * 60 * 60) {
					const _currentVersion = await getCurrentGameVersion()
					if (_currentVersion !== null) {
						if (currentVersion !== _currentVersion) {
							newVersionAvailable = true
						}
						currentVersion = _currentVersion
						lastCheckTime = Date.now()
					}
				}
				return set(state => ({
					...makeGuess(state, wordIndex, letterIndex),
					newVersionAvailable,
					currentVersion,
					lastCheckTime,
				}))
			},
			newGame: () =>
				set(state => ({
					...state,
					gameState: {
						...state.gameState,
						...fullReset(state, state.gameState.gameMode),
					},
					showSolution: false,
				})),
			gameOver: () =>
				set(state => ({
					...state,
					gameState: {
						...state.gameState,
						...fullReset(state, state.gameState.gameMode, true),
					},
				})),
			loadNewSeed: (seed: string) => set(state => loadNewSeed(state, seed)),

			// Sound
			soundManager: null,
			setSoundManager: soundManager =>
				set(state => {
					return { ...state, soundManager }
				}),

			// Load From Old State
			loadFromOldState: oldState =>
				set(state => loadFromOldState(state, oldState)),
		}),
		{
			version: VERSION_NUMBER,
			name: PERSISTED_KEY,
			migrate: (persistedState, version) => {
				return handleMigrations(persistedState, version)
			},
			storage: createJSONStorage(() => AsyncStorage),
			partialize: state => {
				delete state.soundManager
				return state
			},
		},
	),
)

type UpdateGameStateFn = (
	state: GameModeState,
	gameMode: GameMode,
) => GameModeState

const updateCurrentGameState = (
	state: GameManagerState,
	updateFn: UpdateGameStateFn,
) => {
	const { gameMode } = state.gameState

	const newState = {
		...state,
		gameState: {
			...state.gameState,
			[gameMode]: checkGameOver(
				updateFn(state.gameState[gameMode], gameMode),
				gameMode,
			),
		},
	}

	return newState
}

const getNextLetterState = ({ letters }: GameModeState) => {
	let newLetters = [...letters]
	const newCurrLetter = newLetters.shift()
	if (newLetters.length < 5) {
		const moreLetters = wordManager.generateLetters(GameMode.ENDLESS)
		newLetters = [...newLetters, ...moreLetters.letters]
	}
	return {
		letters: newLetters,
		currLetter: newCurrLetter,
	}
}

const nextLetter = (state: GameManagerState) =>
	updateCurrentGameState(state, gameModeState => ({
		...gameModeState,
		...getNextLetterState(gameModeState),
	}))

const holdLetter = (state: GameManagerState) =>
	updateCurrentGameState(state, gameModeState => {
		if (gameModeState.gameplayState !== GameplayState.PLAYING) {
			return gameModeState
		}
		let currLetter = gameModeState.currLetter
		let holdLetter = gameModeState.holdLetter
		let letterState = { currLetter, holdLetter }
		if (gameModeState.holdLetter && gameModeState.holdLetter.letter !== "") {
			const tempSwap = currLetter
			currLetter = holdLetter
			holdLetter = tempSwap
			letterState = { currLetter, holdLetter }
		} else {
			letterState = {
				...getNextLetterState(gameModeState),
				holdLetter: currLetter,
			}
		}

		return {
			...gameModeState,
			...letterState,
		}
	})

const skip = (state: GameManagerState) =>
	updateCurrentGameState(state, gameModeState => ({
		...gameModeState,
		skipsLeft: gameModeState.skipsLeft - 1,
		...getNextLetterState(gameModeState),
	}))

const resetStreak = (state: GameManagerState) =>
	updateCurrentGameState(state, (gameModeState, gameMode) => {
		const { shouldCountScoreOnLoss } = GAME_MODE_CONFIG[gameMode] || {}
		const newScore = shouldCountScoreOnLoss ? gameModeState.score || 0 : 0

		const stats = {
			...gameModeState.stats,
			currentStreak: 0,
			lastTenMatches: [
				...(gameModeState.stats.lastTenMatches || []).reduce(
					(acc, c, idx) =>
						idx === 0 &&
						(gameModeState.stats.lastTenMatches || []).length === 10
							? acc
							: [...acc, c],
					[] as number[],
				),
				newScore,
			],
		}

		if (shouldCountScoreOnLoss) stats.average = getNewAverage(stats, newScore)
		if ((stats.topScore || 0) < newScore) {
			stats.topScore = newScore
		}
		return {
			...gameModeState,
			gameplayState: GameplayState.LOST,
			stats,
		}
	})

const reset = (state: GameManagerState) =>
	updateCurrentGameState(state, (gameModeState, gameMode) => {
		const letters = [...gameModeState.initialLetters]
		const currLetter = letters.shift()
		if (
			gameModeState.attempts === 1 &&
			!gameModeState.guesses.some(row =>
				row.some(guess => guess.letter && guess.letter.letter !== ""),
			)
		) {
			gameModeState.stats.total += 1
		}
		return {
			...gameModeState,
			gameplayState: GameplayState.PLAYING,
			letters,
			currLetter,
			holdLetter: null,
			skipsLeft: SKIPS_ALLOWED,
			score: 0,
			guesses: generateEmptyGuessArray(),
			attempts: gameModeState.attempts + 1,
			...(GAME_MODE_CONFIG[gameMode]?.initialGameStateOverride || {}),
		}
	})

const fullReset = (
	state: GameManagerState,
	mode: GameMode,
	resetStats?: boolean,
) =>
	GET_INITIAL_GAME_STATE(
		state.gameState.currentChallengeSeed,
		state.isCustomSeed,
		mode,
		state.gameState,
		resetStats,
	)

const clearRow = (state: GameManagerState) =>
	updateCurrentGameState(state, (gameModeState, gameMode) => {
		const { guesses } = gameModeState
		if (
			GAME_MODE_CONFIG[gameMode].shouldClearOnComplete &&
			guesses.some(word => word.some(letter => letter.complete))
		) {
			const newGuesses: Guess[][] = [
				...guesses.map(word => [
					...word.map(letter =>
						letter.complete
							? {
									letter: { ...letter.letter, letter: "" },
									complete: false,
							  }
							: { ...letter },
					),
				]),
			]

			return {
				...gameModeState,
				guesses: newGuesses,
			}
		}
		return gameModeState
	})

const undo = (
	state: GameManagerState,
	wordIndex: number,
	letterIndex: number,
) =>
	updateCurrentGameState(state, gameModeState => {
		const { guesses, gameplayState, skipsLeft } = gameModeState

		if (
			!guesses?.[wordIndex]?.[letterIndex]?.letter?.letter ||
			guesses?.[wordIndex]?.[letterIndex]?.letter?.letter === ""
		) {
			state.soundManager?.playInvalid()
			return gameModeState
		}
		if (skipsLeft < 3) {
			state.soundManager?.playInvalid()
			return gameModeState
		}
		if (gameplayState !== GameplayState.PLAYING) {
			return gameModeState
		}

		state.soundManager?.playPush()

		const newCurrLetter = guesses[wordIndex][letterIndex].letter
		const newGuesses: Guess[][] = [
			...guesses.filter((v, idx) => idx < wordIndex),
			[
				...(guesses[wordIndex] || []).filter((v, idx) => idx < letterIndex),
				{
					...guesses[wordIndex][letterIndex],
					letter: {
						letter: "",
					},
					complete: false,
				},
				...(guesses[wordIndex] || []).filter((v, idx) => idx > letterIndex),
			],
			...guesses.filter((v, idx) => idx > wordIndex),
		]

		const newLetters = [gameModeState.currLetter, ...gameModeState.letters]

		return {
			...gameModeState,
			guesses: newGuesses,
			currLetter: newCurrLetter,
			letters: newLetters,
			skipsLeft: gameModeState.skipsLeft - 3,
		}
	})

const makeGuess = (
	state: GameManagerState,
	wordIndex: number,
	letterIndex: number,
) =>
	updateCurrentGameState(state, gameModeState => {
		const { currLetter: ltr, guesses, attempts } = gameModeState

		let { skipsLeft, score, gameplayState, wordsEncoded } = gameModeState

		const stats = {
			...gameModeState.stats,
		}

		if (gameplayState !== GameplayState.PLAYING) {
			return gameModeState
		}
		if (
			guesses?.[wordIndex]?.[letterIndex]?.letter?.letter &&
			guesses?.[wordIndex]?.[letterIndex]?.letter?.letter !== ""
		) {
			return gameModeState
		}

		const newGuesses: Guess[][] = [
			...guesses.filter((v, idx) => idx < wordIndex),
			[
				...(guesses[wordIndex] || []).filter((v, idx) => idx < letterIndex),
				{
					...guesses[wordIndex][letterIndex],
					letter: ltr,
					complete: false,
				},
				...(guesses[wordIndex] || []).filter((v, idx) => idx > letterIndex),
			],
			...guesses.filter((v, idx) => idx > wordIndex),
		]

		const word = newGuesses[wordIndex]
			.map(guess => guess.letter.letter?.toLowerCase())
			.join("")
		const completeWord = word.length === newGuesses[wordIndex].length

		const invalidGuess = completeWord
			? !wordManager.search(word)
			: !wordManager.searchAll(
					newGuesses?.[wordIndex]?.map(g => g?.letter?.letter?.toLowerCase()),
			  )

		if (invalidGuess) {
			return gameModeState
		}

		if (
			gameModeState.attempts === 1 &&
			!guesses.some(row =>
				row.some(guess => guess.letter && guess.letter?.letter !== ""),
			)
		) {
			stats.total += 1
		}

		if (completeWord && !invalidGuess) {
			newGuesses[wordIndex] = newGuesses[wordIndex].map((guess, idx) => {
				return {
					...guess,
					letter: {
						...guess.letter,
						multiplier: 1,
					},
					complete: true,
				}
			})
			const oldScore = gameModeState.score
			const wordScore = newGuesses[wordIndex].reduce(
				(acc, letter) => acc + (SCORES[letter.letter.letter] || 0),
				0,
			)
			const newScore = oldScore + wordScore

			if (Math.floor(oldScore / 50) !== Math.floor(newScore / 50)) {
				skipsLeft = skipsLeft + 2
			}
			score = newScore
			stats.totalScore = (stats.totalScore || 0) + wordScore
			stats.totalWords = (stats.totalWords || 0) + 1
		}
		if (checkGameWon(newGuesses)) {
			stats.currentStreak += 1
			stats.won += 1
			if (stats.maxStreak < stats.currentStreak) {
				stats.maxStreak = stats.currentStreak
			}
			gameplayState = GameplayState.WON
			const encodedScore = wordsEncoded ? getEncodedScore(wordsEncoded) : null
			if (encodedScore && score > encodedScore) {
				stats.beatUs = (stats.beatUs || 0) + 1
			}
			score = score * (attempts === 1 ? 2 : 1)
			stats.experience = (stats.experience || 0) + score
			stats.lastTenMatches = [
				...(stats.lastTenMatches || []).reduce(
					(acc, c, idx) =>
						idx === 0 && (stats.lastTenMatches || []).length === 10
							? acc
							: [...acc, c],
					[] as number[],
				),
				score,
			]
			stats.average = getNewAverage(stats, score)
			if (stats.topScore < score) {
				stats.topScore = score
			}
			setTimeout(() => askForReview(), 1000)
		}

		return {
			...gameModeState,
			skipsLeft,
			score,
			gameplayState,
			wordsEncoded,
			numGuesses: gameModeState.numGuesses + 1,
			guesses: newGuesses,
			stats,
			...getNextLetterState(gameModeState),
		}
	})

const checkGameOver = (gameModeState: GameModeState, gameMode: GameMode) => {
	if (
		gameModeState.gameplayState === GameplayState.PLAYING &&
		gameModeState.skipsLeft === 0 &&
		checkGameLost(
			gameModeState.guesses,
			gameModeState.currLetter?.letter,
			gameModeState.holdLetter?.letter,
			gameMode,
		)
	) {
		gameModeState.gameplayState = getGameOverState(
			gameMode,
			gameModeState.attempts,
		)
		const { attemptsAllowed, shouldCountScoreOnLoss } =
			GAME_MODE_CONFIG[gameMode]
		if (attemptsAllowed && gameModeState.attempts >= attemptsAllowed) {
			gameModeState.stats.currentStreak = 0
		}
		if (gameModeState.gameplayState === GameplayState.LOST) {
			const gameStats = { ...gameModeState.stats }
			const finalScore = shouldCountScoreOnLoss ? gameModeState.score || 0 : 0

			gameStats.lastTenMatches = [
				...(gameStats.lastTenMatches || []).reduce(
					(acc, c, idx) =>
						idx === 0 && (gameStats.lastTenMatches || []).length === 10
							? acc
							: [...acc, c],
					[] as number[],
				),
				finalScore,
			]

			if (shouldCountScoreOnLoss)
				gameStats.average = getNewAverage(gameStats, finalScore)

			if ((gameStats.topScore || 0) < finalScore || 0) {
				gameStats.topScore = finalScore
			}
			gameModeState.stats = gameStats
		}
	}
	return gameModeState
}

const GameManager: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const { value: seed } = useContext(TimeContext)
	const SoundManager = useContext(SoundContext)
	const soundLoaded = useGameStore(state => !!state.soundManager)
	const loaded = useGameStore.persist.hasHydrated()
	const setGameMode = useGameStore(state => state.setGameMode)
	const isMuted = useGameStore(state => state.gameState.isMuted)
	const setCurrentVersion = useGameStore(state => state.setCurrentVersion)
	const setLastCheckTime = useGameStore(state => state.setLastCheckTime)
	const currentChallengeSeed = useGameStore(
		state => state.gameState.currentChallengeSeed,
	)
	const loadNewSeed = useGameStore(state => state.loadNewSeed)
	const setLoadedSeed = useGameStore(state => state.setLoadedSeed)
	const loadFromOldState = useGameStore(state => state.loadFromOldState)
	const setSoundManager = useGameStore(state => state.setSoundManager)
	useEffect(() => {
		getCurrentGameVersion().then(_currentVersion => {
			if (_currentVersion !== null) {
				setCurrentVersion(_currentVersion)
			}
			setLastCheckTime(Date.now())
		})
	}, [setCurrentVersion, setLastCheckTime])

	useEffect(() => {
		if (loaded && SoundManager) {
			if (SoundManager.muted !== isMuted) {
				SoundManager.setMuted(isMuted)
			} else {
				setSoundManager(SoundManager)
			}
		}
	}, [loaded, setSoundManager, SoundManager, isMuted])

	useEffect(() => {
		const loadAndSet = async () => {
			try {
				const oldGameState = await AsyncStorage.getItem("CURR_GAME_STATE")

				if (oldGameState) {
					loadFromOldState(JSON.parse(oldGameState))
					await AsyncStorage.removeItem("CURR_GAME_STATE")
				}
			} catch (e) {
				console.log(e)
			}

			if (loaded && seed && seed !== NO_SEED && seed !== currentChallengeSeed) {
				loadNewSeed(seed)
			}
			setLoadedSeed(seed)
		}
		loadAndSet()
	}, [
		loaded,
		seed,
		currentChallengeSeed,
		setGameMode,
		loadNewSeed,
		loadFromOldState,
		setLoadedSeed,
	])

	return <>{loaded && soundLoaded ? children : null}</>
}

export default GameManager

const checkGameWon = (guesses: Guess[][]) =>
	!guesses.some(guess => guess[0].complete !== true)

const fillWord = (guess: Guess[], nextLetter: string): string =>
	guess.reduce((acc, curr) => {
		if (!curr.letter || curr.letter.letter === "") {
			acc = acc + nextLetter
		} else {
			acc = acc + curr.letter.letter
		}
		return acc
	}, "")

const isValidOneWordLetter = ["a", "i"]

const checkGameLost = (
	guesses: Guess[][],
	nextLetter?: string,
	holdLetter?: string,
	gameMode?: GameMode,
) =>
	guesses.every((guess, idx) => {
		const isWordComplete = guess[0].complete
		const isWordOneAway =
			guess.map(g => g.letter.letter).join("").length === idx
		let isValidWordWithNextLetter =
			nextLetter && wordManager.search(fillWord(guess, nextLetter))
		let isValidWordWithHeldLetter =
			holdLetter && wordManager.search(fillWord(guess, holdLetter))
		const hasPopulatedHoldLetter = holdLetter && holdLetter !== ""

		if (isWordComplete && GAME_MODE_CONFIG[gameMode]?.shouldClearOnComplete) {
			if (
				idx > 0 ||
				isValidOneWordLetter.includes(nextLetter) ||
				isValidOneWordLetter.includes(holdLetter)
			)
				return false
		}
		if (!hasPopulatedHoldLetter) {
			return false
		}
		if (!isWordComplete && !isWordOneAway) {
			isValidWordWithNextLetter =
				isValidWordWithNextLetter ||
				wordManager.searchAll(
					guess.map(g => g.letter?.letter),
					nextLetter,
				)
			isValidWordWithHeldLetter =
				isValidWordWithHeldLetter ||
				wordManager.searchAll(
					guess.map(g => g.letter?.letter),
					holdLetter,
				)
		}
		return (
			isWordComplete ||
			(!isValidWordWithHeldLetter && !isValidWordWithNextLetter)
		)
	})

const getCurrentGameVersion = async () => {
	let hasNewVersion = null
	if (Platform.OS !== "web") {
		return false
	}
	try {
		const resp = await fetch(`${getBaseUrl()}/check.html`, {
			body: null,
			method: "GET",
		})

		resp.headers.forEach((value, key) => {
			if (key?.toLowerCase() === "x-word-tree-version") {
				hasNewVersion = value
			}
		})
	} catch (e) {
		console.log(e)
	}
	return hasNewVersion
}

const getGameOverState = (gameMode: GameMode, attempts: number) => {
	if (
		GAME_MODE_CONFIG[gameMode].gameOverCondition === "ON_NO_MOVES" ||
		(GAME_MODE_CONFIG[gameMode].gameOverCondition === "ON_ATTEMPTS" &&
			attempts >= GAME_MODE_CONFIG[gameMode].attemptsAllowed)
	) {
		return GameplayState.LOST
	} else {
		return GameplayState.NO_MOVES
	}
}

const getNewAverage = (stats, score): [number, number] => {
	const prevTotal = stats.average?.[1] || 0
	const oldMax = (stats.average?.[0] || 0) * prevTotal
	const newMax = oldMax + (score || 0)

	return [newMax / (prevTotal + 1), prevTotal + 1]
}

export const getEncodedScore = (wordsEncoded: string): number => {
	try {
		const words = JSON.parse(window.atob(wordsEncoded))
		return words
			.join("")
			.split("")
			.reduce((acc, ltr) => acc + SCORES[ltr], 0)
	} catch (e) {
		console.log("Parsing error", e)
		return 0
	}
}
