/** @format */

import React, { useContext } from "react"
import { Modal, View, Text, ScrollView } from "react-native"

import SoundContext from "../../../../utils/SoundContext"
import Button from "../../../components/Button"
import Credits from "../../../components/Credits"
import { CheckIcon, CloseIcon } from "../../../components/Icon"
import { useGameStore } from "../../../context/GameManager/GameManager"
import { GameMode } from "../../../context/types"
import { styles } from "../../../styles"
import { colors } from "../../../styles/styles"
import { FooterButton } from "../../footer/components/Footer"

const TutorialModalInner = ({
	showSetTutorial,
	setShowSetTutorial,
	SoundManager,
}) => {
	const setGameMode = useGameStore(state => state.setGameMode)
	return (
		<View
			style={{ ...styles.centeredView, backgroundColor: "rgba(0,0,0,0.3)" }}
		>
			<View
				style={{ ...styles.modalView, backgroundColor: colors["grey-100"] }}
			>
				<View style={styles.topRightButtonView}>
					<View style={styles.topRightButton}>
						<Text
							allowFontScaling={false}
							style={{
								...styles.mediumText,
								...styles.boldText,
							}}
						>
							TUTORIAL
						</Text>
					</View>
					<Button
						style={styles.topRightButton}
						labelStyle={{
							...styles.mediumText,
							...styles.boldText,
							...styles.darkText,
						}}
						onPressIn={() => SoundManager.playPush()}
						onPress={() => {
							setShowSetTutorial(!showSetTutorial)
						}}
					>
						<View style={{ width: 24, height: 24 }}>
							<CloseIcon />
						</View>
					</Button>
				</View>
				<ScrollView style={{ width: "100%" }}>
					<View style={{ marginTop: 15 }}>
						<View
							style={{
								...styles.instructionTextSection,
								flexDirection: "column",
								display: "flex",
								width: "100%",
								paddingHorizontal: 15,
							}}
						>
							<View>
								<View
									style={{
										borderColor: colors["grey-900"],
										borderWidth: 1,
										padding: 15,
										marginBottom: 15,
										borderRadius: 5,
										backgroundColor: colors["grey-050"],
										zIndex: 1,
										height: 115,
									}}
								>
									<View
										style={{
											display: "flex",
											justifyContent: "space-between",
											alignContent: "center",
											flexDirection: "row",
											borderColor: colors["grey-400"],
											borderBottomWidth: 1,
											marginBottom: 10,
										}}
									>
										<View
											style={{
												display: "flex",
												justifyContent: "center",
												paddingBottom: 10,
											}}
										>
											<Text
												allowFontScaling={false}
												style={{
													...styles.mediumText,
													...styles.boldText,
													...styles.darkText,
												}}
											>
												Would you like to view the tutorial?
											</Text>
										</View>
									</View>
									<View
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											flexGrow: 1,
										}}
									>
										<Text
											allowFontScaling={false}
											style={{
												...styles.smallText,
												...styles.darkText,
												fontWeight: "normal",
											}}
										>
											Your progress will be saved and you can return to the game
											at any time with the game mode button.
										</Text>
									</View>
								</View>
								<View
									style={{
										position: "absolute",
										top: 4,
										height: 115,
										width: "100%",
										zIndex: 0,
										borderColor: "transparent",
										borderRadius: 5,
										backgroundColor: colors["grey-900"],
									}}
								/>
							</View>
						</View>
						<View
							style={{
								...styles.instructionTextSection,
								flexDirection: "column",
								display: "flex",
								width: "100%",
								paddingHorizontal: 15,
							}}
						>
							<FooterButton
								onPress={() => {
									setShowSetTutorial(false)
									setGameMode(GameMode.TUTORIAL)
								}}
								style={{ height: undefined, padding: 15 }}
							>
								<View
									style={[
										styles.buttonLabel,

										{
											display: "flex",
											justifyContent: "space-between",
											flexDirection: "row",
											width: "100%",
										},
									]}
								>
									<Text
										style={{
											...styles.mediumText,
											...styles.boldText,
											textAlign: "center",
											textAlignVertical: "center",
										}}
									>
										YES
									</Text>
									<View
										style={{
											right: 0,
											width: 24,
											height: 24,
										}}
									>
										<CheckIcon />
									</View>
								</View>
							</FooterButton>
						</View>
						<View
							style={{
								...styles.instructionTextSection,
								flexDirection: "column",
								display: "flex",
								width: "100%",
								paddingHorizontal: 15,
							}}
						>
							<FooterButton
								onPress={() => {
									setShowSetTutorial(false)
								}}
								style={{
									height: undefined,
									padding: 15,
									backgroundColor: colors["red-400"],
									borderColor: colors["red-700"],
								}}
							>
								<View
									style={[
										styles.buttonLabel,

										{
											display: "flex",
											justifyContent: "space-between",
											flexDirection: "row",
											width: "100%",
										},
									]}
								>
									<Text
										style={{
											...styles.mediumText,
											...styles.boldText,
											textAlign: "center",
											textAlignVertical: "center",
										}}
									>
										NO
									</Text>
									<View
										style={{
											right: 0,
											width: 24,
											height: 24,
										}}
									>
										<CloseIcon />
									</View>
								</View>
							</FooterButton>
							<View style={{ marginTop: 15 }}>
								<Credits />
							</View>
						</View>
					</View>
				</ScrollView>
			</View>
		</View>
	)
}

export const TutorialModal = () => {
	const showSetTutorial = useGameStore(state => state.showSetTutorial)
	const setShowSetTutorial = useGameStore(state => state.setShowSetTutorial)
	const SoundManager = useContext(SoundContext)

	return (
		<Modal
			animationType="fade"
			transparent={true}
			visible={showSetTutorial}
			onRequestClose={async () => {
				SoundManager.playPush()
				setShowSetTutorial(!showSetTutorial)
			}}
		>
			<TutorialModalInner
				showSetTutorial={showSetTutorial}
				setShowSetTutorial={setShowSetTutorial}
				SoundManager={SoundManager}
			/>
		</Modal>
	)
}

export default TutorialModal
