/** @format */
import * as Linking from "expo-linking"
import { Platform, Pressable, View } from "react-native"

import TutorialArrow from "./TutorialArrow"
import { useGameStore } from "../../../context/GameManager/GameManager"
import { GameMode, GameplayState } from "../../../context/types"
import { styles } from "../../../styles"
import { ResetButton, SkipButton } from "../../footer/components/ActionRow"
import AppStoreButton from "../../footer/components/AppStoreButton"
import CurrentLetter from "../../footer/components/CurrentLetter"
import GooglePlayButton from "../../footer/components/GooglePlayButton"
import { TUTORIAL_STEPS } from "../types/types"

const TutorialFooter: React.FC<{
	tutorialStep: number
	nextStep: () => void
}> = ({ tutorialStep, nextStep }) => {
	const gameplayState = useGameStore(
		({ gameState }) => gameState[gameState.gameMode]?.gameplayState,
	)

	const currLetterOverride =
		tutorialStep === TUTORIAL_STEPS.TOUGHER_LETTER ? { letter: "z" } : undefined
	return (
		<View style={{ maxWidth: 450, width: "100%", flexGrow: 2, zIndex: 1 }}>
			<View
				style={{
					...styles.box,
					marginBottom: 15,
					alignItems: "center",
					height: 60,
				}}
			>
				{tutorialStep >= TUTORIAL_STEPS.RESET_BUTTON ? (
					<ResetButton
						disabled={
							tutorialStep < TUTORIAL_STEPS.GAME_MODE ||
							gameplayState === GameplayState.WON
						}
						gameplayState={gameplayState}
						gameMode={GameMode.TUTORIAL}
					/>
				) : (
					<View style={{ minHeight: 50, minWidth: 115 }} />
				)}
				{tutorialStep >= TUTORIAL_STEPS.CURRENT_LETTER ? (
					<CurrentLetter
						currLetterOverride={currLetterOverride}
						gameMode={GameMode.TUTORIAL}
						gameOver={
							gameplayState === GameplayState.LOST ||
							gameplayState === GameplayState.NO_MOVES
						}
					/>
				) : null}
				{tutorialStep >= TUTORIAL_STEPS.SKIP_BUTTON ? (
					<SkipButton
						gameplayState={gameplayState}
						canSkip={
							tutorialStep === TUTORIAL_STEPS.SKIP_BUTTON ||
							tutorialStep >= TUTORIAL_STEPS.GAME_MODE
						}
						onSkip={() => {
							if (tutorialStep === TUTORIAL_STEPS.SKIP_BUTTON) {
								nextStep()
							}
						}}
					/>
				) : (
					<View style={{ minHeight: 50, minWidth: 115 }} />
				)}
				{tutorialStep === TUTORIAL_STEPS.SKIP_BUTTON && (
					<TutorialArrow right={75} top={15} />
				)}
			</View>
			{Platform.OS === "web" && (
				<View
					style={{
						width: "100%",
						display: "flex",
						justifyContent: "center",
						flexDirection: "row",
						marginTop: 15,
					}}
				>
					<Pressable
						onPress={() =>
							Linking.openURL(
								"https://apps.apple.com/us/app/wordtree-a-daily-word-puzzle/id6446817670",
							)
						}
						style={{
							width: 170,
							display: "flex",
							justifyContent: "flex-end",
							flexDirection: "row",
							marginRight: 5,
						}}
					>
						<AppStoreButton />
					</Pressable>
					<Pressable
						onPress={() =>
							Linking.openURL(
								"https://play.google.com/store/apps/details?id=com.hdwatts.wordtree",
							)
						}
						style={{ marginLeft: 5 }}
					>
						<GooglePlayButton />
					</Pressable>
				</View>
			)}
		</View>
	)
}

export default TutorialFooter
