/** @format */

import { MotiPressable } from "moti/interactions"
import React, { useContext, useState } from "react"
import { Linking, Modal, Pressable, ScrollView, Text, View } from "react-native"

import SoundContext from "../../../../utils/SoundContext"
import Button from "../../../components/Button"
import { ChallengeIcon, ShareIcon } from "../../../components/Icon"
import { useGameStore } from "../../../context/GameManager/GameManager"
import { styles } from "../../../styles"
import { colors } from "../../../styles/styles"

export const ChallengeModal = () => {
	const challengeSeed = useGameStore(state => state.challengeSeed)
	const setChallengeSeed = useGameStore(state => setChallengeSeed)

	const SoundManager = useContext(SoundContext)
	return (
		<Modal
			animationType="fade"
			transparent={true}
			visible={challengeSeed !== null}
			onRequestClose={async () => {
				SoundManager.playPush()
				setChallengeSeed(null)
			}}
		>
			<View style={styles.centeredView}>
				<View style={styles.modalView}>
					<View style={styles.topRightButtonView}>
						<View style={styles.topRightButton}>
							<Text
								allowFontScaling={false}
								style={{
									...styles.mediumText,
									...styles.boldText,
									...styles.darkGreyText,
								}}
							>
								DUEL
							</Text>
						</View>
						<Button
							style={styles.topRightButton}
							labelStyle={{
								...styles.mediumText,
								...styles.boldText,
								...styles.darkText,
							}}
							onPressIn={() => SoundManager.playPush()}
							onPress={() => {
								setChallengeSeed(null)
							}}
						>
							CLOSE
						</Button>
					</View>
					<ScrollView style={{ paddingHorizontal: 35, paddingBottom: 35 }}>
						<View style={styles.instructionTextSection}>
							<Text
								allowFontScaling={false}
								style={{
									...styles.normalText,
									marginVertical: 10,
									...styles.darkText,
								}}
							>
								Duel Mode
							</Text>
						</View>
						<View style={styles.instructionTextSection}>
							<Text
								allowFontScaling={false}
								style={{
									...styles.mediumText,
									fontWeight: "normal",
									...styles.darkGreyText,
								}}
							>
								In Duel mode you can share a link to your friends and compete
								for the highest score. Everyone who clicks the link will get the
								same puzzle.
							</Text>
						</View>
						<View style={styles.instructionTextSection}>
							<Text
								allowFontScaling={false}
								style={{
									...styles.mediumText,
									fontWeight: "normal",
									...styles.darkGreyText,
								}}
							>
								Don't worry, for now Duels are not reflected in your stats, but
								we may open up Duel specific stats in the future!
							</Text>
						</View>
						<View style={styles.instructionTextSection}>
							<Text
								allowFontScaling={false}
								style={{
									...styles.mediumText,
									fontWeight: "normal",
									...styles.darkGreyText,
								}}
							>
								Your duel link is:
							</Text>
							<DuelLink challengeSeed={challengeSeed} />
						</View>
						<View style={styles.instructionTextSection}>
							<ShareButton challengeSeed={challengeSeed} />
						</View>
						<View style={styles.instructionTextSection}>
							<GoToChallenge challengeSeed={challengeSeed} />
						</View>
						<View
							style={{
								...styles.instructionTextSection,
								marginBottom: 0,
								marginTop: 10,
								paddingBottom: 0,
							}}
						>
							<Text
								allowFontScaling={false}
								style={{
									...styles.smallText,
									...styles.darkGreyText,
									fontWeight: "normal",
									justifyContent: "flex-end",
									display: "flex",
								}}
							>
								WordTree was made by{" "}
								<Text
									allowFontScaling={false}
									style={{ ...styles.darkText, ...styles.boldText }}
									onPress={() => Linking.openURL("https://www.hdwatts.com/")}
								>
									hdwatts
								</Text>
								.
							</Text>
						</View>
					</ScrollView>
				</View>
			</View>
		</Modal>
	)
}

export default ChallengeModal

const GoToChallenge = ({ challengeSeed }) => {
	const url = `${window.location.origin}/?duel=${challengeSeed}`
	return (
		<MotiPressable
			style={{ ...styles.footerButton, alignItems: "center" }}
			onPress={() => {
				window.location.href = url
			}}
		>
			<View
				style={{
					...styles.centerBox,
					...styles.shareButton,
					maxWidth: "100%",
				}}
			>
				<View
					style={{
						display: "flex",
						justifyContent: "center",
						flexDirection: "row",
						alignItems: "center",
					}}
				>
					<Text
						allowFontScaling={false}
						style={{
							...styles.boldText,
							marginRight: 5,
							color: colors["grey-050"],
						}}
					>
						GO TO DUEL
					</Text>
					<View style={{ height: 16, width: 16 }}>
						<ChallengeIcon />
					</View>
				</View>
			</View>
		</MotiPressable>
	)
}

export const ShareButton = ({ challengeSeed }) => {
	const [copied, setCopied] = useState(false)

	const text = `${window.location.origin}/?duel=${challengeSeed}\nCan you beat my score in WordTree? I'm challenging you to a duel! Click the link to accept!`
	return (
		<MotiPressable
			style={{ ...styles.footerButton, alignItems: "center" }}
			onPress={async () => {
				try {
					if (
						navigator.canShare({
							text,
						})
					) {
						await navigator.share({
							text,
						})
					} else {
						setCopied(true)
						setTimeout(() => setCopied(false), 1000)
						navigator.clipboard.writeText(`${text}`)
					}
				} catch (e: unknown) {
					if (!e.toString().includes("AbortError")) {
						setCopied(true)
						setTimeout(() => setCopied(false), 1000)
						navigator.clipboard.writeText(`${text}`)
					}
				}
			}}
		>
			<View
				style={{
					...styles.centerBox,
					...styles.shareButton,
					maxWidth: "100%",
				}}
			>
				<View
					style={{
						display: "flex",
						justifyContent: "center",
						flexDirection: "row",
						alignItems: "center",
					}}
				>
					{!copied ? (
						<>
							<Text
								allowFontScaling={false}
								style={{
									fontSize: 16,
									...styles.boldText,
									marginRight: 5,
									color: colors["grey-050"],
								}}
							>
								SHARE DUEL
							</Text>
							<ShareIcon color={colors["grey-050"]} />
						</>
					) : (
						<Text
							allowFontScaling={false}
							style={{
								fontSize: 16,
								...styles.boldText,
								color: colors["grey-050"],
							}}
						>
							COPIED!
						</Text>
					)}
				</View>
			</View>
		</MotiPressable>
	)
}

const DuelLink = ({ challengeSeed }) => {
	const url = `${window.location.origin}/?duel=${challengeSeed}`
	return (
		<Pressable
			onPress={() => {
				window.location.href = url
			}}
		>
			<Text
				allowFontScaling={false}
				style={{
					...styles.mediumText,
					...styles.boldText,
					...styles.darkText,
					marginTop: 5,
					fontSize: 12,
					textAlign: "center",
				}}
			>
				<Text
					allowFontScaling={false}
					style={{ ...styles.darkGreyText, fontWeight: "normal" }}
				>
					www.wordtree.app/?duel=
				</Text>
				{challengeSeed?.toUpperCase()}
			</Text>
		</Pressable>
	)
}
