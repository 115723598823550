/** @format */

import { MotiView } from "moti"
import { useMemo } from "react"
import { View, Text } from "react-native"

import { useGameStore } from "../../../context/GameManager/GameManager"
import { colors, styles } from "../../../styles/styles"

const HEIGHT = 20
const SCALING = 1.32
const CONSTANT = 0.15

export const Experience = () => {
	return null
	const experience = useGameStore(
		state => state.gameState?.[state.gameState?.gameMode]?.stats?.experience,
	)
	const level = useMemo(() => {
		if (!experience || experience === 0) {
			return 1
		}
		return Math.floor(Math.pow(experience, 1 / SCALING) * CONSTANT)
	}, [experience])
	const { needed, current } = useMemo(() => {
		const currLevelExp =
			level === 1 ? 0 : Math.ceil(Math.pow(level / CONSTANT, SCALING))
		const nextLevelExp = Math.ceil(Math.pow((level + 1) / CONSTANT, SCALING))

		console.log(experience, level, currLevelExp, nextLevelExp)
		return {
			needed: nextLevelExp - currLevelExp,
			current: (experience || 0) - currLevelExp,
		}
	}, [experience, level])
	return (
		<View
			style={{
				width: "100%",
				position: "absolute",
				bottom: 0,
				height: HEIGHT,
				overflow: "hidden",
			}}
		>
			<View style={{ position: "relative" }}>
				<MotiView
					from={{ width: 0 }}
					style={{
						backgroundColor: colors["yellow-050"],
						height: HEIGHT,
						width: "100%",
						position: "absolute",
						top: 0,
						left: 0,
					}}
				/>
				<MotiView
					style={{
						backgroundColor: colors["yellow-400"],
						height: HEIGHT,
						width: `${(current / needed) * 100}%`,
						position: "absolute",
						top: 0,
						left: 0,
					}}
				/>
				<View
					style={{
						position: "absolute",
						width: "100%",
					}}
				>
					<View
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							maxWidth: 450,
							alignSelf: "center",
							width: "100%",
							padding: 2,
						}}
					>
						<Text
							allowFontScaling={false}
							style={{
								textAlign: "center",
								textAlignVertical: "center",
								display: "flex",
								justifyContent: "center",
								alignContent: "center",
								...styles.boldText,
								color: colors["yellow-700"],
							}}
						>
							Level: {level}
						</Text>
						<Text
							allowFontScaling={false}
							style={{
								textAlign: "center",
								textAlignVertical: "center",
								display: "flex",
								justifyContent: "center",
								alignContent: "center",
								...styles.boldText,
								color: colors["yellow-700"],
							}}
						>
							{current} / {needed}
						</Text>
					</View>
				</View>
			</View>
		</View>
	)
}

export default Experience
