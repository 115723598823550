/** @format */
import { useState } from "react"
import { View } from "react-native"

import TutorialArrow from "./TutorialArrow"
import TutorialTip from "./TutorialTip"
import { LeftRightIcon } from "../../../components/Icon"
import { useGameStore } from "../../../context/GameManager/GameManager"
import { GameMode } from "../../../context/types"
import { styles } from "../../../styles"
import { colors, noSelect } from "../../../styles/styles"
import GameModeButton from "../../wordTree/components/GameModeButton"
import HoldButton from "../../wordTree/components/HoldButton"
import WordButton from "../../wordTree/components/WordButton/WordButton"
import { TUTORIAL_STEPS } from "../types/types"

const TutorialTree = ({ tutorialStep, nextStep }) => {
	const setShouldSetGameMode = useGameStore(state => state.setShouldSetGameMode)

	const [hidePArrow, setHidePArrow] = useState(false)

	if (tutorialStep < TUTORIAL_STEPS.WELCOME) {
		return (
			<View
				style={{
					height: 365,
					maxHeight: 415,
					justifyContent: "flex-start",
					width: "100%",
					maxWidth: 450,
				}}
			>
				<View style={styles.wordTree}>
					<GameModeButton
						gameMode={GameMode.TUTORIAL}
						setShouldSetGameMode={val => {
							if (tutorialStep >= TUTORIAL_STEPS.SET_GAME_MODE) {
								nextStep()
							}
							setShouldSetGameMode(val)
						}}
					/>
				</View>
			</View>
		)
	}
	return (
		<View
			style={{
				height: 365,
				maxHeight: 415,
				justifyContent: "flex-end",
				width: "100%",
				maxWidth: 450,
				zIndex: 2,
				...noSelect,
			}}
		>
			<View style={styles.wordTree}>
				<GameModeButton
					gameMode={GameMode.TUTORIAL}
					setShouldSetGameMode={val => {
						if (tutorialStep >= TUTORIAL_STEPS.SET_GAME_MODE) {
							nextStep()
						}
						setShouldSetGameMode(val)
					}}
					badge={
						tutorialStep >= TUTORIAL_STEPS.SET_GAME_MODE && (
							<View
								style={{
									...styles.buttonBadge,
									backgroundColor: colors["grey-800"],
									shadowColor: "rgba(0, 0, 0, 0.24)",
									shadowOffset: { width: 0, height: 3 },
									shadowRadius: 8,
								}}
							>
								<View style={{ width: 14, height: 14, marginVertical: 4 }}>
									<LeftRightIcon />
								</View>
							</View>
						)
					}
				/>
				{tutorialStep >= TUTORIAL_STEPS.SET_GAME_MODE && (
					<TutorialArrow top={15} left={15} />
				)}

				{tutorialStep >= TUTORIAL_STEPS.HOLD_LETTER && (
					<>
						<HoldButton
							canPress={
								TUTORIAL_STEPS.HOLD_LETTER === tutorialStep ||
								TUTORIAL_STEPS.SWAP_HOLD === tutorialStep ||
								tutorialStep >= TUTORIAL_STEPS.GAME_MODE
							}
							onPress={() => {
								if (
									TUTORIAL_STEPS.HOLD_LETTER === tutorialStep ||
									TUTORIAL_STEPS.SWAP_HOLD === tutorialStep
								) {
									nextStep()
								}
							}}
						/>
						{(tutorialStep === TUTORIAL_STEPS.HOLD_LETTER ||
							tutorialStep === TUTORIAL_STEPS.SWAP_HOLD) && (
							<TutorialArrow top={15} right={25} dark />
						)}
					</>
				)}
				<TutorialTip
					step={TUTORIAL_STEPS.WELCOME}
					tutorialStep={tutorialStep}
					text="Welcome to WordTree!"
					style={{ bottom: 0 }}
					showContinueText
					nextStep={nextStep}
				/>
				<TutorialTip
					tutorialStep={tutorialStep}
					step={TUTORIAL_STEPS.WORD_TREE_INTRO}
					style={{ top: 300 }}
					text="This is the WordTree. Your goal is to fill each row with valid words."
					showContinueText
					nextStep={nextStep}
				/>
				<TutorialTip
					step={TUTORIAL_STEPS.CURRENT_LETTER}
					tutorialStep={tutorialStep}
					text="Below the tree is your current letter: 'A'. The letter 'A' is worth 1 point."
					style={{ bottom: 0 }}
					showContinueText
					nextStep={nextStep}
				/>

				<TutorialTip
					step={TUTORIAL_STEPS.TOUGHER_LETTER}
					tutorialStep={tutorialStep}
					text="Tougher letters are worth more points!"
					style={{ bottom: 0 }}
					showContinueText
					nextStep={nextStep}
				/>
				<TutorialTip
					tutorialStep={tutorialStep}
					step={TUTORIAL_STEPS.PLACE_A}
					text="You can place the current letter on the tree by pressing on an empty space."
					style={{ top: 200 }}
				/>

				<TutorialTip
					tutorialStep={tutorialStep}
					step={TUTORIAL_STEPS.PLACE_A}
					text="'A' is a valid word. So let's put the 'A' on the top of the tree!"
					style={{ top: 275 }}
				/>
				<TutorialTip
					tutorialStep={tutorialStep}
					step={TUTORIAL_STEPS.SCORE}
					text="When you complete a word the score of its letters is added to your total score at the top of the screen."
					nextStep={nextStep}
					showContinueText
					style={{ top: 0 }}
				/>
				<TutorialTip
					tutorialStep={tutorialStep}
					step={TUTORIAL_STEPS.PLACE_O}
					text="Nice! Now our current letter is 'O'. There are many 3 letter words with 'O' in the middle, so let's place it in that location on the tree."
					style={{ bottom: 0 }}
				/>
				<TutorialTip
					tutorialStep={tutorialStep}
					step={TUTORIAL_STEPS.PLACE_D}
					text="Great job! Our current letter is now 'D'. Let's go for the word 'DOG'! Place the 'D' in the third row."
					style={{ bottom: 0 }}
				/>
				<TutorialTip
					tutorialStep={tutorialStep}
					step={TUTORIAL_STEPS.PLACE_P}
					text="Keep it up! Our current letter is now 'P'. Let's place it at the end of the second row to go for 'UP'."
					style={{ bottom: 0 }}
				/>
				<TutorialTip
					tutorialStep={tutorialStep}
					step={TUTORIAL_STEPS.HOLD_LETTER}
					text="'Y' is a letter worth a lot of points . Let's store it for later by using the 'HOLD' space!"
					style={{ bottom: 0 }}
				/>
				<TutorialTip
					tutorialStep={tutorialStep}
					step={TUTORIAL_STEPS.PLACE_G}
					text="There is the 'G'! Let's place it on the WordTree and finish the word 'DOG'."
					style={{ bottom: 0 }}
				/>
				<TutorialTip
					tutorialStep={tutorialStep}
					step={TUTORIAL_STEPS.SKIP_BUTTON}
					text="This is a tough letter to fit on the tree. Thankfully, we can SKIP it with the skip button."
					style={{ bottom: 0 }}
				/>
				<TutorialTip
					tutorialStep={tutorialStep}
					nextStep={nextStep}
					showContinueText
					step={TUTORIAL_STEPS.SKIP_BUTTON_2}
					text="You only have 10 skips per puzzle, so use them wisely!"
					style={{ bottom: 0 }}
				/>
				<TutorialTip
					tutorialStep={tutorialStep}
					step={TUTORIAL_STEPS.SWAP_HOLD}
					text="Let's get that 'Y' on the board. You can press the 'HOLD' space again to swap the held letter with the current letter."
					style={{ bottom: 0 }}
				/>
				<TutorialTip
					tutorialStep={tutorialStep}
					step={TUTORIAL_STEPS.PLACE_Y}
					text="Now let's place that 'Y' at the end of the fourth row, to go for words like 'PLAY' or 'FOXY'."
					style={{ bottom: 0 }}
				/>
				<TutorialTip
					tutorialStep={tutorialStep}
					step={TUTORIAL_STEPS.REMOVE_P}
					text="We want to go for 'PLAY' and we already have a 'P' in the second row! We can remove a guessed letter from the tree and make it our current letter by long pressing on it."
					style={{ bottom: 0 }}
				/>
				<TutorialTip
					tutorialStep={tutorialStep}
					step={TUTORIAL_STEPS.REMOVE_P}
					text="Be careful though - removing a letter from the tree costs 3 skips!"
					style={{ bottom: -75 }}
				/>
				<TutorialTip
					tutorialStep={tutorialStep}
					step={TUTORIAL_STEPS.RESET_BUTTON}
					text="While the letters seem random, there's guaranteed to be at least one valid solution! If you are unable to make a valid move you will need to reset the tree."
					style={{ bottom: 0 }}
					nextStep={nextStep}
					showContinueText
				/>
				<TutorialTip
					tutorialStep={tutorialStep}
					step={TUTORIAL_STEPS.GAME_MODE}
					text="You're now ready to play WordTree! Finish this puzzle if you'd like. Whenever you're ready there are many game modes to choose from. Click the 'MODE' button to pick a game mode and have fun!"
					nextStep={nextStep}
					showContinueText
					style={{ top: 300 }}
				/>
				<View style={styles.centerBoxRow}>
					{makeWordButton(
						tutorialStep,
						0,
						0,
						nextStep,
						setHidePArrow,
						hidePArrow,
					)}
				</View>
				<View style={styles.centerBoxRow}>
					{makeWordButton(
						tutorialStep,
						1,
						0,
						nextStep,
						setHidePArrow,
						hidePArrow,
					)}
					{makeWordButton(
						tutorialStep,
						1,
						1,
						nextStep,
						setHidePArrow,
						hidePArrow,
					)}
				</View>
				<View style={styles.centerBoxRow}>
					{makeWordButton(
						tutorialStep,
						2,
						0,
						nextStep,
						setHidePArrow,
						hidePArrow,
					)}
					{makeWordButton(
						tutorialStep,
						2,
						1,
						nextStep,
						setHidePArrow,
						hidePArrow,
					)}
					{makeWordButton(
						tutorialStep,
						2,
						2,
						nextStep,
						setHidePArrow,
						hidePArrow,
					)}
				</View>
				<View style={styles.centerBoxRow}>
					{makeWordButton(
						tutorialStep,
						3,
						0,
						nextStep,
						setHidePArrow,
						hidePArrow,
					)}
					{makeWordButton(
						tutorialStep,
						3,
						1,
						nextStep,
						setHidePArrow,
						hidePArrow,
					)}
					{makeWordButton(
						tutorialStep,
						3,
						2,
						nextStep,
						setHidePArrow,
						hidePArrow,
					)}
					{makeWordButton(
						tutorialStep,
						3,
						3,
						nextStep,
						setHidePArrow,
						hidePArrow,
					)}
				</View>
				<View style={styles.centerBoxRow}>
					{makeWordButton(
						tutorialStep,
						4,
						0,
						nextStep,
						setHidePArrow,
						hidePArrow,
					)}
					{makeWordButton(
						tutorialStep,
						4,
						1,
						nextStep,
						setHidePArrow,
						hidePArrow,
					)}
					{makeWordButton(
						tutorialStep,
						4,
						2,
						nextStep,
						setHidePArrow,
						hidePArrow,
					)}
					{makeWordButton(
						tutorialStep,
						4,
						3,
						nextStep,
						setHidePArrow,
						hidePArrow,
					)}
					{makeWordButton(
						tutorialStep,
						4,
						4,
						nextStep,
						setHidePArrow,
						hidePArrow,
					)}
				</View>
			</View>
		</View>
	)
}

export default TutorialTree

const pressThisButton = (tutorialStep, wordIndex, letterIndex) => {
	if (
		tutorialStep === TUTORIAL_STEPS.PLACE_A &&
		wordIndex === 0 &&
		letterIndex === 0
	) {
		return true
	} else if (
		tutorialStep === TUTORIAL_STEPS.PLACE_O &&
		wordIndex === 2 &&
		letterIndex === 1
	) {
		return true
	} else if (
		tutorialStep === TUTORIAL_STEPS.PLACE_D &&
		wordIndex === 2 &&
		letterIndex === 0
	) {
		return true
	} else if (
		tutorialStep === TUTORIAL_STEPS.PLACE_P &&
		wordIndex === 1 &&
		letterIndex === 1
	) {
		return true
	} else if (
		tutorialStep === TUTORIAL_STEPS.PLACE_G &&
		wordIndex === 2 &&
		letterIndex === 2
	) {
		return true
	} else if (
		tutorialStep === TUTORIAL_STEPS.REMOVE_P &&
		wordIndex === 1 &&
		letterIndex === 1
	) {
		return true
	} else if (
		tutorialStep === TUTORIAL_STEPS.PLACE_Y &&
		wordIndex === 3 &&
		letterIndex === 3
	) {
		return true
	}
	return false
}

const getCanLongPress = (
	tutorialStep: number,
	wordIndex: number,
	letterIndex: number,
) =>
	tutorialStep >= TUTORIAL_STEPS.REMOVE_P &&
	(pressThisButton(tutorialStep, wordIndex, letterIndex) ||
		tutorialStep >= TUTORIAL_STEPS.GAME_MODE)

const makeWordButton = (
	tutorialStep,
	wordIndex,
	letterIndex,
	nextStep,
	setHidePArrow,
	hidePArrow,
) => (
	<WordButton
		wordIndex={wordIndex}
		letterIndex={letterIndex}
		canLongPressOverride={getCanLongPress(tutorialStep, wordIndex, letterIndex)}
		onLongPress={() => {
			if (tutorialStep === TUTORIAL_STEPS.REMOVE_P) {
				nextStep()
			}
		}}
		onPressIn={() => {
			if (tutorialStep === TUTORIAL_STEPS.REMOVE_P) {
				setHidePArrow(true)
			}
		}}
		canPress={
			pressThisButton(tutorialStep, wordIndex, letterIndex) ||
			tutorialStep >= TUTORIAL_STEPS.SET_GAME_MODE
		}
		onPress={() => {
			if (tutorialStep !== TUTORIAL_STEPS.REMOVE_P) {
				nextStep()
			}
		}}
	>
		{pressThisButton(tutorialStep, wordIndex, letterIndex) &&
			(TUTORIAL_STEPS.REMOVE_P !== tutorialStep || !hidePArrow) && (
				<TutorialArrow top={15} left={15} />
			)}
	</WordButton>
)
