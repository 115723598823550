/** @format */

import Constants from "expo-constants"
import { StatusBar as _StatusBar } from "expo-status-bar"
import React from "react"
import { View } from "react-native"

import { colors } from "../../../styles/styles"

const StatusBar = props => (
	<View
		style={{
			backgroundColor: colors["grey-800"],
			height: Constants.statusBarHeight,
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
		}}
	>
		<_StatusBar
			translucent
			backgroundColor={colors["grey-800"]}
			{...props}
			style="light"
		/>
	</View>
)

export default StatusBar
