/** @format */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

import React, { Component } from "react"
import { Dimensions, StyleSheet, View, Animated, Easing } from "react-native"

export default class Fireworks extends Component<
	{
		colors?: string[]
		height?: number
		width?: number
		zIndex?: number
		iterations?: number
		circular?: boolean
		density?: number
		speed?: number
		playFireworks?: () => void
	},
	{ x: number[]; y: number[] }
> {
	fadingOpacity: Animated.Value
	movingBall: Animated.Value
	skyWidth: number
	skyHeight: number
	skyZindex: number
	explosionIterations: number | "infinite"
	count: number
	yPositions: number[]
	xPositions: number[]

	constructor(props) {
		super(props)
		this.state = {
			x: [],
			y: [],
		}
		this.fadingOpacity = new Animated.Value(1)
		this.movingBall = new Animated.Value(0)
		this.skyHeight = 0
		this.skyWidth = 0
		this.skyZindex = 10
		this.explosionIterations = "infinite"
		this.count = 0
		this.yPositions = []
		this.xPositions = []
	}

	componentDidMount = () => {
		const { height, width, zIndex, iterations, circular } = this.props
		if (circular) {
			this.fillXYpositions()
		}
		if (height && typeof height === "number") {
			this.skyHeight = height
		} else {
			this.skyHeight = Dimensions.get("window").height
		}
		if (width && typeof width === "number") {
			this.skyWidth = width
		} else {
			this.skyWidth = Dimensions.get("window").width
		}
		if (zIndex && typeof zIndex === "number") {
			this.skyZindex = zIndex
		} else {
			this.skyZindex = 10
		}
		if (iterations && typeof iterations === "number") {
			this.explosionIterations = iterations
		} else {
			this.explosionIterations = "infinite"
		}
		this.setExplosionSpots(false)
	}

	fillXYpositions = () => {
		let x
		for (let i = 0; i <= 5; i++) {
			x = i * i * 8
			this.xPositions[i] = x
			this.xPositions[i + 5] = x
			this.xPositions[i + 10] = 200 - x
			this.xPositions[i + 15] = 200 - x
			this.yPositions[i] = 100 - Math.sqrt(10000 - (x - 100) * (x - 100))
			this.yPositions[i + 5] = 100 + Math.sqrt(10000 - (x - 100) * (x - 100))
			this.yPositions[i + 10] = 100 - Math.sqrt(10000 - (x - 100) * (x - 100))
			this.yPositions[i + 15] = 100 + Math.sqrt(10000 - (x - 100) * (x - 100))
		}
	}

	setExplosionSpots = shouldUpdateCounts => {
		let { density } = this.props
		if (density && typeof density === "number" && density > 0) {
			if (density > 10) {
				density = 10
			}
		} else {
			density = 5
		}
		const x = []
		const y = []
		let i
		for (i = 0; i < density; i++) {
			x[i] = this.getRandom(this.skyWidth)
			y[i] = this.getRandom(this.skyHeight)
		}
		this.setState({ x, y }, () => {
			if (
				this.explosionIterations &&
				typeof this.explosionIterations === "number"
			) {
				if (shouldUpdateCounts) {
					this.count++
				}
				if (this.count < this.explosionIterations) {
					this.props.playFireworks()
					this.animateOpacity()
					this.animateBall()
				}
			} else {
				this.animateOpacity()
				this.animateBall()
			}
		})
	}

	animateOpacity() {
		const { speed } = this.props
		this.fadingOpacity.setValue(1)
		Animated.timing(this.fadingOpacity, {
			toValue: 0,
			duration: speed === 1 ? 900 : speed === 3 ? 500 : 700,
			easing: Easing.ease,
			useNativeDriver: false,
		}).start(() => this.setExplosionSpots(true))
	}

	animateBall() {
		const { speed } = this.props
		this.movingBall.setValue(0)
		Animated.timing(this.movingBall, {
			toValue: 1,
			duration: speed === 1 ? 900 : speed === 3 ? 500 : 700,
			easing: Easing.ease,
			useNativeDriver: false,
		}).start()
	}

	getRandom = n => {
		return Math.round(Math.random() * n)
	}

	getRandomColors = () => {
		const { colors } = this.props
		if (colors && colors.length > 0) {
			const l = colors.length - 1
			const n = Math.round(Math.random() * l)
			return colors[n]
		} else {
			return (
				"rgb(" +
				this.getRandom(255) +
				"," +
				this.getRandom(255) +
				"," +
				this.getRandom(255) +
				")"
			)
		}
	}

	explosionBox = color => {
		const { circular, iterations } = this.props
		const ballSize = this.movingBall.interpolate({
			inputRange: [0, 1],
			outputRange: [0, 12],
		})
		const ballRadius = this.movingBall.interpolate({
			inputRange: [0, 1],
			outputRange: [0, 6],
		})
		const balls = []
		const randomTops = []
		const randomLefts = []
		let x, y

		const loopCount = circular ? 20 : 30
		for (let i = 0; i <= loopCount; i++) {
			balls.push("")
			if (circular) {
				x = this.xPositions[i]
				y = this.yPositions[i]
				randomTops[i] = this.movingBall.interpolate({
					inputRange: [0, 1],
					outputRange: [100, y],
				})
				randomLefts[i] = this.movingBall.interpolate({
					inputRange: [0, 1],
					outputRange: [100, x],
				})
			} else {
				randomTops[i] = this.movingBall.interpolate({
					inputRange: [0, 1],
					outputRange: [100, this.getRandom(200)],
				})
				randomLefts[i] = this.movingBall.interpolate({
					inputRange: [0, 1],
					outputRange: [100, this.getRandom(200)],
				})
			}
		}
		const ballOpacity = this.fadingOpacity.interpolate({
			inputRange: [0, 1],
			outputRange: [0, 1],
		})
		if (!iterations || this.count < iterations - 1) {
			return (
				<View style={styles.explosionBoundary}>
					{balls.map((ball, index) => {
						return (
							<Animated.View
								key={index}
								style={[
									styles.ball,
									{
										height: circular ? ballSize : 7,
										width: circular ? ballSize : 7,
										borderRadius: circular ? ballRadius : 3,
										top: randomTops[index],
										left: randomLefts[index],
										opacity: circular ? 1 : ballOpacity,
										backgroundColor: this.getRandomColors(),
									},
								]}
							/>
						)
					})}
				</View>
			)
		}
		return null
	}
	render() {
		const { x, y } = this.state
		const { iterations } = this.props
		if (!iterations || this.count < iterations) {
			return (
				<View style={styles.container}>
					{x.map((xItem, index) => {
						return (
							<View
								key={index}
								style={{
									top: y[index],
									left: x[index],
								}}
							>
								{this.explosionBox(this.getRandomColors())}
							</View>
						)
					})}
				</View>
			)
		}
		return null
	}
}

const styles = StyleSheet.create({
	container: {
		position: "absolute",
		top: -100,
		left: -100,
		zIndex: 10,
		width: Dimensions.get("window").width,
		height: Dimensions.get("window").height,
	},
	explosionBoundary: {
		position: "absolute",
		height: 200,
		width: 200,
		zIndex: 10,
	},
	ball: {
		position: "absolute",
	},
})
