/** @format */
import { AnimatePresence } from "moti"
import { MotiPressable } from "moti/interactions"
import { useContext } from "react"
import { ViewStyle, Text } from "react-native"

import SoundContext from "../../../../utils/SoundContext"
import { styles } from "../../../styles"
import { colors } from "../../../styles/styles"

const TutorialTip: React.FC<{
	style?: ViewStyle
	text: string
	showContinueText?: boolean
	nextStep?: () => void
	tutorialStep: number
	step: number
}> = ({ style, showContinueText, text, nextStep, tutorialStep, step }) => {
	const SoundManager = useContext(SoundContext)
	return (
		<AnimatePresence>
			{step === tutorialStep && (
				<MotiPressable
					onPress={() => {
						if (showContinueText && nextStep) {
							SoundManager.playPush()
							nextStep()
						}
					}}
					containerStyle={{
						...(style || {}),
						position: "absolute",
						...styles.centerBox,
						minHeight: 60,
						alignItems: "center",
						width: "100%",
						zIndex: 25,
					}}
					style={[
						{
							backgroundColor: colors["grey-050"],
							borderRadius: 5,
							padding: 15,
							shadowColor: "#000",
							shadowOffset: {
								width: 0,
								height: 2,
							},
							width: "100%",
							maxWidth: 375,
							shadowOpacity: 0.23,
							shadowRadius: 2.62,
							elevation: 4,
						},
					]}
					from={{ opacity: 0, translateY: -30 }}
					animate={{ opacity: 1, translateY: 0 }}
					exit={{ opacity: 0, translateY: 30 }}
				>
					<Text
						allowFontScaling={false}
						style={[
							styles.mediumText,
							styles.darkText,
							{ fontWeight: "normal", textAlign: "center" },
						]}
					>
						{text}
					</Text>
					{showContinueText && (
						<Text
							allowFontScaling={false}
							style={[
								styles.smallText,
								styles.darkGreyText,
								{
									marginTop: 10,
									textAlign: "center",
								},
							]}
						>
							(Press to continue)
						</Text>
					)}
				</MotiPressable>
			)}
		</AnimatePresence>
	)
}

export default TutorialTip
