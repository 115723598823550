/** @format */
import { Asset } from "expo-asset"
import { createContext, useState } from "react"
import useSound from "use-sound"

import { SoundContextInterface } from "../src/context/types"

export const SoundContext = createContext<SoundContextInterface>({
	muted: false,
	setMuted: (b: boolean) => null,
	playInvalid: () => null,
	playSuccess: () => null,
	playFirework: () => null,
	playGameOver: () => null,
	playPush: () => null,
	playVictory: () => null,
})

export const SoundProvider: React.FC<{
	children: React.ReactNode[]
	assets: Asset[]
}> = ({ children, assets }) => {
	const [muted, setMuted] = useState(false)

	// eslint-disable-next-line @typescript-eslint/no-var-requires
	const [playInvalid] = useSound(assets[0]?.localUri, {
		volume: 0.4,
		soundEnabled: !muted,
	})

	// eslint-disable-next-line @typescript-eslint/no-var-requires
	const [playSuccess] = useSound(assets[1]?.localUri, {
		volume: 0.4,
		soundEnabled: !muted,
	})

	// eslint-disable-next-line @typescript-eslint/no-var-requires
	const [playFirework] = useSound(assets[2]?.localUri, {
		volume: 0.3,
		soundEnabled: !muted,
	})

	// eslint-disable-next-line @typescript-eslint/no-var-requires
	const [playPush] = useSound(assets[3]?.localUri, {
		volume: 0.3,
		soundEnabled: !muted,
	})

	// eslint-disable-next-line @typescript-eslint/no-var-requires
	const [playGameOver] = useSound(assets[4]?.localUri, {
		volume: 0.5,
		soundEnabled: !muted,
		interrupt: true,
	})
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	const [playVictory] = useSound(assets[5]?.localUri, {
		volume: 0.5,
		soundEnabled: !muted,
		interrupt: true,
	})

	return (
		<SoundContext.Provider
			value={{
				muted,
				setMuted,
				playPush,
				playInvalid,
				playSuccess,
				playVictory,
				playGameOver,
				playFirework,
			}}
		>
			{children}
		</SoundContext.Provider>
	)
}

export default SoundContext
