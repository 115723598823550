/** @format */

import { SequenceItem } from "moti"

export const TEST_IMPORT: SequenceItem<string>[] = [
	{ value: "0deg", duration: 50, delay: 0, type: "timing" },
	{ value: "-6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "-6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "6deg", duration: 50, delay: 0, type: "timing" },
]

export const SHAKEY_ANIMATION: SequenceItem<string>[] = [
	{ value: "0deg", duration: 0, delay: 0, type: "timing" },
	{ value: "-6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "-6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "-6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "-6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "-6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "-6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "-6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "-6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "-6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "-6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "-6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "-6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "-6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "-6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "-6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "-6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "-6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "-6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "-6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "-6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "-6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "-6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "-6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "-6deg", duration: 50, delay: 0, type: "timing" },
	{ value: "6deg", duration: 50, delay: 0, type: "timing" },
]
