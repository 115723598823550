/** @format */

import { View, Text } from "react-native"

import StatusBar from "./StatusBar"
import { styles } from "../../../styles"

export const EmptyHeader = () => (
	<View style={{ width: "100%", height: "100%" }}>
		<View style={styles.headerContainer}>
			<StatusBar />
			<View style={styles.headerView}>
				<Text allowFontScaling={false} style={styles.logoText}>
					WordTree
				</Text>
				<View style={styles.buttonContainer} />
			</View>
		</View>
	</View>
)
