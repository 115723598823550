/** @format */
import React from "react"
import { ScrollView } from "react-native"

import { useGameStore } from "../context/GameManager/GameManager"
import { GameMode } from "../context/types"
import { Footer } from "../features/footer"
import { GameOverModal } from "../features/gameOverModal"
import { NewVersionModal } from "../features/newVersionModal"
import TutorialContainer from "../features/tutorial/components/TutorialContainer"
import { WordTree } from "../features/wordTree"
import { styles } from "../styles"

const Home: React.FC<{
	onLayoutRootView: () => Promise<void>
	backgroundColor?: string
}> = ({ onLayoutRootView, backgroundColor }) => {
	const isTutorial = useGameStore(
		state => state.gameState.gameMode === GameMode.TUTORIAL,
	)

	return (
		<ScrollView
			style={{
				...styles.scrollContainer,
				position: "relative",
				zIndex: -1,

				overflow: "visible",
			}}
			contentContainerStyle={{
				...styles.container,
				backgroundColor,
			}}
			onLayout={onLayoutRootView}
		>
			<GameOverModal />
			<NewVersionModal />
			{isTutorial ? (
				<TutorialContainer />
			) : (
				<>
					<WordTree />
					<Footer />
				</>
			)}
		</ScrollView>
	)
}

export default Home
