/** @format */

import { MotiView } from "moti"
import React from "react"

export const ShakeView: React.FC<{
	children: React.ReactElement
	shakeAmount?: number
}> = React.memo(({ children, shakeAmount = 3, ...rest }) => (
	<MotiView
		{...rest}
		from={{ rotate: "0deg" }}
		animate={{
			rotate: [
				{
					delay: 3200,
					value: "0deg",
					duration: 50,
					type: "timing",
				},
				{ value: `${shakeAmount}deg`, duration: 50, type: "timing" },
				{ value: `-${shakeAmount}deg`, duration: 50, type: "timing" },
				{ value: `${shakeAmount}deg`, duration: 50, type: "timing" },
				{ value: `-${shakeAmount}deg`, duration: 50, type: "timing" },
				{ value: "0deg", duration: 50, type: "timing" },
			],
		}}
		transition={{
			loop: true,
			type: "timing",
		}}
	>
		{children}
	</MotiView>
))
