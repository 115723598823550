/** @format */

import { useEffect, useCallback, useState } from "react"

import TutorialFooter from "./TutorialFooter"
import TutorialTree from "./TutorialTree"
import { useGameStore } from "../../../context/GameManager/GameManager"
import { GameMode } from "../../../context/types"

export const TutorialContainer = () => {
	const [tutorialStep, setTutorialStep] = useState(0)
	const nextStep = useCallback(
		() => setTutorialStep(tutorialStep + 1),
		[tutorialStep],
	)
	const setGameMode = useGameStore(state => state.setGameMode)

	useEffect(() => {
		setGameMode(GameMode.TUTORIAL)
	}, [setGameMode])

	return (
		<>
			<TutorialTree tutorialStep={tutorialStep} nextStep={nextStep} />
			<TutorialFooter tutorialStep={tutorialStep} nextStep={nextStep} />
		</>
	)
}

export default TutorialContainer
