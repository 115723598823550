/** @format */
import React from "react"
import { View, StyleSheet } from "react-native"
import Svg, { Path, Rect, Circle } from "react-native-svg"

import { colors } from "../styles/styles"

export const SettingsIcon = () => (
	<View
		style={[
			StyleSheet.absoluteFill,
			{ alignItems: "center", justifyContent: "center" },
		]}
	>
		<Svg
			fill="none"
			viewBox="0 0 24 24"
			strokeWidth={1.5}
			stroke={colors["grey-050"]}
		>
			<Path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
			/>
			<Path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
			/>
		</Svg>
	</View>
)

export const StatsIcon = () => (
	<View
		style={[
			StyleSheet.absoluteFill,
			{ alignItems: "center", justifyContent: "center" },
		]}
	>
		<Svg
			fill="none"
			viewBox="0 0 24 24"
			strokeWidth={1.5}
			stroke={colors["grey-050"]}
		>
			<Path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
			/>
		</Svg>
	</View>
)

export const HelpIcon = () => (
	<View
		style={[
			StyleSheet.absoluteFill,
			{ alignItems: "center", justifyContent: "center" },
		]}
	>
		<Svg
			fill="none"
			viewBox="0 0 24 24"
			strokeWidth={1.5}
			stroke={colors["grey-050"]}
		>
			<Path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
			/>
		</Svg>
	</View>
)

export const ExampleIcon = () => (
	<View
		style={[
			StyleSheet.absoluteFill,
			{ alignItems: "center", justifyContent: "center" },
		]}
	>
		<Svg
			fill="none"
			viewBox="0 0 24 24"
			strokeWidth={1.5}
			stroke={colors["grey-050"]}
		>
			<Circle
				cx="50"
				cy="50"
				r="45"
				stroke="blue"
				strokeWidth="2.5"
				fill="green"
			/>
			<Rect
				x="15"
				y="15"
				width="70"
				height="70"
				stroke="red"
				strokeWidth="2"
				fill="yellow"
			/>
		</Svg>
	</View>
)

export const SkipIcon = () => (
	<View
		style={[
			StyleSheet.absoluteFill,
			{
				alignItems: "center",
				justifyContent: "center",
			},
		]}
	>
		<Svg
			fill="none"
			viewBox="0 0 24 24"
			strokeWidth={1.5}
			stroke={colors["grey-050"]}
		>
			<Path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M3 8.688c0-.864.933-1.405 1.683-.977l7.108 4.062a1.125 1.125 0 010 1.953l-7.108 4.062A1.125 1.125 0 013 16.81V8.688zM12.75 8.688c0-.864.933-1.405 1.683-.977l7.108 4.062a1.125 1.125 0 010 1.953l-7.108 4.062a1.125 1.125 0 01-1.683-.977V8.688z"
			/>
		</Svg>
	</View>
)

export const ArrowIcon = () => (
	<View
		style={[
			StyleSheet.absoluteFill,
			{
				alignItems: "flex-end",
				justifyContent: "flex-end",
			},
		]}
	>
		<Svg
			fill="none"
			viewBox="0 0 24 24"
			strokeWidth={1.5}
			stroke={colors["grey-900"]}
		>
			<Path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3"
			/>
		</Svg>
	</View>
)

export const ShareIcon = ({ color }) => (
	<View style={[{ alignItems: "center", justifyContent: "center" }]}>
		<Svg
			fill="none"
			viewBox="0 0 24 24"
			strokeWidth={1.5}
			stroke={color || colors["cyan-700"]}
		>
			<Path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z"
			/>
		</Svg>
	</View>
)

export const ChallengeIcon = () => (
	<View
		style={[
			StyleSheet.absoluteFill,
			{ alignItems: "center", justifyContent: "center" },
		]}
	>
		<Svg
			fill="none"
			viewBox="0 0 24 24"
			strokeWidth={1.5}
			stroke={colors["grey-050"]}
		>
			<Path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0"
			/>
		</Svg>
	</View>
)

export const LeftRightIcon = () => (
	<View
		style={[
			StyleSheet.absoluteFill,
			{ alignItems: "center", justifyContent: "center" },
		]}
	>
		<Svg
			fill="none"
			viewBox="0 0 24 24"
			strokeWidth={1.5}
			stroke={colors["grey-050"]}
		>
			<Path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
			/>
		</Svg>
	</View>
)

export const ClockIcon = () => (
	<View style={[{ alignItems: "center", justifyContent: "center" }]}>
		<Svg
			fill="none"
			viewBox="0 0 24 24"
			strokeWidth={1.5}
			stroke={colors["grey-050"]}
		>
			<Path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
			/>
		</Svg>
	</View>
)

export const CalendarIcon = () => (
	<View style={[{ alignItems: "center", justifyContent: "center" }]}>
		<Svg
			fill="none"
			viewBox="0 0 24 24"
			strokeWidth={1.5}
			stroke={colors["grey-050"]}
		>
			<Path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
			/>
		</Svg>
	</View>
)

export const SparkleIcon = () => (
	<View style={[{ alignItems: "center", justifyContent: "center" }]}>
		<Svg
			fill="none"
			viewBox="0 0 24 24"
			strokeWidth={1.5}
			stroke={colors["grey-050"]}
		>
			<Path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
			/>
		</Svg>
	</View>
)

export const BookIcon = () => (
	<View style={[{ alignItems: "center", justifyContent: "center" }]}>
		<Svg
			fill="none"
			viewBox="0 0 24 24"
			strokeWidth={1.5}
			stroke={colors["grey-050"]}
		>
			<Path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25"
			/>
		</Svg>
	</View>
)

export const DownIcon = () => (
	<View style={[{ alignItems: "center", justifyContent: "center" }]}>
		<Svg
			fill="none"
			viewBox="0 0 24 24"
			strokeWidth={1.5}
			stroke={colors["grey-050"]}
		>
			<Path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M19.5 8.25l-7.5 7.5-7.5-7.5"
			/>
		</Svg>
	</View>
)

export const CursorArrow = ({ dark }) => (
	<View style={[{ alignItems: "center", justifyContent: "center" }]}>
		<Svg
			fill={dark ? colors["grey-900"] : colors["grey-050"]}
			viewBox="0 0 203.079 203.079"
		>
			<Path d="M192.231,104.082V102c0-12.407-10.094-22.5-22.5-22.5c-2.802,0-5.484,0.519-7.961,1.459 C159.665,70.722,150.583,63,139.731,63c-2.947,0-5.76,0.575-8.341,1.61C128.667,55.162,119.624,48,109.231,48 c-2.798,0-5.496,0.541-8,1.516V22.5c0-12.407-10.094-22.5-22.5-22.5s-22.5,10.093-22.5,22.5v66.259 c-3.938-5.029-8.673-9.412-14.169-11.671c-6.133-2.52-12.587-2.219-18.667,0.872c-11.182,5.686-15.792,19.389-10.277,30.548 l27.95,56.563c0.79,1.552,19.731,38.008,54.023,38.008h40c31.54,0,57.199-25.794,57.199-57.506l-0.031-41.491H192.231z M135.092,188.079h-40c-24.702,0-40.091-28.738-40.646-29.796l-27.88-56.42c-1.924-3.893-0.33-8.519,3.629-10.532 c2.182-1.11,4.081-1.223,6.158-0.372c8.281,3.395,16.41,19.756,19.586,29.265l2.41,7.259l12.883-4.559V22.5 c0-4.136,3.364-7.5,7.5-7.5s7.5,3.364,7.5,7.5V109h0.136h14.864h0.136V71c0-4.187,3.748-8,7.864-8c4.262,0,8,3.505,8,7.5v15v26h15 v-26c0-4.136,3.364-7.5,7.5-7.5s7.5,3.364,7.5,7.5V102v16.5h15V102c0-4.136,3.364-7.5,7.5-7.5s7.5,3.364,7.5,7.5v10.727h0.035 l0.025,32.852C177.291,169.014,158.36,188.079,135.092,188.079z"></Path>
		</Svg>
		{/* <Svg
			fill="none"
			viewBox="0 0 24 24"
			strokeWidth={1.5}
			stroke={colors["grey-900"]}
		>
			<Path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zM12 2.25V4.5m5.834.166l-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243l-1.59-1.59"
			/>
		</Svg> */}
	</View>
)

export const SpeakerWave = () => (
	<View style={[{ alignItems: "center", justifyContent: "center" }]}>
		<Svg
			fill="none"
			viewBox="0 0 24 24"
			strokeWidth={1.5}
			stroke={colors["grey-050"]}
		>
			<Path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z"
			/>
		</Svg>
	</View>
)
export const SpeakerMute = () => (
	<View style={[{ alignItems: "center", justifyContent: "center" }]}>
		<Svg
			fill="none"
			viewBox="0 0 24 24"
			strokeWidth={1.5}
			stroke={colors["grey-050"]}
		>
			<Path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M17.25 9.75L19.5 12m0 0l2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6l4.72-4.72a.75.75 0 011.28.531V19.94a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.506-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.395C2.806 8.757 3.63 8.25 4.51 8.25H6.75z"
			/>
		</Svg>
	</View>
)

export const CheckIcon = () => (
	<View style={[{ alignItems: "center", justifyContent: "center" }]}>
		<Svg
			fill="none"
			viewBox="0 0 24 24"
			strokeWidth={1.5}
			stroke={colors["grey-050"]}
		>
			<Path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M4.5 12.75l6 6 9-13.5"
			/>
		</Svg>
	</View>
)

export const CloseIcon = () => (
	<View style={[{ alignItems: "center", justifyContent: "center" }]}>
		<Svg
			fill="none"
			viewBox="0 0 24 24"
			strokeWidth={1.5}
			stroke={colors["grey-050"]}
		>
			<Path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M6 18L18 6M6 6l12 12"
			/>
		</Svg>
	</View>
)

export const PuzzleIcon = () => (
	<View style={[{ alignItems: "center", justifyContent: "center" }]}>
		<Svg
			fill="none"
			viewBox="0 0 24 24"
			strokeWidth={1.5}
			stroke={colors["grey-050"]}
		>
			<Path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M14.25 6.087c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.036-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959v0a.64.64 0 01-.657.643 48.39 48.39 0 01-4.163-.3c.186 1.613.293 3.25.315 4.907a.656.656 0 01-.658.663v0c-.355 0-.676-.186-.959-.401a1.647 1.647 0 00-1.003-.349c-1.036 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401v0c.31 0 .555.26.532.57a48.039 48.039 0 01-.642 5.056c1.518.19 3.058.309 4.616.354a.64.64 0 00.657-.643v0c0-.355-.186-.676-.401-.959a1.647 1.647 0 01-.349-1.003c0-1.035 1.008-1.875 2.25-1.875 1.243 0 2.25.84 2.25 1.875 0 .369-.128.713-.349 1.003-.215.283-.4.604-.4.959v0c0 .333.277.599.61.58a48.1 48.1 0 005.427-.63 48.05 48.05 0 00.582-4.717.532.532 0 00-.533-.57v0c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.035 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.37 0 .713.128 1.003.349.283.215.604.401.96.401v0a.656.656 0 00.658-.663 48.422 48.422 0 00-.37-5.36c-1.886.342-3.81.574-5.766.689a.578.578 0 01-.61-.58v0z"
			/>
		</Svg>
	</View>
)
