/** @format */

import { MotiText } from "moti"
import { MotiPressable } from "moti/interactions"
import React, { useMemo } from "react"
import { View, Text, Platform } from "react-native"

import { useGameStore } from "../../../context/GameManager/GameManager"
import { styles } from "../../../styles"

export const NewVersionModal = () => {
	const newVersionAvailable = useGameStore(state => state.newVersionAvailable)

	const animate = useMemo(
		() =>
			({ hovered, pressed }) => {
				"worklet"

				return {
					translateY: pressed ? 4 : hovered ? -2 : 0,
					opacity: 1,
				}
			},
		[],
	)

	if (!newVersionAvailable || Platform.OS !== "web") {
		return null
	}

	return (
		<View style={styles.fullCenteredView}>
			<MotiPressable
				animate={animate}
				from={{ opacity: 0, translateY: -100 }}
				onPress={() => window.location.reload()}
			>
				<View style={styles.overlay}>
					<MotiText
						allowFontScaling={false}
						style={styles.headerText}
						adjustsFontSizeToFit={true}
						numberOfLines={1}
					>
						New Version
					</MotiText>
					<Text allowFontScaling={false} style={{ ...styles.centerBox }}>
						Press here to update
					</Text>
				</View>
			</MotiPressable>
		</View>
	)
}
