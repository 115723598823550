/** @format */

import { AnimatePresence, MotiView } from "moti"
import React, { useContext, useState } from "react"
import { Modal, View, Text, ScrollView } from "react-native"
import { shallow } from "zustand/shallow"

import LastTenChart from "./LastTenChart"
import SoundContext from "../../../../utils/SoundContext"
import Button from "../../../components/Button"
import Credits from "../../../components/Credits"
import { ArrowIcon, CloseIcon } from "../../../components/Icon"
import { useGameStore } from "../../../context/GameManager/GameManager"
import { GAME_MODE_CONFIG, GameMode } from "../../../context/types"
import { styles } from "../../../styles"
import { colors } from "../../../styles/styles"

const MODE_TO_LABEL = {
	[GameMode.CASUAL]: "Free Challenge",
	[GameMode.CHALLENGE]: "Daily Challenge",
	[GameMode.ENDLESS]: "Endless Challenge",
	[GameMode.STUMP]: "Daily Stump Challenge",
}

const StatModalInner = ({ showStats, setShowStats, SoundManager }) => {
	const gameMode = useGameStore(({ gameState }) => gameState.gameMode)
	const {
		lastTenMatches,
		topScore,
		won,
		total,
		currentStreak,
		maxStreak,
		beatUs,
		totalScore,
		totalWords,
	} = useGameStore(
		({ gameState }) => gameState[gameState.gameMode].stats,
		shallow,
	)
	const average = useGameStore(
		({ gameState }) => gameState?.[gameState.gameMode]?.stats?.average?.[0],
	)

	const [showArrow, setShowArrow] = useState(true)
	const handleLayout = e => {
		const { height } = e.nativeEvent.layout
		const { scrollHeight } = e.nativeEvent.target
		const paddingToBottom = 20
		const bottom = height >= scrollHeight - paddingToBottom
		if (bottom) {
			setShowArrow(false)
		} else {
			setShowArrow(true)
		}
	}
	const handleScroll = e => {
		const { contentOffset, contentSize, layoutMeasurement } = e.nativeEvent
		const paddingToBottom = 20
		const bottom =
			layoutMeasurement.height + contentOffset.y >=
			contentSize.height - paddingToBottom
		if (bottom) {
			setShowArrow(false)
		} else {
			setShowArrow(true)
		}
	}

	const { disableStats, challengeLabel } = GAME_MODE_CONFIG[gameMode]

	return (
		<View
			style={{ ...styles.centeredView, backgroundColor: "rgba(0,0,0,0.3)" }}
		>
			<View
				style={{ ...styles.modalView, backgroundColor: colors["grey-100"] }}
			>
				<AnimatePresence>
					{showArrow && (
						<MotiView
							style={styles.modalScroll}
							from={{ opacity: 0 }}
							animate={{
								opacity: 1,
								translateY: [0, -5],
							}}
							transition={{
								translateY: {
									loop: true,
									duration: 150,
									delay: 0,
								},
							}}
							exit={{
								opacity: 0,
							}}
						>
							<ArrowIcon />
						</MotiView>
					)}
				</AnimatePresence>
				<View style={styles.topRightButtonView}>
					<View style={styles.topRightButton}>
						<Text
							allowFontScaling={false}
							style={{
								...styles.mediumText,
								...styles.boldText,
							}}
						>
							STATS - {MODE_TO_LABEL[gameMode]?.toUpperCase()}
						</Text>
					</View>
					<Button
						style={styles.topRightButton}
						labelStyle={{
							...styles.mediumText,
							...styles.boldText,
							...styles.darkText,
						}}
						onPressIn={() => SoundManager.playPush()}
						onPress={() => {
							setShowStats(!showStats)
						}}
					>
						<View style={{ width: 24, height: 24 }}>
							<CloseIcon />
						</View>
					</Button>
				</View>
				<ScrollView
					onScroll={handleScroll}
					onLayout={handleLayout}
					scrollEventThrottle={400}
					style={{ width: "100%" }}
				>
					<View style={{ marginTop: 15 }}>
						{!disableStats?.lastTenMatches && (
							<LastTenChart
								lastTenMatches={lastTenMatches}
								label={`LAST 10 ${MODE_TO_LABEL[gameMode]?.toUpperCase()}S:`}
							/>
						)}

						<View
							style={{
								...styles.instructionTextSection,
								flexDirection: "column",
								display: "flex",
								width: "100%",
								paddingHorizontal: 15,
							}}
						>
							{!disableStats?.currentStreak && (
								<StatView
									label="CURRENT STREAK"
									value={currentStreak}
									subLabel="Your current number of solved challenges in a row."
								/>
							)}
							{!disableStats?.maxStreak && (
								<StatView
									label="BEST STREAK"
									value={maxStreak || 0}
									subLabel="Your highest number of solved challenges in a row."
								/>
							)}
							{!disableStats?.beatUs && (
								<StatView
									label="BEAT THE APP"
									value={beatUs || 0}
									subLabel="The number of times your solution was worth more points than our solution."
								/>
							)}
							{!disableStats?.topScore && (
								<StatView
									label="TOP SCORE"
									value={topScore || 0}
									subLabel="Your highest score."
								/>
							)}
							{!disableStats?.average && (
								<StatView
									label="AVERAGE SCORE"
									value={Math.floor(average || 0)}
									subLabel={`Your average score in ${
										challengeLabel ? challengeLabel : "winning games"
									}.`}
								/>
							)}
							<StatView
								label="TOTAL SCORE"
								value={totalScore || 0}
								subLabel="The combined score of all the words you have found in this game mode."
							/>
							<StatView
								label="TOTAL WORDS"
								value={totalWords || 0}
								subLabel="Your combined number of words you have found in this game mode."
							/>
							<StatView
								label="TOP SCORE"
								value={topScore || 0}
								subLabel="Your highest score."
							/>
							{!disableStats?.won && (
								<StatView
									label="TOTAL WINS"
									value={won || 0}
									subLabel="Your total number of victories."
								/>
							)}
							<StatView
								label="TOTAL GAMES"
								value={total || 0}
								subLabel="Your total number of games played."
							/>
							{!disableStats?.winRate && (
								<StatView
									label="WIN RATE"
									value={`${
										total > 0 && won > 0 ? ((won / total) * 100).toFixed(1) : 0
									}%`}
									subLabel="How often your games end in victory."
								/>
							)}
							<Credits />
						</View>
					</View>
				</ScrollView>
			</View>
		</View>
	)
}

export const StatModal = () => {
	const showStats = useGameStore(state => state.showStats)
	const setShowStats = useGameStore(state => state.setShowStats)
	const SoundManager = useContext(SoundContext)

	return (
		<Modal
			animationType="fade"
			transparent={true}
			visible={showStats}
			onRequestClose={async () => {
				SoundManager.playPush()
				setShowStats(!showStats)
			}}
		>
			<StatModalInner
				showStats={showStats}
				setShowStats={setShowStats}
				SoundManager={SoundManager}
			/>
		</Modal>
	)
}

const StatView = ({ label, value, subLabel }) => (
	<View>
		<View
			style={{
				borderColor: colors["grey-900"],
				borderWidth: 1,
				padding: 15,
				marginBottom: 15,
				borderRadius: 5,
				backgroundColor: colors["grey-050"],
				zIndex: 1,
				height: 115,
			}}
		>
			<View
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignContent: "center",
					flexDirection: "row",
					borderColor: colors["grey-400"],
					borderBottomWidth: 1,
					marginBottom: 10,
				}}
			>
				<View
					style={{
						display: "flex",
						justifyContent: "center",
					}}
				>
					<Text
						allowFontScaling={false}
						style={{
							...styles.mediumText,
							...styles.boldText,
							...styles.darkText,
						}}
					>
						{label}
					</Text>
				</View>
				<View
					style={{
						display: "flex",
						justifyContent: "flex-end",
						alignItems: "flex-end",
						minWidth: 100,
					}}
				>
					<Text
						allowFontScaling={false}
						style={{
							...styles.valueText,
							...styles.darkText,
						}}
					>
						{nFormatter(value, 1000)}
					</Text>
				</View>
			</View>
			<View
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexGrow: 1,
				}}
			>
				<Text
					allowFontScaling={false}
					style={{
						...styles.smallText,
						...styles.darkText,
						fontWeight: "normal",
					}}
				>
					{subLabel}
				</Text>
			</View>
		</View>
		<View
			style={{
				position: "absolute",
				top: 4,
				height: 115,
				width: "100%",
				zIndex: 0,
				borderColor: "transparent",
				borderRadius: 5,
				backgroundColor: colors["grey-900"],
			}}
		/>
	</View>
)

export default StatModal

const nFormatter = (_num: number, _minValue: number): string => {
	if (!_num || !+_num || typeof +_num !== "number") {
		return `${_num}`
	}

	const num = +_num

	const minValue = _minValue || 0
	const si = [
		{ value: 1e18, symbol: "E" },
		{ value: 1e15, symbol: "P" },
		{ value: 1e12, symbol: "T" },
		{ value: 1e9, symbol: "G" },
		{ value: 1e6, symbol: "M" },
		{ value: 1e3, symbol: "k" },
	]

	if (typeof num === "number" && num >= minValue) {
		for (let i = 0; i < si.length; i++) {
			if (num >= si[i].value) {
				return `${(num / si[i].value).toFixed(1)}${si[i].symbol}`
			}
		}
	}

	return `${num}`
}
