/** @format */

import { AnimatePresence, MotiView } from "moti"
import React, { useContext, useState } from "react"
import { Modal, ScrollView, Text, View } from "react-native"

import SoundContext from "../../../../utils/SoundContext"
import Button from "../../../components/Button"
import Credits from "../../../components/Credits"
import {
	ClockIcon,
	CalendarIcon,
	SparkleIcon,
	ArrowIcon,
	CheckIcon,
	CloseIcon,
	PuzzleIcon,
} from "../../../components/Icon"
import { useGameStore } from "../../../context/GameManager/GameManager"
import { NO_SEED } from "../../../context/TimeContext/TimeContext"
import { GameMode, GameplayState } from "../../../context/types"
import { styles } from "../../../styles"
import { colors } from "../../../styles/styles"
import { FooterButton } from "../../footer/components/Footer"

export const GameModeModal = () => {
	const shouldSetGameMode = useGameStore(state => state.shouldSetGameMode)
	const setShouldSetGameMode = useGameStore(state => state.setShouldSetGameMode)
	const setGameMode = useGameStore(state => state.setGameMode)
	const seed = useGameStore(state => state.loadedSeed)
	const hasPlayedDailyChallenge = useGameStore(
		state =>
			state.gameState[GameMode.CHALLENGE]?.gameplayState ===
				GameplayState.LOST ||
			state.gameState[GameMode.CHALLENGE]?.gameplayState === GameplayState.WON,
	)

	const hasPlayedDailyStump = useGameStore(
		state =>
			state.gameState[GameMode.STUMP]?.gameplayState === GameplayState.WON,
	)

	const SoundManager = useContext(SoundContext)
	const [showArrow, setShowArrow] = useState(true)

	const handleLayout = e => {
		const { height } = e.nativeEvent.layout
		const { scrollHeight } = e.nativeEvent.target
		const paddingToBottom = 20
		const bottom = height >= scrollHeight - paddingToBottom
		if (bottom) {
			setShowArrow(false)
		} else {
			setShowArrow(true)
		}
	}
	const handleScroll = e => {
		const { contentOffset, contentSize, layoutMeasurement } = e.nativeEvent
		const paddingToBottom = 20
		const bottom =
			layoutMeasurement.height + contentOffset.y >=
			contentSize.height - paddingToBottom
		if (bottom) {
			setShowArrow(false)
		} else {
			setShowArrow(true)
		}
	}

	return (
		<Modal
			animationType="fade"
			transparent={true}
			visible={shouldSetGameMode}
			onRequestClose={async () => {
				SoundManager.playPush()
				setShouldSetGameMode(false)
			}}
		>
			<View
				style={{ ...styles.centeredView, backgroundColor: "rgba(0,0,0,0.3)" }}
			>
				<View
					style={{
						...styles.modalView,
						backgroundColor: colors["grey-100"],
					}}
				>
					<AnimatePresence>
						{showArrow && (
							<MotiView
								style={styles.modalScroll}
								from={{ opacity: 0 }}
								animate={{
									opacity: 1,
									translateY: [0, -5],
								}}
								transition={{
									translateY: {
										loop: true,
										duration: 150,
										delay: 0,
									},
								}}
								exit={{
									opacity: 0,
								}}
							>
								<ArrowIcon />
							</MotiView>
						)}
					</AnimatePresence>
					<View style={styles.topRightButtonView}>
						<View style={styles.topRightButton}>
							<Text
								allowFontScaling={false}
								style={{
									...styles.mediumText,
									...styles.boldText,
								}}
							>
								SELECT GAME MODE
							</Text>
						</View>
						<Button
							style={styles.topRightButton}
							labelStyle={{
								...styles.mediumText,
								...styles.boldText,
							}}
							onPressIn={() => SoundManager.playPush()}
							onPress={() => {
								setShouldSetGameMode(null)
							}}
						>
							<View style={{ width: 24, height: 24 }}>
								<CloseIcon />
							</View>
						</Button>
					</View>
					<ScrollView
						style={{
							width: "100%",
						}}
						onScroll={handleScroll}
						onLayout={handleLayout}
						scrollEventThrottle={400}
					>
						<View style={{ marginTop: 15 }}>
							<View
								style={{
									...styles.instructionTextSection,
									flexDirection: "column",
									display: "flex",
									width: "100%",
									paddingHorizontal: 15,
								}}
							>
								{seed === NO_SEED && (
									<View style={styles.instructionTextSection}>
										<Text
											style={[
												styles.smallText,
												styles.darkText,
												{ textAlign: "center" },
											]}
										>
											Unable to load today's challenges.
										</Text>
										<Text
											style={[
												styles.smallText,
												styles.darkText,
												{ textAlign: "center" },
											]}
										>
											Check your internet connection and try again.
										</Text>
									</View>
								)}
								<GameModeButton
									label="ENDLESS CHALLENGE"
									onPress={() => {
										setGameMode(GameMode.ENDLESS)
									}}
									icon={<ClockIcon />}
									text={
										<Text
											style={{
												...styles.smallText,
												textAlign: "left",
												fontWeight: "normal",
											}}
										>
											In an{" "}
											<Text style={{ ...styles.boldText }}>
												Endless Challenge
											</Text>
											, completed words are added to your score and then cleared
											from the tree. Every{" "}
											<Text style={{ ...styles.boldText }}>50 points</Text> you
											earn two extra{" "}
											<Text style={{ ...styles.boldText }}>skips</Text>. How
											long can you last?
										</Text>
									}
								/>
								<GameModeButton
									label="DAILY CHALLENGE"
									disabled={seed === NO_SEED}
									onPress={() => {
										setGameMode(GameMode.CHALLENGE)
									}}
									showNotification={hasPlayedDailyChallenge}
									icon={<CalendarIcon />}
									text={
										<Text
											style={{
												...styles.smallText,
												fontWeight: "normal",
												textAlign: "left",
											}}
										>
											In a{" "}
											<Text style={{ ...styles.boldText }}>
												Daily Challenge
											</Text>
											, you have three attempts to find a solution to the
											puzzle. After three attempts it is{" "}
											<Text style={{ ...styles.boldText }}>Game Over</Text>.
											Solving on your first attempt gets you a{" "}
											<Text style={{ ...styles.boldText }}>
												double points bonus
											</Text>
											. A new challenge is released every day!
										</Text>
									}
								/>
								<GameModeButton
									label="DAILY STUMP"
									disabled={seed === NO_SEED}
									onPress={() => {
										setGameMode(GameMode.STUMP)
									}}
									showNotification={hasPlayedDailyStump}
									icon={<PuzzleIcon />}
									text={
										<Text
											style={{
												...styles.smallText,
												fontWeight: "normal",
												textAlign: "left",
											}}
										>
											In the{" "}
											<Text style={{ ...styles.boldText }}>Daily Stump</Text>,
											you have{" "}
											<Text style={{ ...styles.boldText }}>unlimited</Text>{" "}
											attempts to find a solution to the puzzle. However, there
											are <Text style={{ ...styles.boldText }}>no skips</Text>{" "}
											and{" "}
											<Text style={{ ...styles.boldText }}>no hold button</Text>
											. So you will only be able to use the letters you have
											available. A new puzzle is released every day!
										</Text>
									}
								/>

								<GameModeButton
									label="FREE CHALLENGE"
									onPress={() => {
										setGameMode(GameMode.CASUAL)
									}}
									icon={<SparkleIcon />}
									text={
										<Text
											style={{
												...styles.smallText,
												textAlign: "left",
												fontWeight: "normal",
											}}
										>
											In a{" "}
											<Text style={{ ...styles.boldText }}>Free Challenge</Text>
											, you have unlimited attempts to solve a random Daily
											Challenge. In order for it to count as a victory you must
											solve the puzzle within your first{" "}
											<Text style={{ ...styles.boldText }}>three</Text>{" "}
											attempts.
										</Text>
									}
								/>
								<Credits />
							</View>
						</View>
					</ScrollView>
				</View>
			</View>
		</Modal>
	)
}

export default GameModeModal

const GameModeButton: React.FC<{
	label: string
	icon: JSX.Element
	onPress: () => void
	text: JSX.Element
	disabled?: boolean
	showNotification?: boolean
}> = ({ label, icon, text, disabled, onPress, showNotification }) => (
	<>
		<View
			removeClippedSubviews={false}
			style={{
				...styles.instructionTextSection,
			}}
		>
			<FooterButton
				disabled={disabled}
				onPress={onPress}
				style={{ height: undefined, padding: 15 }}
			>
				{showNotification !== undefined && (
					<View
						style={{
							...styles.buttonBadge,
							backgroundColor: showNotification
								? colors["grey-800"]
								: colors["red-400"],
							shadowColor: "rgba(0, 0, 0, 0.24)",
							shadowOffset: { width: 0, height: 3 },
							shadowRadius: 8,
							elevation: 3,
							zIndex: 3,
						}}
					>
						{showNotification ? (
							<View
								style={{
									width: 14,
									height: 14,
									marginVertical: 4,
								}}
							>
								<CheckIcon />
							</View>
						) : (
							<Text
								allowFontScaling={false}
								style={{
									...styles.buttonBadgeText,
									textAlign: "center",
									textAlignVertical: "center",
								}}
							>
								!
							</Text>
						)}
					</View>
				)}
				<View
					style={[
						styles.buttonLabel,

						{
							display: "flex",
							justifyContent: "space-between",
							flexDirection: "row",
							width: "100%",
							borderBottomWidth: 1,
							borderBottomColor: colors["grey-050"],
							paddingBottom: 10,
							marginBottom: 10,
						},
					]}
				>
					<Text
						allowFontScaling={false}
						style={{
							...styles.mediumText,
							...styles.boldText,
							textAlign: "center",
							textAlignVertical: "center",
						}}
					>
						{label}
					</Text>
					<View
						style={{
							width: 24,
							height: 24,
						}}
					>
						{icon}
					</View>
				</View>
				<View>{text}</View>
			</FooterButton>
		</View>
	</>
)
