/** @format */

import { MotiPressable, MotiPressableProp } from "moti/interactions"
import React from "react"
import { StyleProp, ViewStyle, Text, TextStyle } from "react-native"

const Button: React.FC<{
	style: StyleProp<ViewStyle>
	labelStyle: StyleProp<TextStyle>
	children: React.ReactNode
	disabled?: boolean
	onPress: () => void
	onPressIn: () => void
	customComponent?: boolean
	animate?: MotiPressableProp
}> = ({
	style,
	labelStyle,
	onPress,
	onPressIn,
	disabled,
	children,
	customComponent,
	animate,
}) => {
	return (
		<MotiPressable
			style={style}
			onPress={onPress}
			onPressIn={onPressIn}
			disabled={disabled}
			animate={animate}
			transition={React.useMemo(
				() =>
					({ pressed }) => {
						"worklet"

						return {
							delay: pressed ? 0 : 50,
						}
					},
				[],
			)}
		>
			{customComponent ? children : <Text style={labelStyle}>{children}</Text>}
		</MotiPressable>
	)
}

export default Button
