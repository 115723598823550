/** @format */

import { MotiText, MotiView, SequenceItem, useAnimationState } from "moti"
import React, { useCallback, useEffect, useState } from "react"
import { OpaqueColorValue } from "react-native"
import { shallow } from "zustand/shallow"

import { SHAKEY_ANIMATION } from "./Animations"
import { WordButtonPressable } from "./WordButtonPressable"
import { type Letter, SCORES } from "../../../../../utils/wordManager"
import { useGameStore } from "../../../../context/GameManager/GameManager"
import { GAME_MODE_CONFIG, GameplayState } from "../../../../context/types"
import { styles } from "../../../../styles"
import { colors, noSelect } from "../../../../styles/styles"

type BorderColorType =
	| string
	| number
	| OpaqueColorValue
	| { width: number; height: number }
	| number[]
	| SequenceItem<
			| string
			| number
			| OpaqueColorValue
			| { width: number; height: number }
			| number[]
	  >[]

const WordButton: React.FC<{
	letterIndex: number
	wordIndex: number
	isHold?: boolean
	children?: React.ReactNode
	onPress?: () => void
	onPressIn?: () => void
	onLongPress?: () => void
	canLongPressOverride?: boolean
	canPress?: boolean
}> = ({
	letterIndex,
	wordIndex,
	isHold = false,
	onPress,
	onPressIn,
	onLongPress,
	children,
	canLongPressOverride = true,
	canPress = true,
}) => {
	const guess = useGameStore(
		useCallback(
			state =>
				!isHold &&
				state.gameState[state.gameState.gameMode]?.guesses?.[wordIndex]?.[
					letterIndex
				],
			[wordIndex, letterIndex, isHold],
		),
		shallow,
	)
	const letter = useGameStore(
		useCallback(
			(state): Letter =>
				state.showSolution
					? getEncodedLetter(
							state.gameState[state.gameState.gameMode].wordsEncoded,
							wordIndex,
							letterIndex,
					  )
					: isHold
					? state.gameState[state.gameState.gameMode]?.holdLetter
					: state.gameState[state.gameState.gameMode]?.guesses?.[wordIndex]?.[
							letterIndex
					  ]?.letter,
			[letterIndex, wordIndex, isHold],
		),
		shallow,
	)
	const gameOver = useGameStore(state =>
		[GameplayState.LOST || GameplayState.NO_MOVES].includes(
			state.gameState[state.gameState.gameMode].gameplayState,
		),
	)
	const letterContainerAnimationState = useAnimationState({
		from: {
			translateY: -15,
			rotate: "0deg",
			opacity: 0,
		},
		to: {
			translateY: 0,
			opacity: 1,
			rotate: "0deg",
		},
		correct: {
			translateY: 0,
			opacity: [{ value: 1, duration: 750, delay: 0, type: "timing" }],
			rotate: [{ value: "360deg", duration: 500, delay: 0, type: "spring" }],
		},
		removing: {
			translateY: 0,
			opacity: 1,
			rotate: SHAKEY_ANIMATION,
		},
		invalid: {
			opacity: 1,
			translateY: 0,
			rotate: SHAKEY_ANIMATION,
		},
		exit: {
			translateY: [{ value: -100, delay: 0, duration: 500 }],
			opacity: [{ value: 0, delay: 0, duration: 500 }],
			rotate: "360deg",
		},
	})
	const [removing, setRemoving] = useState(false)
	const [invalid, setInvalid] = useState(false)
	const [ready, setReady] = useState(false)
	const setIsRemoving = isRemoving => {
		if (isRemoving && letterContainerAnimationState.current === "to") {
			letterContainerAnimationState.transitionTo("removing")
		} else if (
			!isRemoving &&
			letterContainerAnimationState.current === "removing"
		) {
			letterContainerAnimationState.transitionTo("to")
		}
		setRemoving(isRemoving)
	}
	useEffect(() => {
		if (guess.complete && letterContainerAnimationState.current === "to") {
			letterContainerAnimationState.transitionTo("correct")
		} else if (
			!guess.complete &&
			letterContainerAnimationState.current === "correct"
		) {
			letterContainerAnimationState.transitionTo("to")
		}
	}, [guess.complete, letterContainerAnimationState])

	useEffect(() => {
		if (invalid) {
			letterContainerAnimationState.transitionTo("invalid")
		} else if (letterContainerAnimationState.current === "invalid") {
			letterContainerAnimationState.transitionTo("to")
		}
	}, [invalid, letterContainerAnimationState])
	return (
		<WordButtonPressable
			letterIndex={letterIndex}
			wordIndex={wordIndex}
			isHold={isHold}
			onPress={onPress}
			onPressIn={onPressIn}
			onLongPress={onLongPress}
			canLongPressOverride={canLongPressOverride}
			canPress={canPress}
			guessed={letter?.letter && letter?.letter !== ""}
			correct={guess.complete}
			gameOver={gameOver}
			setIsRemoving={setIsRemoving}
			setInvalid={setInvalid}
			invalid={invalid}
			disabled={!ready}
		>
			<LetterView
				letterContainerAnimationState={letterContainerAnimationState}
				wordIndex={wordIndex}
				isHold={isHold}
				letter={letter}
				gameOver={gameOver}
				guessed={letter?.letter && letter?.letter !== ""}
				correct={guess.complete}
				isRemoving={removing}
				invalid={invalid}
				setReady={setReady}
			>
				{children}
			</LetterView>
		</WordButtonPressable>
	)
}

export default React.memo(WordButton)

const LetterView = ({
	children,
	gameOver,
	isHold,
	wordIndex,
	letter,
	guessed,
	correct,
	letterContainerAnimationState,
	isRemoving,
	invalid,
	setReady,
}) => {
	const solution = useGameStore(state => state.showSolution)
	const gameMode = useGameStore(state => state.gameState.gameMode)
	const clearRow = useGameStore(state => state.clearRow)

	const delay = wordIndex * 50
	const { letter: label } = letter || {}

	const { shouldClearOnComplete } = GAME_MODE_CONFIG[gameMode] || {}

	const shouldHideBorders =
		(label !== "" && label !== undefined) ||
		solution ||
		gameOver ||
		correct ||
		guessed

	const borderColor: BorderColorType =
		isHold && guessed && !gameOver && !solution
			? colors["indigo-800"]
			: solution
			? colors["red-500"]
			: gameOver
			? colors["grey-500"]
			: correct
			? colors["cyan-800"]
			: shouldHideBorders
			? "transparent"
			: invalid
			? [
					{ value: colors["red-800"], duration: 100, type: "timing" },
					{ value: colors["grey-800"], duration: 100, type: "timing" },
					{ value: colors["red-800"], duration: 100, type: "timing" },
					{ value: colors["grey-800"], duration: 100, type: "timing" },
			  ]
			: colors["grey-800"]
	return (
		<MotiView
			onDidAnimate={(key, finished, maybeValue, { attemptedValue }) => {
				if (
					key === "opacity" &&
					letterContainerAnimationState.current === "to"
				) {
					setReady(true)
				}
				if (!shouldClearOnComplete) {
					return
				}
				if (
					key === "opacity" &&
					letterContainerAnimationState.current === "correct"
				) {
					setReady(false)
					letterContainerAnimationState.transitionTo("exit")
				} else if (
					key === "opacity" &&
					letterContainerAnimationState.current === "exit"
				) {
					letterContainerAnimationState.transitionTo("to")
					clearRow()
				}
			}}
			state={letterContainerAnimationState}
			transition={{
				delay,
			}}
		>
			{!isHold && (
				<MotiView
					style={{
						position: "absolute",
						...styles.wordButton,
						borderColor: "transparent",
						top: 5,
					}}
					animate={{
						opacity: 1,
						backgroundColor:
							borderColor === "transparent" ? colors["grey-800"] : borderColor,
					}}
					from={{ opacity: 0 }}
					transition={{
						rotate: { duration: 50, type: "timing" },
					}}
				/>
			)}
			<MotiView
				from={{
					translateY: 4,
				}}
				animate={{
					translateY:
						shouldHideBorders && !correct && !isHold && !solution ? 4 : 0,
					borderColor,
					backgroundColor: solution
						? colors["red-300"]
						: gameOver
						? colors["grey-400"]
						: correct
						? colors["cyan-600"]
						: guessed
						? isHold
							? colors["indigo-100"]
							: isRemoving
							? colors["red-500"]
							: colors["grey-800"]
						: isHold
						? colors["grey-100"]
						: invalid
						? [
								{ value: colors["red-200"], duration: 100, type: "timing" },
								{
									value: colors["grey-300"],
									duration: 100,
									type: "timing",
								},
								{ value: colors["red-200"], duration: 100, type: "timing" },
								{
									value: colors["grey-300"],
									duration: 100,
									type: "timing",
								},
						  ]
						: colors["grey-300"],
				}}
				transition={{
					backgroundColor: {
						duration: guessed && isRemoving ? 1750 : 300,
						type: "timing",
					},
				}}
				style={{
					...styles.wordButton,

					borderStyle: isHold && !label ? "dashed" : "solid",
					borderWidth: 2,
				}}
			>
				<Letter
					label={label}
					shouldClearOnComplete={shouldClearOnComplete}
					isHold={isHold}
					guessed={guessed}
					gameOver={gameOver}
					solution={solution}
				/>
			</MotiView>

			{children}
		</MotiView>
	)
}

const Letter = ({
	label,
	shouldClearOnComplete,
	isHold,
	guessed,
	gameOver,
	solution,
}) => {
	if (!label || label === "") {
		return null
	}
	return (
		<>
			<MotiView
				from={{ opacity: 0 }}
				animate={{
					opacity: [
						{
							value: label !== "" ? 1 : 0,
							delay: 0,
						},
					],
				}}
				exit={{ opacity: 0 }}
				exitTransition={
					shouldClearOnComplete ? { opacity: { delay: 500 } } : {}
				}
				pointerEvents="none"
			>
				<MotiText
					allowFontScaling={false}
					style={{
						...noSelect,
						...styles.normalText,
						...styles.boldText,
						...(isHold && guessed && !gameOver && !solution
							? { color: colors["indigo-700"] }
							: {}),
					}}
				>
					{label?.toUpperCase()}
				</MotiText>
			</MotiView>

			<MotiText
				allowFontScaling={false}
				style={{
					...noSelect,
					...styles.smallText,
					...styles.wordButtonScore,
					...(isHold && guessed && !gameOver && !solution
						? { color: colors["indigo-700"] }
						: {}),
				}}
			>
				{SCORES[label?.toLowerCase()]}
			</MotiText>
		</>
	)
}

const getEncodedLetter = (
	wordsEncoded: string,
	wordIndex: number,
	letterIndex: number,
) => {
	let letter = null
	try {
		const words = JSON.parse(window.atob(wordsEncoded))
		letter = words.map(word => word.split("").map(letter => ({ letter })))?.[
			wordIndex
		]?.[letterIndex]
	} catch (e) {
		console.log(e)
		return null
	}
	return letter
}
