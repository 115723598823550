/** @format */

import React from "react"
import { View, Text } from "react-native"
import { LineChart } from "react-native-chart-kit"
import { Text as TextSVG } from "react-native-svg"

import styles, { colors } from "../../../styles/styles"

const LastTenChart: React.FC<{ lastTenMatches: number[] }> = ({
	lastTenMatches,
}) => {
	if ((lastTenMatches || []).length <= 1) {
		return (
			<View
				style={{
					...styles.centerBoxRow,
					marginBottom: 15,
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Text
					allowFontScaling={false}
					style={{
						...styles.mediumText,
						...styles.darkText,
					}}
				>
					No data yet. Check back in after a few matches.
				</Text>
			</View>
		)
	}
	return (
		<LineChart
			data={{
				labels: [],
				datasets: [
					{
						data: new Array(10).fill(0).map((a, idx) => {
							if (idx < 10 - lastTenMatches.length) {
								return 0
							} else {
								return lastTenMatches[lastTenMatches.length - 10 + idx]
							}
						}),
					},
				],
			}}
			width={300}
			height={220}
			fromZero={true}
			xLabelsOffset={5}
			yAxisInterval={1} // optional, defaults to 1
			chartConfig={{
				backgroundGradientFromOpacity: 0,
				backgroundGradientToOpacity: 0,
				decimalPlaces: 0, // optional, defaults to 2dp
				color: (opacity = 1) => colors["cyan-400"],
				labelColor: (opacity = 1) => colors["grey-800"],
				style: {
					borderRadius: 16,
				},
				propsForDots: {
					r: "6",
					strokeWidth: "2",
					stroke: colors["cyan-600"],
				},
				propsForLabels: {
					fontFamily: "Inter_700Bold",
				},
				propsForBackgroundLines: {
					display: "none",
				},
			}}
			bezier
			renderDotContent={({ x, y, index, indexData }) => (
				<TextSVG
					key={index}
					x={x}
					y={y + 16}
					fontFamily="Inter_700Bold"
					fontSize="10"
					textAnchor="middle"
					fill={colors["grey-900"]}
					stroke="transparent"
				>
					{indexData === 0 ? null : indexData}
				</TextSVG>
			)}
			style={{
				marginVertical: 8,
				borderRadius: 16,
				marginLeft: -52,
				justifyContent: "center",
				flexDirection: "row",
			}}
		/>
	)
}

const LastTenChartContainer: React.FC<{
	lastTenMatches: number[]
	label: string
}> = ({ lastTenMatches, label }) => {
	return (
		<View style={{ marginHorizontal: 15 }}>
			<View
				style={{
					borderColor: colors["grey-900"],
					borderWidth: 1,
					padding: 15,
					marginBottom: 15,
					borderRadius: 5,
					backgroundColor: colors["grey-050"],
					zIndex: 1,
					height: 280,
				}}
			>
				<View
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignContent: "center",
						flexDirection: "row",
						borderColor: colors["grey-400"],
						borderBottomWidth: 1,
						marginBottom: 10,
					}}
				>
					<View
						style={{
							display: "flex",
							justifyContent: "center",
							paddingBottom: 10,
						}}
					>
						<Text
							allowFontScaling={false}
							style={{
								...styles.mediumText,
								...styles.boldText,
								...styles.darkText,
							}}
						>
							{label}
						</Text>
					</View>
				</View>

				<LastTenChart lastTenMatches={lastTenMatches} />
			</View>
			<View
				style={{
					position: "absolute",
					top: 4,
					height: 280,
					width: "100%",
					zIndex: 0,
					borderColor: "transparent",
					borderRadius: 5,
					backgroundColor: colors["grey-900"],
				}}
			/>
		</View>
	)
}

export default LastTenChartContainer
