/** @format */

import * as Linking from "expo-linking"
import { MotiText } from "moti"
import { MotiPressable } from "moti/interactions"
import React, { useMemo, useCallback, useContext, useState } from "react"
import { View, Text, Pressable, Platform } from "react-native"

import { ActionRow } from "./ActionRow"
import AppStoreButton from "./AppStoreButton"
import GooglePlayButton from "./GooglePlayButton"
import SoundContext from "../../../../utils/SoundContext"
import {
	getEncodedScore,
	useGameStore,
} from "../../../context/GameManager/GameManager"
import {
	GAME_MODE_CONFIG,
	GameMode,
	GameplayState,
} from "../../../context/types"
import useInterval from "../../../hooks/useInterval"
import { styles } from "../../../styles"
import { colors } from "../../../styles/styles"
import { ShareButton } from "../../challengeModal/components/ChallengeModal"

export const Footer = () => {
	const seed = useGameStore(state => state.gameState.currentChallengeSeed)
	const newGame = useGameStore(state => state.newGame)
	const gameOver = useGameStore(state => state.gameOver)

	const isCustomSeed = useGameStore(state => state.isCustomSeed)

	const gameMode = useGameStore(state => state.gameState.gameMode)
	const gameplayState = useGameStore(
		state => state.gameState[state.gameState.gameMode].gameplayState,
	)

	const { allowNewGame } = GAME_MODE_CONFIG[gameMode]

	return (
		<View style={{ maxWidth: 450, width: "100%", flexGrow: 2 }}>
			<ActionRow />

			{isCustomSeed && (
				<>
					<View style={{ marginBottom: 15 }}>
						<ShareButton challengeSeed={seed} />
					</View>
					<FooterButton onPress={() => (window.location.href = "/")}>
						<MotiText allowFontScaling={false} style={styles.mediumText}>
							LEAVE DUEL
						</MotiText>
					</FooterButton>
				</>
			)}
			{allowNewGame && (
				<NewGameButton
					gameOver={gameOver}
					newGame={newGame}
					gameplayState={gameplayState}
				/>
			)}

			<ShowSolutionButton gameplayState={gameplayState} gameMode={gameMode} />
			{Platform.OS === "web" && (
				<View
					style={{
						width: "100%",
						display: "flex",
						justifyContent: "center",
						flexDirection: "row",
						marginTop: 15,
					}}
				>
					<Pressable
						onPress={() =>
							Linking.openURL(
								"https://apps.apple.com/us/app/wordtree-a-daily-word-puzzle/id6446817670",
							)
						}
						style={{
							width: 170,
							display: "flex",
							justifyContent: "flex-end",
							flexDirection: "row",
							marginRight: 5,
						}}
					>
						<AppStoreButton />
					</Pressable>
					<Pressable
						onPress={() =>
							Linking.openURL(
								"https://play.google.com/store/apps/details?id=com.hdwatts.wordtree",
							)
						}
						style={{ marginLeft: 5 }}
					>
						<GooglePlayButton />
					</Pressable>
				</View>
			)}
		</View>
	)
}

export default Footer

export const FooterButton = ({
	onPress,
	children,
	light = false,
	style = {},
	disabled = false,
}) => {
	const SoundManager = useContext(SoundContext)
	const borderColor = disabled
		? colors["grey-600"]
		: style["borderColor"] || colors["cyan-800"]

	const backgroundColor = disabled
		? colors["grey-400"]
		: style["backgroundColor"] || colors["cyan-600"]

	const animate = useMemo(
		() =>
			({ hovered, pressed }) => {
				"worklet"

				return {
					translateY: pressed ? 4 : hovered ? -2 : 0,
				}
			},
		[],
	)

	return (
		<View>
			<MotiPressable
				animate={animate}
				style={{
					...styles.centerBox,
				}}
				disabled={disabled}
				onPressIn={() => SoundManager.playPush()}
				onPress={() => {
					onPress()
				}}
				containerStyle={{ zIndex: 1 }}
			>
				<View
					style={{
						...styles.footerButton,
						height: 50,
						...styles.centerBox,
						...style,
						borderColor,
						backgroundColor,
					}}
				>
					{children}
				</View>
			</MotiPressable>
			<View
				style={{
					...styles.footerButton,
					width: "100%",
					position: "absolute",
					bottom: -5,
					zIndex: 0,
					backgroundColor: borderColor,
					borderColor: "transparent",
				}}
			/>
		</View>
	)
}

const NewGameButton = ({
	newGame,
	gameplayState,

	gameOver,
}) => {
	const hasMadeMove = useGameStore(state => {
		const gameState = state.gameState[state.gameState.gameMode]
		return (
			gameState.attempts !== 1 ||
			gameState.guesses.some(row =>
				row.some(guess => guess.letter && guess.letter.letter !== ""),
			) ||
			gameState.score > 0
		)
	})
	const [isSure, setIsSure] = useState(false)

	const executeNewGame = () => {
		if (
			gameplayState === GameplayState.WON ||
			gameplayState === GameplayState.LOST ||
			!hasMadeMove
		) {
			newGame()
		} else if (isSure) {
			gameOver()
			setIsSure(false)
		} else {
			setIsSure(true)
		}
	}

	return (
		<View style={{ marginTop: 15 }}>
			<FooterButton
				onPress={executeNewGame}
				style={
					isSure
						? {
								backgroundColor: colors["red-600"],
								marginTop: 0,
								borderColor: colors["red-800"],
						  }
						: { marginTop: 0 }
				}
			>
				{!isSure && (
					<MotiText
						allowFontScaling={false}
						style={{
							...styles.buttonLabel,
							...styles.mediumText,
							...styles.boldText,
						}}
					>
						NEW GAME
					</MotiText>
				)}
				{isSure && (
					<View>
						<Timer isSure={isSure} setIsSure={setIsSure} />
						<MotiText
							allowFontScaling={false}
							style={{
								...styles.buttonLabel,
								...styles.mediumText,
								...styles.boldText,
							}}
						>
							ARE YOU SURE?
						</MotiText>
						<MotiText
							allowFontScaling={false}
							style={{ ...styles.buttonLabel, ...styles.smallText }}
						>
							This will count as a loss in your statistics.
						</MotiText>
					</View>
				)}
			</FooterButton>
		</View>
	)
}

const Timer = ({ isSure, setIsSure }) => {
	const [timer, setTimer] = useState(5)
	const intervalCallback = useCallback(() => {
		setTimer(timer - 1)
		if (timer === 1) {
			setTimer(5)
			setIsSure(false)
		}
	}, [timer, setIsSure])
	useInterval(intervalCallback, isSure ? 1000 : null)
	return (
		<Text
			allowFontScaling={false}
			style={{
				position: "absolute",
				top: 0,
				right: 0,
				...styles.smallText,
				...styles.boldText,
			}}
		>
			{timer}
		</Text>
	)
}

const ShowSolutionButton: React.FC<{
	gameplayState: GameplayState
	gameMode: GameMode
}> = ({ gameplayState, gameMode }) => {
	const toggleSolution = useGameStore(state => state.toggleSolution)
	const showSolution = useGameStore(state => state.showSolution)
	const score = useGameStore(
		state => state.gameState[state.gameState.gameMode].score,
	)
	const attempts = useGameStore(
		state => state.gameState[state.gameState.gameMode].attempts,
	)
	const wordsEncoded = useGameStore(
		state => state.gameState[state.gameState.gameMode].wordsEncoded,
	)
	const [isSure, setIsSure] = useState(false)

	const executeShowSolution = () => {
		if (
			(gameplayState !== GameplayState.PLAYING &&
				gameplayState !== GameplayState.NO_MOVES) ||
			showSolution
		) {
			toggleSolution(false)
		} else if (isSure) {
			toggleSolution(true)
			setIsSure(false)
		} else {
			setIsSure(true)
		}
	}

	const encodedScore = useMemo(
		() => (attempts === 1 ? 2 : 1) * getEncodedScore(wordsEncoded),
		[wordsEncoded, attempts],
	)

	const { allowShowSolution, gameOverCondition, attemptsAllowed } =
		GAME_MODE_CONFIG[gameMode] || {}

	const showButton =
		wordsEncoded &&
		allowShowSolution &&
		(gameplayState === GameplayState.LOST ||
			(attemptsAllowed &&
				gameOverCondition === "ON_USER" &&
				attempts > attemptsAllowed) ||
			gameplayState === GameplayState.WON)

	return (
		showButton && (
			<View style={{ marginTop: 15 }}>
				<FooterButton
					onPress={() => executeShowSolution()}
					style={
						isSure
							? {
									borderColor: colors["red-800"],
									backgroundColor: colors["red-600"],
									marginTop: 0,
							  }
							: {}
					}
				>
					{!isSure && (
						<MotiText
							allowFontScaling={false}
							style={[styles.buttonLabel, styles.mediumText, styles.boldText]}
						>
							{showSolution ? "HIDE " : "SHOW "}OUR SOLUTION
						</MotiText>
					)}
					{(gameplayState === GameplayState.WON ||
						gameplayState === GameplayState.LOST) &&
						!isSure &&
						encodedScore &&
						!GAME_MODE_CONFIG[gameMode]?.disableStats?.beatUs && (
							<View>
								<MotiText
									allowFontScaling={false}
									style={{
										...styles.buttonLabel,
										...styles.smallText,
										fontWeight: "normal",
									}}
								>
									{gameplayState === GameplayState.WON &&
										(score > encodedScore ? `Expert Solution! ` : "Beat you! ")}
									Our solution was worth {encodedScore} points.
								</MotiText>
							</View>
						)}
					{isSure && (
						<View>
							<Timer isSure={isSure} setIsSure={setIsSure} />
							<MotiText
								allowFontScaling={false}
								style={{
									...styles.buttonLabel,
									...styles.mediumText,
									...styles.boldText,
								}}
							>
								ARE YOU SURE?
							</MotiText>
							<MotiText
								allowFontScaling={false}
								style={{ ...styles.buttonLabel, ...styles.smallText }}
							>
								This will count as a loss in your statistics.
							</MotiText>
						</View>
					)}
				</FooterButton>
			</View>
		)
	)
}
