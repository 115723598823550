/** @format */

import WordManager from "../../../utils/wordManager"
import { NO_SEED } from "../TimeContext/TimeContext"
import {
	GameMode,
	INITIAL_STATS,
	GameManagerState,
	Stat,
	GameModeState,
	GAME_MODE_CONFIG,
	Guess,
	GameplayState,
} from "../types"

export const SKIPS_ALLOWED = 10
export const wordManager = new WordManager()

export const generateEmptyGuessArray = () => {
	const arr: Guess[][] = []
	for (let i = 0; i < 5; i++) {
		const a: Guess[] = []
		for (let i2 = 0; i2 < 5 - i; i2++) {
			a.push({
				letter: {
					letter: "",
				},
				complete: false,
			})
		}
		arr.unshift(a)
	}
	return arr
}

export const generateNewGameState = (
	gameMode: GameMode,
	stats?: Stat,
	seed?: string,
): GameModeState => {
	const _seed = seed
		? `${seed}${GAME_MODE_CONFIG[gameMode]?.seedSuffix || ""}`
		: undefined
	const { letters, words } = wordManager.generateLetters(gameMode, _seed)

	const currLetter = letters.shift()
	return {
		stats: stats || {
			...INITIAL_STATS,
		},
		letters,
		initialLetters: [currLetter, ...letters],
		currLetter,
		holdLetter: null,
		guesses: generateEmptyGuessArray(),
		skipsLeft: SKIPS_ALLOWED,
		attempts: 1,
		score: 0,
		numGuesses: 0,
		gameplayState: GameplayState.PLAYING,
		wordsEncoded: window.btoa(JSON.stringify(words)),
		...(GAME_MODE_CONFIG[gameMode]?.initialGameStateOverride || {}),
	}
}

export const handleMigrations = (persistedState: unknown, version: number) => {
	let newState = persistedState as GameManagerState
	if (version === 7) {
		const oldState = persistedState as Omit<GameManagerState, "gameState"> & {
			gameState: Omit<GameManagerState["gameState"], GameMode.STUMP>
		}
		newState = {
			...oldState,
			gameState: {
				...oldState.gameState,
				[GameMode.STUMP]: generateNewGameState(
					GameMode.STUMP,
					INITIAL_STATS,
					`${oldState.challengeSeed || NO_SEED}`,
				),
			},
		}
	}
	return newState
}

export const loadFromOldState = (
	state: GameManagerState,
	oldState: GameManagerState & { VERSION_NUMBER: number },
) => {
	if (oldState.VERSION_NUMBER < 6) {
		return state
	}
	const newGameState = {
		...state.gameState,
		[GameMode.CASUAL]: {
			...state.gameState[GameMode.CASUAL],
			...oldState[GameMode.CASUAL],
			stats: {
				...state.gameState[GameMode.CASUAL].stats,
				...oldState[GameMode.CASUAL].stats,
				average: Array.isArray(oldState[GameMode.CASUAL].stats.average)
					? oldState[GameMode.CASUAL].stats.average
					: [0, 0],
			},
		},
		[GameMode.CHALLENGE]: {
			...state.gameState[GameMode.CHALLENGE],
			...oldState[GameMode.CHALLENGE],
			stats: {
				...state.gameState[GameMode.CHALLENGE].stats,
				...oldState[GameMode.CHALLENGE].stats,
				average: Array.isArray(oldState[GameMode.CHALLENGE].stats.average)
					? oldState[GameMode.CHALLENGE].stats.average
					: [0, 0],
			},
		},
		[GameMode.ENDLESS]: {
			...state.gameState[GameMode.ENDLESS],
			...oldState[GameMode.ENDLESS],
			stats: {
				...state.gameState[GameMode.ENDLESS].stats,
				...oldState[GameMode.ENDLESS].stats,
				average: Array.isArray(oldState[GameMode.ENDLESS].stats.average)
					? oldState[GameMode.ENDLESS].stats.average
					: [0, 0],
			},
		},
	}

	return {
		...state,
		gameState: newGameState,
	}
}
