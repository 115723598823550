/** @format */
import seedrandom from "seedrandom"

import words, { COMMON_WORDS } from "./words"
import { GameMode, __TUTORIAL_SEED__ } from "../src/context/types"

export const SCORES = {
	a: 1,
	b: 3,
	c: 3,
	d: 2,
	e: 1,
	f: 4,
	g: 2,
	h: 4,
	i: 1,
	j: 8,
	k: 5,
	l: 1,
	m: 3,
	n: 1,
	o: 1,
	p: 3,
	q: 10,
	r: 1,
	s: 1,
	t: 1,
	u: 1,
	v: 4,
	w: 4,
	x: 8,
	y: 4,
	z: 10,
}

export type Letter = {
	letter: string
	multiplier?: number
}

class TrieNode {
	children: TrieNode[]
	isEndOfWord
	constructor() {
		this.children = []
		this.isEndOfWord = false
	}
}

const BLACKLIST = []
type SIZE_INDEX = 1 | 2 | 3 | 4 | 5
class WordManager {
	ALPHABET = "abcdefghijklmnopqrstuvwxyz"
	rootNode: TrieNode
	canPlayChallenge: boolean
	wordsBySize: Map<SIZE_INDEX, string[]>

	constructor() {
		this.rootNode = this.newNode()
		this.wordsBySize = new Map()
		this.canPlayChallenge = false
		this.setupWordsBySize()
		this.setupWordTrie()
	}

	setupWordsBySize = () => {
		COMMON_WORDS.forEach(word => {
			const index: SIZE_INDEX = word.length as SIZE_INDEX
			const wordsAtSize = this.wordsBySize.get(index) || []
			wordsAtSize.push(word)
			this.wordsBySize.set(index, wordsAtSize)
		})
	}

	setupWordTrie = () =>
		words
			.filter(word => BLACKLIST.indexOf(word) === -1)
			.forEach(word => {
				this.insert(word)
			})

	getWords = (randomFn?: typeof seedrandom): string[] => {
		const words = []
		for (let i = 1 as SIZE_INDEX; i < 6; i++) {
			const wordsAtSize = this.wordsBySize.get(i)
			const word =
				wordsAtSize[
					Math.floor((randomFn || Math.random)() * wordsAtSize.length)
				]
			words.push(word.toLowerCase())
		}
		return words
	}

	getRandomLetter(randomFn) {
		return this.ALPHABET[Math.floor(randomFn() * this.ALPHABET.length)]
	}

	generateLetters = (
		gameMode: GameMode,
		seed?: string,
	): { letters: Letter[]; words: string[] } => {
		const randomFn = seed ? seedrandom(seed) : Math.random
		const words = this.getWords(randomFn)
		const letters = words.join("").split("")
		if (gameMode !== GameMode.STUMP) {
			for (let a = 0; a < 11; a++) {
				letters.push(this.getRandomLetter(randomFn))
			}
		}

		const randomSortedLetters = letters
			.map(value => ({ value, sort: randomFn() }))
			.sort((a, b) => a.sort - b.sort)
			.map(
				({ value }, index): Letter => ({
					letter: value,
					multiplier: 1,
				}),
			)

		if (seed === __TUTORIAL_SEED__) {
			const makeTutorialLetter = (letter: string) => ({ letter })

			return {
				letters: [
					"a",
					"o",
					"d",
					"p",
					"y",
					"g",
					"q",
					"i",
					"e",
					"a",
					"r",
					"e",
					"s",
					"l",
					"f",
					"t",
					"c",
					"a",
					"i",
					"o",
					"u",
					"s",
				].map(makeTutorialLetter),
				words: ["A", "IF", "DOG", "PLAY", "TREES"],
			}
		}

		return { letters: randomSortedLetters, words }
	}

	insert = (word: string) => {
		let crawlNode = this.rootNode
		for (let i = 0; i < word.length; i++) {
			const index = this.ALPHABET.indexOf(word[i])
			if (!crawlNode.children[index]) {
				crawlNode.children[index] = this.newNode()
			}
			crawlNode = crawlNode.children[index]
		}
		crawlNode.isEndOfWord = true
	}

	search = (word: string): boolean => {
		let crawlNode = this.rootNode
		for (let i = 0; i < word.length; i++) {
			const index = this.ALPHABET.indexOf(word[i])
			if (!crawlNode.children[index]) {
				return false
			}
			crawlNode = crawlNode.children[index]
		}
		return crawlNode.isEndOfWord
	}

	searchAll = (letters: string[], possibleLetter?: string): boolean => {
		const indexes = letters.reduce(
			(acc, i, index) => (i === null || i === "" ? [...acc, index] : acc),
			[],
		)
		const filteredIndexes = letters.reduce(
			(acc, i, index) => (i !== null && i !== "" ? [...acc, index] : acc),
			[],
		)
		return words.some(
			word =>
				word.length === letters.length &&
				(!possibleLetter ||
					indexes.some(index => word[index] === possibleLetter)) &&
				filteredIndexes.every(index => word[index] === letters[index]),
		)
	}

	newNode = (): TrieNode => {
		const node = new TrieNode()
		for (let i = 0; i < this.ALPHABET.length; i++) {
			node.children[i] = null
		}
		return node
	}
}

export default WordManager
