/** @format */

import { useCallback, useMemo } from "react"
import { Pressable } from "react-native"

import { useGameStore } from "../../../../context/GameManager/GameManager"
import { __INVALID_GUESS__ } from "../../../../context/types"

export const WordButtonPressable: React.FC<{
	letterIndex: number
	wordIndex: number
	isHold?: boolean
	children?: React.ReactNode
	onPress?: () => void
	onPressIn?: () => void
	onLongPress?: () => void
	setIsRemoving?: (b: boolean) => void
	setInvalid?: (b: boolean) => void
	canLongPressOverride?: boolean
	canPress?: boolean
	guessed: boolean
	correct: boolean
	gameOver: boolean
	invalid: boolean
	disabled?: boolean
}> = ({
	children,
	wordIndex,
	letterIndex,
	onPress,
	onPressIn,
	onLongPress,
	canLongPressOverride,
	canPress = true,
	isHold,
	guessed,
	correct,
	gameOver,
	setIsRemoving,
	setInvalid,
	invalid,
	disabled,
}) => {
	const doHoldLetter = useGameStore(state => state.doHoldLetter)
	const undo = useGameStore(state => state.undo)
	const checkGuess = useGameStore(state => state.checkGuess)
	const makeGuess = useGameStore(state => state.makeGuess)

	const canLongPress = useGameStore(
		useCallback(
			({ gameState }) =>
				canLongPressOverride || gameState[gameState.gameMode].skipsLeft >= 3,
			[canLongPressOverride],
		),
	)

	const doOnPress = useCallback(() => {
		if (!canPress) {
			return
		}
		if (isHold) {
			doHoldLetter()
		} else {
			makeGuess(wordIndex, letterIndex)
		}
		onPress && onPress()
	}, [
		canPress,
		isHold,
		onPress,
		wordIndex,
		letterIndex,
		doHoldLetter,
		makeGuess,
	])

	const doOnPressIn = useCallback(() => {
		if (!canPress) {
			return
		}
		onPressIn && onPressIn()
		if (isHold) {
			return
		}
		if (!invalid && checkGuess(wordIndex, letterIndex) === __INVALID_GUESS__) {
			setInvalid(true)
			setTimeout(() => setInvalid(false), 500)
		}

		canLongPress && guessed && !correct && setIsRemoving(true)
	}, [
		onPressIn,
		guessed,
		correct,
		isHold,
		canPress,
		wordIndex,
		letterIndex,
		setIsRemoving,
		setInvalid,
		canLongPress,
		checkGuess,
		invalid,
	])
	const doOnLongPress = useCallback(() => {
		if (canLongPress) {
			undo(wordIndex, letterIndex)
			onLongPress && onLongPress()
		}
		setIsRemoving(false)
	}, [setIsRemoving, wordIndex, letterIndex, canLongPress, onLongPress, undo])
	const unsetRemoving = useCallback(() => {
		setIsRemoving(false)
	}, [setIsRemoving])

	const style = useMemo(() => ({ zIndex: 2 }), [])

	return (
		<Pressable
			style={style}
			onPress={doOnPress}
			onPressIn={doOnPressIn}
			onHoverOut={unsetRemoving}
			onTouchCancel={unsetRemoving}
			onPressOut={unsetRemoving}
			onLongPress={doOnLongPress}
			delayLongPress={guessed ? 1250 : 20000}
			disabled={disabled || gameOver}
		>
			{children}
		</Pressable>
	)
}
