/** @format */
import { MotiView } from "moti"
import { useContext } from "react"
import { StyleProp, Text, ViewStyle } from "react-native"

import WordButton from "./WordButton/WordButton"
import SoundContext from "../../../../utils/SoundContext"
import { styles } from "../../../styles"

const HoldButton: React.FC<{
	onPress?: () => void
	canPress?: boolean
	style?: StyleProp<ViewStyle>
}> = ({ onPress, canPress, style }) => {
	const SoundManager = useContext(SoundContext)

	return (
		<MotiView
			from={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 750, type: "timing" }}
			style={
				style
					? style
					: {
							zIndex: 3,
							position: "absolute",
							right: 0,
							display: "flex",
							justifyContent: "center",
					  }
			}
		>
			<WordButton
				isHold
				canPress={canPress}
				onPress={onPress}
				onPressIn={() => SoundManager.playPush()}
				wordIndex={-1}
				letterIndex={-1}
			/>
			<Text
				maxFontSizeMultiplier={1.25}
				style={{
					...styles.smallText,
					...styles.darkText,
					...styles.boldText,
					textAlign: "center",
				}}
			>
				HOLD
			</Text>
		</MotiView>
	)
}

export default HoldButton
