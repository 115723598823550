/** @format */

import { View, Text, Linking } from "react-native"

import { JS_VERSION } from "../context/types"
import { styles } from "../styles"
import { colors } from "../styles/styles"

const Credits = () => (
	<View
		style={{
			...styles.instructionTextSection,
			marginBottom: 35,
			paddingBottom: 0,
			justifyContent: "space-between",
			flexDirection: "row",
		}}
	>
		<Text
			allowFontScaling={false}
			style={{
				...styles.smallText,
				color: colors["grey-300"],
				justifyContent: "flex-start",
				display: "flex",
				textAlign: "left",
			}}
		>
			v{JS_VERSION}
		</Text>
		<Text
			allowFontScaling={false}
			style={{
				...styles.smallText,
				...styles.darkText,
				justifyContent: "flex-end",
				display: "flex",
				textAlign: "right",
			}}
		>
			WordTree was made by{" "}
			<Text
				allowFontScaling={false}
				style={{ ...styles.darkText, ...styles.boldText }}
				onPress={() => Linking.openURL("https://www.hdwatts.com/")}
			>
				hdwatts
			</Text>
			.
		</Text>
	</View>
)

export default Credits
