/** @format */

export const TUTORIAL_STEPS = {
	WELCOME: 0,
	WORD_TREE_INTRO: 1,
	CURRENT_LETTER: 2,
	TOUGHER_LETTER: 3,
	PLACE_A: 4,
	PLACE_O: 5,
	PLACE_D: 6,
	PLACE_P: 7,
	HOLD_LETTER: 8,
	PLACE_G: 9,
	SCORE: 10,
	SKIP_BUTTON: 11,
	SKIP_BUTTON_2: 12,
	SWAP_HOLD: 13,
	PLACE_Y: 14,
	REMOVE_P: 15,
	RESET_BUTTON: 16,
	GAME_MODE: 17,
	SET_GAME_MODE: 18,
	PLAYING: 19,
}
