/** @format */
import Constants from "expo-constants"
import { StyleSheet, Dimensions, Platform } from "react-native"

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get("window")

export function normalize(size) {
	if (SCREEN_WIDTH < 450) {
		return 32
	} else {
		return size
	}
}

export const colors = {
	// Primary
	"cyan-050": "#E0FCFF",
	"cyan-100": "#BEF8FD",
	"cyan-200": "#87EAF2",
	"cyan-300": "#54D1DB",
	"cyan-400": "#38BEC9",
	"cyan-500": "#2CB1BC",
	"cyan-600": "#14919B",
	"cyan-700": "#0E7C86",
	"cyan-800": "#0A6C74",
	"cyan-900": "#044E54",

	// Neutrals
	"grey-050": "#F0F4F8",
	"grey-100": "#D9E2EC",
	"grey-200": "#BCCCDC",
	"grey-300": "#9FB3C8",
	"grey-400": "#829AB1",
	"grey-500": "#627D98",
	"grey-600": "#486581",
	"grey-700": "#334E68",
	"grey-800": "#243B53",
	"grey-900": "#102A43",

	// Supporting
	"indigo-050": "#E0E8F9",
	"indigo-100": "#BED0F7",
	"indigo-200": "#98AEEB",
	"indigo-300": "#7B93DB",
	"indigo-400": "#647ACB",
	"indigo-500": "#4C63B6",
	"indigo-600": "#4055A8",
	"indigo-700": "#35469C",
	"indigo-800": "#2D3A8C",
	"indigo-900": "#19216C",

	"pink-050": "#FFE0F0",
	"pink-100": "#FAB8D9",
	"pink-200": "#F191C1",
	"pink-300": "#E668A7",
	"pink-400": "#DA4A91",
	"pink-500": "#C42D78",
	"pink-600": "#AD2167",
	"pink-700": "#9B1B5A",
	"pink-800": "#781244",
	"pink-900": "#5C0B33",

	"red-050": "#FFEEEE",
	"red-100": "#FACDCD",
	"red-200": "#F29B9B",
	"red-300": "#E66A6A",
	"red-400": "#D64545",
	"red-500": "#BA2525",
	"red-600": "#A61B1B",
	"red-700": "#911111",
	"red-800": "#780A0A",
	"red-900": "#610404",

	"yellow-050": "#FFFAEB",
	"yellow-100": "#FCEFC7",
	"yellow-200": "#F8E3A3",
	"yellow-300": "#F9DA8B",
	"yellow-400": "#F7D070",
	"yellow-500": "#E9B949",
	"yellow-600": "#C99A2E",
	"yellow-700": "#A27C1A",
	"yellow-800": "#7C5E10",
	"yellow-900": "#513C06",
}

export const styles = StyleSheet.create({
	headerContainer: {
		flex: 1,
		display: "flex",
		maxHeight: 60 + Constants.statusBarHeight,
		color: colors["grey-050"],
		fontFamily: "Inter",
		alignItems: "center",
		justifyContent: "center",
		position: "relative",
		zIndex: 1,
		minHeight: 60 + Constants.statusBarHeight,
	},
	container: {
		color: colors["grey-050"],
		fontFamily: "Inter",
		alignItems: "center",
		justifyContent: "center",
		padding: 15,
		// overflow: "scroll",
	},
	scrollContainer: {
		maxHeight: SCREEN_HEIGHT - 60 - Constants.statusBarHeight,
	},
	centeredView: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		position: "relative",
	},
	fullCenteredView: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		marginTop: Constants.statusBarHeight,
		position: "absolute",
		zIndex: 999999,
	},
	overlay: {
		maxWidth: 450,
		padding: 25,
		borderRadius: 25,
		backgroundColor: "hsla(209, 61%, 16%, .95)",
		boxShadow:
			"rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
	},
	mediumText: {
		color: colors["grey-050"],
		fontSize: 16,
		fontFamily: "Inter",
	},
	boldText: {
		fontFamily: "Inter_700Bold",
		fontWeight: "bold",
	},
	normalText: {
		color: colors["grey-050"],
		fontSize: 32,
		fontFamily: "Inter",
	},
	headerText: {
		color: colors["grey-050"],
		fontSize: 56,
		fontFamily: "Inter_700Bold",
		fontWeight: "bold",
	},
	valueText: {
		color: colors["grey-050"],
		fontSize: 32,
		fontFamily: "Inter_700Bold",
		fontWeight: "bold",
		justifyContent: "center",
		alignContent: "center",
		alignItems: "center",
		textAlign: "center",
		display: "flex",
	},
	wordTree: {
		display: "flex",
		width: "100%",
		marginBottom: 15,
	},
	wordButtonScore: {
		position: "absolute",
		top: 2,
		right: 4,
	},
	wordButtonMultiplier: {
		position: "absolute",
		bottom: 0,
	},
	currentLetter: {
		borderWidth: 2,
		borderRadius: 5,
		borderStyle: "solid",
		width: 60,
		height: 60,
		backgroundColor: colors["grey-800"],
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		userSelect: "none",
		marginHorizontal: 5,
	},
	doubleWordScore: {
		backgroundColor: colors["red-300"],
		borderColor: colors["red-500"],
	},
	tripleWordScore: {
		backgroundColor: colors["red-400"],
		borderColor: colors["red-700"],
	},
	doubleLetterScore: {
		backgroundColor: colors["cyan-200"],
		borderColor: colors["cyan-400"],
	},
	tripleLetterScore: {
		backgroundColor: colors["cyan-500"],
		borderColor: colors["cyan-700"],
	},
	doubleWordScoreText: {
		color: colors["grey-050"],
	},
	doubleLetterScoreText: {
		color: colors["grey-900"],
	},
	tripleWordScoreText: {
		color: colors["grey-050"],
	},
	tripleLetterScoreText: {
		color: colors["grey-050"],
	},
	wordButton: {
		borderWidth: 2,
		borderRadius: 5,
		borderStyle: "solid",
		width: 60,
		height: 60,
		margin: 5,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		userSelect: "none",
	},
	modalView: {
		maxHeight: "75%",
		margin: 20,
		display: "flex",
		width: "90%",
		maxWidth: 450,
		backgroundColor: colors["grey-050"],
		borderRadius: 20,
		borderWidth: 2,
		borderColor: colors["grey-800"],
		alignItems: "center",
		shadowColor: colors["grey-900"],
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5,
	},
	topRightButton: {
		borderRadius: 20,
		padding: 20,
		justifyContent: "center",
		fontWeight: "bold",
	},
	topRightButtonView: {
		display: "flex",
		justifyContent: "space-between",
		borderColor: "transparent",
		borderTopLeftRadius: 15,
		borderTopRightRadius: 15,
		borderWidth: 2,
		width: "100%",
		flexDirection: "row",
		flexGrow: 2,
		backgroundColor: colors["grey-800"],
	},
	smallText: {
		fontFamily: "Inter",
		fontSize: 12,
		color: colors["grey-050"],
	},
	box: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		marginTop: 15,
		justifyContent: "space-between",
		fontFamily: "Inter",
	},
	boxCol: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		marginTop: 15,
		justifyContent: "space-between",
		fontFamily: "Inter",
	},
	centerBox: {
		display: "flex",
		color: colors["grey-050"],
		fontWeight: "bold",
		justifyContent: "center",
		alignContent: "center",
		flexGrow: 1,
		textAlign: "center",
	},
	centerBoxRow: {
		display: "flex",
		color: colors["grey-050"],
		fontWeight: "bold",
		justifyContent: "center",
		alignContent: "center",
		flexGrow: 1,
		textAlign: "center",
		flexDirection: "row",
	},
	footerButton: {
		minHeight: 50,
		minWidth: 115,
		borderRadius: 5,
		display: "flex",
		alignContent: "center",
		justifyContent: "center",
		fontFamily: "Inter",
		fontWeight: "bold",
		paddingHorizontal: 15,
		userSelect: "none",
		backgroundColor: colors["cyan-600"],
		zIndex: 1,
		borderWidth: 2,
		borderStyle: "solid",
	},
	shareButton: {
		marginHorizontal: 5,
		borderWidth: 2,
		paddingHorizontal: 15,
		borderRadius: 5,
		borderColor: "transparent",
		minHeight: 50,
		maxWidth: 100,
		userSelect: "none",
	},
	darkText: {
		color: colors["grey-900"],
	},
	darkGreyText: {
		color: colors["grey-500"],
	},
	buttonLabel: {
		fontFamily: "Inter_700Bold",
		color: colors["grey-050"],
		fontWeight: "bold",
		fontSize: 16,
		justifyContent: "center",
		alignContent: "center",
		alignItems: "center",
		textAlign: "center",
		display: "flex",
	},
	buttonBadgeText: {
		color: colors["grey-050"],
		fontSize: 16,
		fontWeight: "bold",
		fontFamily: "Inter_700Bold",
	},
	buttonBadge: {
		position: "absolute",
		top: -11,
		right: -11,
		width: 26,
		height: 26,
		display: "flex",
		alignContent: "center",
		justifyContent: "center",
		alignItems: "center",
		justifyItems: "center",
		textAlign: "center",
		backgroundColor: "red",
		borderRadius: 26 / 2,
		borderStyle: "solid",
		borderColor: "transparent",
		overflow: "hidden",
		borderWidth: 2,
		shadowColor: "rgba(0, 0, 0, 0.24)",
		shadowOffset: { width: 0, height: 3 },
		shadowRadius: 8,
		elevation: 3,
	},
	buttonContainer: {
		display: "flex",
		flexDirection: "row",
	},
	headerView: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		fontFamily: "Inter",
		width: "100%",
		position: "absolute",
		top: Constants.statusBarHeight,
		padding: 15,
		backgroundColor: colors["grey-800"],
		elevation: 1,
		zIndex: 4,
	},
	modalScroll: {
		position: "absolute",
		height: 25,
		display: "flex",
		flexDirection: "row",
		width: 25,
		zIndex: 10,
		bottom: 25,
		right: 25,
	},
	instructionTextSection: {
		marginBottom: 15,
	},
	logoText: {
		color: colors["grey-050"],
		fontSize: 24,
		fontWeight: "bold",
	},
	headerIcon: {
		color: colors["grey-050"],
		width: 24,
		height: 24,
	},
	headerIconContainer: {
		marginLeft: 10,
	},
})

export default styles

export const noSelect =
	Platform.OS === "web"
		? {
				userSelect: "none",
				WebkitTouchCallout: "none",
				WebkitUserSelect: "none",
		  }
		: {}
