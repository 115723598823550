/** @format */

import { Platform } from "react-native"

export const getBaseUrl = () => {
	if (Platform.OS === "web") {
		return `${window.location.origin}`
	} else {
		return "https://www.wordtree.app"
	}
}

export const getSeedString = (seed: string) =>
	new Date(seed + "T00:00:00")?.toLocaleDateString("en-US", {
		month: "short",
		day: "numeric",
	})
