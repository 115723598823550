/** @format */

import { MotiPressable } from "moti/interactions"
import React, { useContext } from "react"
import { View, Text, Platform } from "react-native"

import HeaderScore from "./HeaderScore"
import SoundContext from "../../../../utils/SoundContext"
import {
	ChallengeIcon,
	HelpIcon,
	SpeakerMute,
	SpeakerWave,
	StatsIcon,
} from "../../../components/Icon"
import { useGameStore } from "../../../context/GameManager/GameManager"
import { GameMode } from "../../../context/types"
import { styles } from "../../../styles"

export const Header = () => {
	const setShowStats = useGameStore(state => state.setShowStats)
	const setShowSetTutorial = useGameStore(state => state.setShowSetTutorial)
	const setIsMuted = useGameStore(state => state.setIsMuted)
	const showStats = useGameStore(state => state.showStats)
	const newChallenge = useGameStore(state => state.newChallenge)
	const isCustomSeed = useGameStore(state => state.isCustomSeed)
	const gameMode = useGameStore(state => state.gameState.gameMode)

	const SoundManager = useContext(SoundContext)

	return (
		<>
			<View style={styles.headerView}>
				<Text allowFontScaling={false} style={styles.logoText}>
					WordTree
				</Text>
				{gameMode === GameMode.TUTORIAL && (
					<View style={styles.buttonContainer}>
						<MotiPressable
							containerStyle={styles.headerIconContainer}
							style={styles.headerIcon}
							onPress={() => {
								const value = !SoundManager.muted
								SoundManager.setMuted(value)
								setIsMuted(value)
							}}
						>
							{SoundManager.muted ? <SpeakerMute /> : <SpeakerWave />}
						</MotiPressable>
					</View>
				)}
				{gameMode !== GameMode.TUTORIAL && (
					<View style={styles.buttonContainer}>
						<MotiPressable
							containerStyle={styles.headerIconContainer}
							style={styles.headerIcon}
							onPress={() => {
								const value = !SoundManager.muted
								SoundManager.setMuted(value)
								setIsMuted(value)
							}}
						>
							{SoundManager.muted ? <SpeakerMute /> : <SpeakerWave />}
						</MotiPressable>

						{Platform.OS === "web" && false && (
							<MotiPressable
								containerStyle={styles.headerIconContainer}
								style={styles.headerIcon}
								onPressIn={() => SoundManager.playPush()}
								onPress={() => {
									newChallenge()
								}}
							>
								<ChallengeIcon />
							</MotiPressable>
						)}
						{!isCustomSeed && (
							<MotiPressable
								containerStyle={styles.headerIconContainer}
								style={styles.headerIcon}
								onPressIn={() => SoundManager.playPush()}
								onPress={() => {
									setShowStats(!showStats)
								}}
							>
								<StatsIcon />
							</MotiPressable>
						)}
						<MotiPressable
							containerStyle={styles.headerIconContainer}
							style={styles.headerIcon}
							onPressIn={() => SoundManager.playPush()}
							onPress={() => {
								setShowSetTutorial(true)
							}}
						>
							<HelpIcon />
						</MotiPressable>
					</View>
				)}
			</View>
			<HeaderScore />
		</>
	)
}

export default Header
