/** @format */

import { AnimatePresence, MotiView } from "moti"
import React from "react"
import { Text, View } from "react-native"

import { Letter, SCORES } from "../../../../utils/wordManager"
import { useGameStore } from "../../../context/GameManager/GameManager"
import { GameMode } from "../../../context/types"
import { styles } from "../../../styles"
import { colors } from "../../../styles/styles"

export const CurrentLetter: React.FC<{
	gameOver?: boolean
	gameMode: GameMode
	currLetterOverride?: Letter
}> = ({ gameOver, gameMode, currLetterOverride }) => {
	const _letter = useGameStore(
		state => state.gameState[state.gameState.gameMode].currLetter?.letter,
	)
	const animationKey = useGameStore(
		state => state.gameState[state.gameState.gameMode].letters.length,
	)
	const letter = currLetterOverride?.letter || _letter

	const boxStyle = {
		...styles.centerBox,
		...styles.currentLetter,
		...(gameOver
			? { borderColor: "transparent", backgroundColor: colors["grey-400"] }
			: {}),
	}

	return (
		<AnimatePresence exitBeforeEnter>
			<MotiView
				key={`${animationKey}-${gameMode}-${letter}`}
				style={{
					...styles.centerBox,
					alignContent: "center",
					justifyContent: "center",
					flexWrap: "wrap",
				}}
				from={{
					opacity: 0,
					translateY: 0,
					scale: 1.5,
				}}
				animate={{
					opacity: 1,
					scale: 1,
				}}
				exit={{ opacity: 0, translateY: -50 }}
				delay={0}
				transition={{
					type: "timing",
					duration: 250,
				}}
			>
				<View
					style={{
						...boxStyle,
						overflow: "hidden",
						borderColor: "transparent",
					}}
				>
					<Text
						allowFontScaling={false}
						style={{ ...styles.normalText, ...styles.boldText }}
					>
						{letter !== "" ? letter?.toUpperCase() : "-"}
					</Text>
					<Text
						allowFontScaling={false}
						style={{
							...styles.smallText,
							...styles.wordButtonScore,
						}}
					>
						{SCORES[letter]}{" "}
					</Text>
				</View>
			</MotiView>
		</AnimatePresence>
	)
}

export default CurrentLetter
