/** @format */

import React from "react"
import { View } from "react-native"

import GameModeButton from "./GameModeButton"
import HoldButton from "./HoldButton"
import WordButton from "./WordButton/WordButton"
import { CalendarIcon, LeftRightIcon } from "../../../components/Icon"
import { ShakeView } from "../../../components/ShakeView"
import { useGameStore } from "../../../context/GameManager/GameManager"
import { GAME_MODE_CONFIG, GameplayState } from "../../../context/types"
import { styles } from "../../../styles"
import * as styles_1 from "../../../styles/styles"
import { getSeedString } from "../../../utils/utils"

export const WordTree = () => {
	const currentChallengeSeed = useGameStore(({ gameState }) => {
		const currentChallengeSeed = gameState.currentChallengeSeed
		const seedSuffix = GAME_MODE_CONFIG[gameState.gameMode]?.seedSuffix
		const cleanSeed = seedSuffix
			? currentChallengeSeed.replace(seedSuffix, "")
			: currentChallengeSeed

		return getSeedString(cleanSeed)
	})
	const setShouldSetGameMode = useGameStore(state => state.setShouldSetGameMode)
	const gameMode = useGameStore(({ gameState }) => gameState.gameMode)

	const gameplayState = useGameStore(
		({ gameState }) => gameState[gameState.gameMode].gameplayState,
	)

	const showDailyAvailable = useGameStore(
		({ gameState }) =>
			!Object.entries(GAME_MODE_CONFIG).reduce((acc, [key, value]) => {
				return (
					acc &&
					(!value.isDaily ||
						gameState[key]?.gameplayState === GameplayState.LOST ||
						gameState[key]?.gameplayState === GameplayState.WON)
				)
			}, true),
	)
	const GameModeView =
		showDailyAvailable &&
		(gameplayState === GameplayState.LOST ||
			gameplayState === GameplayState.WON)
			? ShakeView
			: View

	const badge = showDailyAvailable ? (
		<GameModeView
			shakeAmount={20}
			style={{
				...styles.buttonBadge,
				backgroundColor: styles_1.colors["red-400"],
				shadowColor: "rgba(0, 0, 0, 0.24)",
				shadowOffset: { width: 0, height: 3 },
				shadowRadius: 8,
			}}
		>
			<View style={{ width: 14, height: 14, marginVertical: 4 }}>
				<CalendarIcon />
			</View>
		</GameModeView>
	) : (
		<View
			style={{
				...styles.buttonBadge,
				backgroundColor: styles_1.colors["grey-800"],
				shadowColor: "rgba(0, 0, 0, 0.24)",
				shadowOffset: { width: 0, height: 3 },
				shadowRadius: 8,
			}}
		>
			<View style={{ width: 14, height: 14, marginVertical: 4 }}>
				<LeftRightIcon />
			</View>
		</View>
	)

	return (
		<View
			key={gameMode}
			style={{
				maxHeight: 415,
				justifyContent: "flex-end",
				width: "100%",
				maxWidth: 450,
				...styles_1.noSelect,
			}}
		>
			<View style={styles.wordTree}>
				<GameModeButton
					gameMode={gameMode}
					setShouldSetGameMode={setShouldSetGameMode}
					badge={badge}
					label={
						GAME_MODE_CONFIG[gameMode].hasSeed
							? currentChallengeSeed
							: undefined
					}
				/>

				{GAME_MODE_CONFIG[gameMode].hasHoldButton && <HoldButton />}

				<View style={styles.centerBoxRow}>
					<WordButton wordIndex={0} letterIndex={0} />
				</View>
				<View style={styles.centerBoxRow}>
					<WordButton wordIndex={1} letterIndex={0} />
					<WordButton wordIndex={1} letterIndex={1} />
				</View>
				<View style={styles.centerBoxRow}>
					<WordButton wordIndex={2} letterIndex={0} />
					<WordButton wordIndex={2} letterIndex={1} />
					<WordButton wordIndex={2} letterIndex={2} />
				</View>
				<View style={styles.centerBoxRow}>
					<WordButton wordIndex={3} letterIndex={0} />
					<WordButton wordIndex={3} letterIndex={1} />
					<WordButton wordIndex={3} letterIndex={2} />
					<WordButton wordIndex={3} letterIndex={3} />
				</View>
				<View style={styles.centerBoxRow}>
					<WordButton wordIndex={4} letterIndex={0} />
					<WordButton wordIndex={4} letterIndex={1} />
					<WordButton wordIndex={4} letterIndex={2} />
					<WordButton wordIndex={4} letterIndex={3} />
					<WordButton wordIndex={4} letterIndex={4} />
				</View>
			</View>
		</View>
	)
}

export default WordTree
