/** @format */
import { MotiView } from "moti"
import { memo } from "react"

import { CursorArrow } from "../../../components/Icon"
import { noSelect } from "../../../styles/styles"

const TutorialArrow: React.FC<{
	top?: number
	left?: number
	right?: number
	dark?: boolean
}> = memo(({ top, left, right, dark }) => {
	return (
		<MotiView
			pointerEvents="none"
			style={{
				position: "absolute",
				top,
				left,
				right,
				height: 33,
				width: 33,
				zIndex: 10,
				...noSelect,
			}}
			from={{ scale: 1.25, opacity: 0 }}
			animate={{ scale: 1, opacity: 1 }}
			transition={{
				loop: true,
				// repeatReverse: false,
				scale: { duration: 1000, type: "timing" },
				opacity: { duration: 1000, type: "timing" },
			}}
		>
			<CursorArrow dark={dark} />
		</MotiView>
	)
})

export default TutorialArrow
