/** @format */

import NetInfo from "@react-native-community/netinfo"
import React, { useState, createContext, useEffect, useMemo } from "react"
import { Platform, AppState } from "react-native"

import { EmptyHeader } from "../../features/header/components/EmptyHeader"
import { getBaseUrl } from "../../utils/utils"

export const TimeContext = createContext({
	isCustomSeed: false,
	value: null,
	isActive: true,
})

export const NO_SEED = "___ERROR__NO__SEED___"

export const TimeProvider = ({ children }) => {
	const [loaded, setLoaded] = useState(false)
	const [value, setValue] = useState<string>(null)
	const [isCustomSeed, setIsCustomSeed] = useState(false)
	const [appState, setAppState] = useState(AppState.currentState)
	const [isOnline, setIsOnline] = useState(false)
	const isActive = appState === "active" && isOnline
	useEffect(() => {
		if (Platform.OS === "ios") {
			const listener = AppState.addEventListener("change", nextAppState =>
				setAppState(nextAppState),
			)
			const unsubscribe = NetInfo.addEventListener(state => {
				setIsOnline(state.isConnected)
			})

			return () => {
				listener.remove()
				unsubscribe()
			}
		}
	}, [])

	useEffect(() => {
		const params =
			Platform.OS === "web"
				? new Proxy(new URLSearchParams(window.location.search), {
						get: (searchParams, prop) => searchParams.get(prop?.toString()),
				  })
				: {}
		if (params["duel"]) {
			setValue(params["duel"])
			setIsCustomSeed(true)
			setLoaded(true)
			return
		}
		const checkTime = async () => {
			let found = false
			try {
				const value = await makeFetch()
				if (value) {
					found = true
					setValue(value)
				}
			} catch (e) {
				console.log(e)
			}
			if (!found && !value) {
				setValue(NO_SEED)
			}
			setLoaded(true)
		}
		checkTime()
	}, [isActive, value])

	const memoValue = useMemo(() => (loaded ? value : null), [value, loaded])

	return (
		<TimeContext.Provider
			value={{
				value: memoValue,
				isCustomSeed,
				isActive,
			}}
		>
			{memoValue === null ? <EmptyHeader /> : children}
		</TimeContext.Provider>
	)
}

export const makeFetch = async () => {
	let val = null
	const resp = await fetch(`${getBaseUrl()}/check.html`, {
		body: null,
		method: "GET",
	})
	resp.headers.forEach((value, key) => {
		if (key === "date") {
			const _date = new Date(value).toLocaleDateString("en-US", {
				timeZone: "America/New_York",
			})
			const date = new Date(formatDate(_date)).toISOString().split("T")[0]
			val = date
		}
	})
	return val
}

function formatDate(userDate) {
	const parts = userDate.split("/")
	if (parts[0].length === 1) parts[0] = "0" + parts[0]
	if (parts[1].length === 1) parts[1] = "0" + parts[1]
	return parts[2] + "-" + parts[0] + "-" + parts[1]
}
